import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import {getPageView} from '../api/index/index'

class RouterView extends Component {

    componentDidMount() {
        // this.getPageView()
        if(Cookies.get("Main_Info") && Cookies.get("Main_Info") !== undefined && Cookies.get("Main_Info") !== 'undefined') {
            let mainInfo = decodeURI(Cookies.get("Main_Info"));
            let personnelDetail = JSON.parse(mainInfo)
            // this.props.changeIndex('2');
            let userInfo = {
                id: personnelDetail.id ? personnelDetail.id : '', // 用户Id
                humanName: personnelDetail.humanName ? personnelDetail.humanName : '', // 姓名
                fullName: personnelDetail.humanName ? personnelDetail.humanName : '', // 姓名
                portraitId: personnelDetail.portraitId ? personnelDetail.portraitId : '', // 头像Id
                userName: personnelDetail.userName ? personnelDetail.userName : '', // 用户名，登录名
                telNum: personnelDetail.telNum ? personnelDetail.telNum : '', // 电话
                sex: personnelDetail.sex ? personnelDetail.sex : '', // 性别（2女，1男）
                introduction: personnelDetail.introduction ? personnelDetail.introduction : '', // 个人描述
                epsId: personnelDetail.epsId ? personnelDetail.epsId : '', // 企业id
                organization: personnelDetail.epsId ? personnelDetail.epsId : '', // 组织id
                innerWebsite: personnelDetail.innerWebsite ? personnelDetail.innerWebsite : '', // 域名
                token: personnelDetail.token ? personnelDetail.token : '', // token
            }
            localStorage.setItem("userInfo", JSON.stringify(Object.assign({}, userInfo))); // 用户信息集合
            localStorage.setItem("epsId", personnelDetail.epsId ? personnelDetail.epsId : '',); // 企业ID
            localStorage.setItem("OBS_token", personnelDetail.token ? personnelDetail.token : '',); // 登录token
            localStorage.setItem("innerWebsite", personnelDetail.innerWebsite ? personnelDetail.innerWebsite : '',); // 域名
            // this.props.changeIndex('2');
        } else {
            localStorage.removeItem("userInfo")
            localStorage.removeItem("OBS_token")
            localStorage.removeItem("innerWebsite")
        }
    }

    //访问量+1
    // getPageView = () => {
    //     let schoolId = JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'';
    //     getPageView(schoolId).then((res) => {
    //     })
    // }

    render() {
        let { routers } = this.props;
        // console.log(routers);
        let redirect = !routers ? [] : routers.filter((v) => v.redirect);
        let newroutes = !routers ? [] : routers.filter((v) => !v.redirect);
        return (
            <Switch>
                {newroutes.map((v, i) => {
                    return (
                        <Route
                            key={i}
                            path={v.path}
                            exact={v.exact}
                            render={(props) => {
                                if (v.children) {
                                    return <v.component routers={v.children} {...props} />;
                                } else {
                                    return <v.component {...props} />;
                                }
                            }}
                        />
                    );
                })}

                {redirect.map((v, i) => {
                    return (
                        <Redirect exact={v.exact} key={i} from={v.path} to={v.redirect} />
                    );
                })}
            </Switch>
        );
    }
}
export default withRouter(RouterView);
