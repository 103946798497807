import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import { Input,Form } from 'antd';
import { withRouter } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import 'swiper/dist/css/swiper.min.css';
import Swiper from 'swiper';
import RenderImg from '../../components/renderImg/index'
import {peixunIndexTopData,absorbedTimeList} from "@/api/indexnews/index"
import NoData from "../../components/noData";
const urlPre = "cvec-space-front-peixun/";
const { Search } = Input;

export default Form.create()(
    withRouter(
        class Newsinformation extends React.Component {
            state = {
                imgVisible:false,//图片弹出
                imgTotal:2,
                imgCurPage:1,
                organizationId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'', //机构id,,,
                platform: 'TS', // 业务系统标识
                pageNum: 1,
                pageSize: 5,
                topList: [], // 顶部新闻、专题列表
                newsOne: {}, // 新闻第一条
                newsTopList: [], // 顶部新闻、专题列表
                categoryList: [],
                newsPageList: [],
                specialList: [],
                newList: [], // 新闻列表
                specialTopList: [], // 专题顶部列表
                id: '', // 主键id
                zzskList: [] // 专注时刻列表
            };
            componentWillUnmount() {}
            componentDidUpdate () {
                new Swiper('.swiper-container', {
                    pagination: '.swiper-pagination',
                    // nextButton: '.swiper-button-next',
                    // prevButton: '.swiper-button-prev',
                    slidesPerView: 5,
                    centeredSlides: false,
                    paginationClickable: true,
                    spaceBetween: 30,
                    slidesPerGroup : 5,
                    pages: 0 // 总页数
                });
            }
            componentDidMount() {

                this.peixunIndexTopData()
                this.absorbedTimeList()
            }
            onSearch = (value) => { // 搜索
                if(value){
                    this.props.history.push(`/searchresult/?${encodeURI(JSON.stringify({keyword: value,organizationId:this.state.organizationId}))}`)
                }
            }

            // 查询顶部推荐新闻资讯、推荐专题
            async peixunIndexTopData () {
                const {organizationId,platform}=this.state
                let data = {
                    organizationId: organizationId,
                    platform: platform,
                    pageNum: 1,
                    pageSize: 10
                }
                const indexList = await peixunIndexTopData(data)
                let arry = indexList.newsList ? indexList.newsList : []
                this.setState({
                    newsTopList: indexList.newsTopList, //顶部新闻
                    newsOne: arry && arry[0]?arry[0]:[], // 第一条新闻
                    specialList: indexList.specialList,
                    specialTopList: indexList.specialTopList, // 专题顶部列表
                    newList: arry // 新闻列表
                })
            }

            async absorbedTimeList () { // 专注时刻列表
                const { id, pageNum, pageSize } = this.state
                let data = {
                    id: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'', //机构id,,,
                    pageNum,
                    pageSize
                }
                await absorbedTimeList(data).then(res=>{
                    if (res) {
                        this.setState({
                            zzskList: res.data,
                            pageNum: res.pageNum,
                            pageSize: res.pageSize,
                            pages: res.pages
                        },()=>{
                        })
                    }
                })
            }
            changePage = type => { // 专注时刻分页
                const {pageNum, pages} = this.state
                if (type === 'next') {
                    if (pageNum < pages) {
                        this.setState({
                            pageNum: pageNum + 1
                        },()=>{
                            this.absorbedTimeList()
                        })
                    } else {
                        this.setState({
                            pageNum: pageNum
                        },()=>{
                        })
                    }
                } else {
                    if (pageNum > 1) {
                        this.setState({
                            pageNum: pageNum - 1
                        },()=>{
                            this.absorbedTimeList()
                        })
                    } else {
                        this.setState({
                            pageNum: 1
                        })
                    }
                }



            }
            gotoNewsDetial = item => { // 进入新闻详情页
                this.props.history.push(`/newscont/?${encodeURI(JSON.stringify({newsId: item.id,oneCategoryId:item.oneCategoryId,twoCategoryId:item.twoCategoryId}))}`)
            }
            goNewsMore = () =>{ // 进入新闻列表页
                this.props.history.push(`/newslist`)

            }
            gotoSpeMore = () => { // 进入专题列表页
                this.props.history.push('/hottopic')
            }
            gotoSpeList = item => { // 进入专题详情页
                this.props.history.push(`/projectlist/?${encodeURI(JSON.stringify({id: item.id}))}`)
            }

            render() {
                const {newsTopList,newsOne,newList,specialList, specialTopList,zzskList,pages, pageNum } =this.state
                const { $$img, } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='CamelCaseNewsinformation'>
                                {/*背景大图*/}
                                <div className="back_img_box">
                                    <div className="Camel-content">
                                        <div className="title_box">
                                            <div className="modal_tile">培训动态</div>
                                            <div className="modal_con">聚焦培训动态，关注行业发展</div>
                                            <div className="modal_input_box">
                                                <Search
                                                    placeholder="请输入关键字"
                                                    allowClear
                                                    enterButton="搜索"
                                                    size="large"
                                                    onSearch={this.onSearch}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*推荐专题*/}
                                <div className="reaement">
                                    {
                                        (newsTopList.length > 0 || specialTopList.length > 0) ?
                                            <div className="Camel-content">
                                                {
                                            newsTopList&&newsTopList.map((item,index)=>{
                                                return(
                                                    <div className="list_box">
                                                        <div className="top_wapper">
                                                            <div className="reament_title">
                                                                {item.title}
                                                            </div>
                                                            <div className="reament_cont">
                                                                {item.content}
                                                            </div>
                                                            <div className="title_bg" style={{background:`url('${window.$$img('cvec-space-front-peixun/news-li.png')}')`}}>
                                                                <img src={`${$$img(urlPre + "news-title1.png")}`}/>
                                                                培训资讯
                                                            </div>
                                                        </div>
                                                        <div className="bottom_hover" style={{background:`url('${window.$$img('cvec-space-front-peixun/news-li-bg1.png')}')`}}>
                                                            <div className="title_bottom">
                                                                <img src={`${$$img(urlPre + "news-title1.png")}`}/>
                                                                培训资讯
                                                            </div>
                                                            <div className="text_bottom">
                                                                {item.title}
                                                            </div>
                                                            <div className="more_link" onClick={()=>this.gotoNewsDetial(item)}>
                                                                <a>
                                                                    进入新闻
                                                                    <img src={`${$$img(urlPre + "news-jt.png")}`}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        }

                                        {
                                            specialTopList && specialTopList.map((item,index) => {
                                                return (
                                                    <div className="list_box">
                                                        <div className="top_wapper">
                                                            <div className="reament_title">
                                                                {item.title}
                                                            </div>
                                                            <div className="reament_cont">
                                                                {item.content}
                                                            </div>
                                                            <div className="title_bg" style={{background:`url('${window.$$img('cvec-space-front-peixun/news-li2.png')}')`}}>
                                                                <img src={`${$$img(urlPre + "news-title2.png")}`}/>
                                                                推荐专题
                                                            </div>
                                                        </div>
                                                        <div className="bottom_hover" style={{background:`url('${window.$$img('cvec-space-front-peixun/news-li-bg2.png')}')`}}>
                                                            <div className="title_bottom">
                                                                <img src={`${$$img(urlPre + "news-title2.png")}`}/>
                                                                推荐专题
                                                            </div>
                                                            <div className="text_bottom">
                                                                {item.title}
                                                            </div>
                                                            <div className="more_link" onClick={()=>this.gotoSpeList(item)}>
                                                                <a>
                                                                    进入推荐专题
                                                                    <img src={`${$$img(urlPre + "news-jt.png")}`}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> :
                                            <NoData></NoData>
                                    }

                                </div>
                                {/*新闻资讯*/}
                                <div className="information_title">
                                    <div className="title">
                                        <h2>培训资讯</h2>
                                    </div>
                                </div>
                                <div className="news_information">
                                    {
                                        newList && newList.length > 0 ?
                                            <div className="Camel-content">
                                                <div className="information_top">
                                                    <div className="pic">
                                                        <RenderImg type={newsOne.mediaType} id={newsOne.img}/>
                                                    </div>
                                                    <div className="text_right">
                                                        <h3 className="tit-h3"><i className={newsOne.newsType === 1? '': 'on'}>{newsOne.newsType === 1 ? '原创' : '转载'  }</i>{newsOne.title}</h3>
                                                        <span>{newsOne.authorName} {newsOne.releaseTime}</span>
                                                        <div className="text">
                                                            {newsOne.content}
                                                        </div>
                                                        <div className="more" onClick={()=>this.gotoNewsDetial(newsOne)}>
                                                            <a>
                                                                <div>进入资讯</div>
                                                                <img src={`${$$img(urlPre + "news-jt.png")}`}/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="ul_bottom">
                                                    {
                                                        newList&&newList.map((item,index)=>{
                                                            if (index > 0) {
                                                                return(
                                                                    <li>
                                                                        <div className="time_bg">
                                                                            <div className="time_left">
                                                                                <b>{item.releaseTime.slice(8,10)}</b>
                                                                                <span>{item.releaseTime.slice(0,7)}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="news_text" onClick={()=>this.gotoNewsDetial(item)}><a>
                                                                            <h3><i className={item.newsType === 1? '': 'on'}>{item.newsType === 1 ? '原创' : '转载'  }</i>{item.title}</h3>
                                                                            <div className="text">
                                                                                {item.content}
                                                                            </div>
                                                                        </a></div>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>
                                                <div className="more-linka" onClick={()=>this.goNewsMore()}>
                                                    <a>查看更多</a>
                                                </div>

                                            </div> :
                                            <NoData></NoData>
                                    }

                                </div>
                                {/*推荐专题*/}
                                <div className="information_title">
                                    <div className="title">
                                        <h2>推荐专题</h2>
                                    </div>
                                </div>
                                <div className="Recommended_project">
                                    {
                                        specialList.length > 0 ?
                                            <div className="Camel-content">
                                                <ul>
                                                    {
                                                        specialList&&specialList.map((item,index)=>{
                                                            if (index < 3) {
                                                                return(
                                                                    <li>
                                                                        <div className="pic">
                                                                            <RenderImg type={item.mediaType?item.mediaType:''} id={item.img}/>
                                                                            <span><i>专题</i></span>
                                                                        </div>
                                                                        <div className="text">
                                                                            <h3>{item.content}</h3>
                                                                            <div className="dov">{item.title}</div>
                                                                            <div className="more" onClick={()=>this.gotoSpeList(item)}>
                                                                                <a>
                                                                                    进入专题
                                                                                    <img className="img1" src={`${$$img(urlPre + "ztjt1.png")}`}/>
                                                                                    <img className="img2" src={`${$$img(urlPre + "ztjt2.png")}`}/>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>
                                                <div className="more-linka" onClick={()=>this.gotoSpeMore()}>
                                                    <a>查看更多</a>
                                                </div>
                                            </div> :
                                            <NoData></NoData>
                                    }

                                </div>
                                {/*专注时刻*/}
                                <div className="dedicated_cont">
                                    <div className="information_title">
                                        <div className="title">
                                            <h2>培训风采</h2>
                                        </div>
                                    </div>
                                    <div className="Camel-content">
                                        {
                                            zzskList.length > 0 ?
                                                <div className="dedica_main">
                                                    <div className="swiper-container">
                                                        <div className="swiper-wrapper">
                                                            {
                                                                zzskList && zzskList.map((item,index)=>{
                                                                    return(
                                                                        <div className="swiper-slide">
                                                                            <RenderImg type={item.mediaType?item.mediaType:''} id={item.imgUrl}/>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            pageNum < pages ?
                                                                <div className="swiper-button-next" onClick={()=>this.changePage('next')}></div> : null
                                                        }
                                                        {
                                                            pageNum > 1 ?
                                                                <div className="swiper-button-prev" onClick={()=>this.changePage('prev')}></div> : null
                                                        }

                                                    </div>
                                                    <div className="more-linka" onClick={()=>this.props.history.push('/focusontime')}>
                                                        <span>查看更多</span>
                                                    </div>
                                                </div> :
                                                <NoData></NoData>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
