import React from 'react';
import './index.scss';
import {Row, Col, Radio, Form, Input} from 'antd';
export default Form.create()(
    class RegisTration extends React.Component {
        state = {
            confirmDirty: false // 密码校验
        }
        // 校验手机号格式
        validatePhone = (rule, value, callback) => {
            let pattern = /^1[3|4|5|7|8][0-9]\d{8}$/;
            if (value && !pattern.test(value)) {
                callback('请输入正确的号，如：13988886666')
            } else {
                callback();
            }
        }
        validateToNextPassword = (rule, value, callback) => { // 密码校验，由数字大小写字母6-16位组成
            let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,16}$/
            if (value && !pattern.test(value)) {
                callback('密码应由6-16位的数字、大小写字母组成！')
            } else {
                callback();
            }
        }
        compareToFirstPassword = (rule, value, callback) => { // 确认密码校验
            const { form } = this.props;
            if (value && value !== form.getFieldValue('password')) {
                callback('两次密码不一样!');
            } else {
                callback();
            }
        };
        handleConfirmBlur = e => {
            const { value } = e.target;
            this.setState({ confirmDirty: this.state.confirmDirty || !!value });
        };
        render() {
            const {getFieldDecorator} = this.props.form;
            return (
                <Form className='add_stu_info'>
                    <Form.Item>
                        <Row>
                            <Col span={3}><span><span className='red'>*</span>用户名</span></Col>
                            <Col span={15}>
                                {
                                    getFieldDecorator('fullName', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '用户名不能为空'
                                            },
                                            {
                                                max: 10,
                                                message: "文本过长，最多10字"
                                            }
                                        ],
                                        getValueFromEvent: event => event.target.value.replace(/\s+/g, '').replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
                                    })(
                                        <Input placeholder='请输入用户名'  maxLength={10} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`fullName`) ? this.props.form.getFieldValue(`fullName`).length : 0}/10</span>}/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={3}><span><span className='red'>*</span>性别</span></Col>
                            <Col span={15}>
                                {
                                    getFieldDecorator('sex', {
                                        initialValue: ''
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>男</Radio>
                                            <Radio value={0}>女</Radio>
                                        </Radio.Group>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={3}><span><span className='red'>*</span>手机号</span></Col>
                            <Col span={15}>
                                {
                                    getFieldDecorator('telNum', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '手机号不能为空'
                                            }, {
                                                validator: this.validatePhone
                                            }
                                        ]
                                    })(
                                        <Input placeholder='请输入联系方式'/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item>
                        <Row>
                            <Col span={3}><span><span className='red'>*</span>密码</span></Col>
                            <Col span={15}>
                                {
                                    getFieldDecorator('password', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入密码'
                                            }, {
                                                validator: this.validateToNextPassword
                                            }
                                        ]
                                    })(
                                        <Input.Password placeholder='密码由6-16位的数字、大小写字母组成'/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item>
                        <Row>
                            <Col span={3}><span><span className='red'>*</span>确认密码</span></Col>
                            <Col span={15}>
                                {
                                    getFieldDecorator('confirm', {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入确认密码'
                                            }, {
                                                validator: this.compareToFirstPassword
                                            }
                                        ]
                                    })(
                                        <Input.Password placeholder='请输入确认密码' onBlur={this.handleConfirmBlur}/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Form.Item>

                </Form>
            )
        }

    }
)
