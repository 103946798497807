import { post } from '../../until/axios_instans';
// 查询首页新闻资讯、专题
export function peixunIndexTopData(data) {
    return post(window.apiZixun + '/api/v1/sdk/news/peixun/index/peixunIndexTopData', data);
}

// 搜索新闻和专题
export function peixunSearchNewsAndSpecial(data) {
    return post(window.apiZixun + '/api/v1/sdk/news/peixun/index/peixunSearchNewsAndSpecial', data);
}

// 查询首页新闻详情
export function peixunIndexNewsDetail(data) {
    return post(window.apiZixun + '/api/v1/sdk/news/peixun/index/peixunIndexNewsDetail', data);
}

// 专注时刻
export function absorbedTimeList(data) {
    return post(window.apiPrefix + '/api/v1/ts/portal/news/absorbedTimeList', data);
}
