import { post } from '../../until/axios_instans';
// 查询首页新闻专题列表
export function peixunIndexNewsSpecialList(data) {
    return post(window.apiZixun+'/api/v1/sdk/news/peixun/index/peixunIndexNewsSpecialList', data);
}
// 查询专题详情
export function peixunSpecialDetail(data) {
    return post(window.apiZixun+'/api/v1/sdk/news/peixun/index/peixunSpecialDetail', data);
}
// 查询首页新闻详情
export function peixunIndexNewsDetail(data) {
    return post(window.apiZixun+'/api/v1/sdk/news/peixun/index/peixunIndexNewsDetail', data);
}