// 课程资料
import React from 'react';
import './index.scss';
import { Table,message,Spin,Drawer,Popover } from 'antd';
import {courseDetailResource,courseResourceDownload,getByPlanTransId} from "../../api/course/index";
import CoursePreview from "@/components/coursePreview"
import NoData from "../noData"; // 资源预览
const urlPre = "cvec-space-front-peixun/";

export default class Coumaterials extends React.Component {
    state = {
        dataSource:[
            {
                title:'第三方教育评估现状调研与推进研究.pdf',
                type:'4.5mb',
                max:'4.5MB',
                down:'2344'
            }
        ],
        pageNum: 1,
        pageSize: 10,
        pages: '', // 总页数
        total: '', // 总记录数
        courseId: '', // 课程id
        courseDetailResourceInfo: '' ,// 表格信息
        tableLoading:true,
        visible: false, // 资源预览
        fileDetail:{},
        resourceName:'',
        fileId: '',
        transSuffix:'',
        suffix: '',
        connecting:true,

    }
    componentDidMount() {
        this.setState({courseId: this.props.courseId})
        this.courseDetailResource()
    }

    // 获取课程资料列表
    async courseDetailResource () {
        const {courseId, pageNum, pageSize} = this.state
        let data = {
            id: this.props.courseId,
            pageNum: pageNum,
            pageSize: pageSize
        }
        const courseDetailResourceInfo = await courseDetailResource(data)
        this.setState({
            pageNum: courseDetailResourceInfo.pageNum,
            pageSize: courseDetailResourceInfo.pageSize,
            dataSource: courseDetailResourceInfo.data,
            pages: courseDetailResourceInfo.pages, // 总页数
            total: courseDetailResourceInfo.total, // 	总记录数
            courseDetailResourceInfo,
            tableLoading:false
        })
    }

    changePage=(cur,pageSize)=>{
        this.setState({
            pageNum:cur,
            pageSize: pageSize
        },()=>{
            //列表方法
            this.courseDetailResource()
        })
    }
    onShowSizeChange=(current, pageSize)=>{
        this.setState({
            pageSize:pageSize,
            pageNum:current
        },()=>{
            this.courseDetailResource()
        })
    }
    downloadFile = (fileId,resourceName,id,resourceId) => {
        const { connecting } = this.state
        if(connecting){
            this.setState({connecting:false})
            courseResourceDownload({id:id}).then(res =>{
                if(res){
                    const url = window.$$gw('//api/fujian/api/v1/fujian/download'+'/'+resourceId+ '/'+ resourceName);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', resourceName);
                    link.click();
                    this.courseDetailResource()
                    setTimeout(() => {
                        this.setState({connecting: true});
                    }, 2000);
                }
            })
        }

    }
    onClose = () => { // 关闭详情
        this.setState({
            visible: false,
            fileId:''
        });
        window.location.reload();
    };
    showDrawer = (record) => {  // 查看详情
        let data = {
            id:record.id,
            type:1
        }
 /*       this.setState({
            visible: true,
        });*/
        getByPlanTransId(data).then((res) => {
            // console.log('资源信息---》', res)
            if (res.transStatus ===0){
                //不转码
                this.setState({
                    fileId:res.fileId,
                    fileDetail: res,
                    resourceName: res.resourceName,
                    suffix: res.resourceSuffix
                },()=>{
                    this.setState({
                        visible: true,
                    })
                })

            } else if (res.transStatus ===1){
                //转码成功
                const fileNameindex = res.resourceName.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                const fileNameLen = res.resourceName.length; // 取到文件名长度
                const fileType = res.resourceName.substring(fileNameindex + 1, fileNameLen); // 截
                this.setState({
                    fileDetail: res,
                    fileId: res.transId,
                    transSuffix:res.transSuffix,
                    resourceName: res.resourceName + "."+ res.transSuffix, // transSuffix"转码后的文件后缀"
                    suffix: res.transSuffix
                },()=>{
                  this.setState({
                      visible: true
                  })
                });
            }else  if (res.transStatus ===2){
                message.warning("文件转码失败")
                return;
            } else{
                message.warning("文件转码未完成")
                return
            }
         /*   this.setState({
                fileDetail:res
            })*/
        });
    };

    render() {
        const {dataSource,total, pageNum, pageSize,tableLoading, fileId,
            transSuffix,id,fileDetail,resourceName, suffix} =this.state
        // console.log('前一页的fileId--->', fileId)
        // console.log('suffix-123-->', suffix)
        const { $$img,apiPrefix } = window;
        let pagination={
            total:total,
            pageNum:pageNum,
            current:pageNum,//重置页码
            pageSize:pageSize,
            onChange:this.changePage,
            showSizeChanger:true,
            onShowSizeChange:this.onShowSizeChange
        }
        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                key: 'key',
                render:(text,record,index)=>`${index+1}`
            },
            {
                title: '资料名称',
                dataIndex: 'resourceName',
                key: 'resourceName',
                ellipsis: true,
                width: 150,
                render: (text, record) => {
                    return (
                        <div>
                            {
                                record?.resourceName?.length > 10 ?
                                    <Popover placement="bottom" content={(<p style={{width: '150px',wordBreak: 'break-all'}}>  {record.resourceName}</p>)} title="">
                                        <p className="content-name">
                                            {record.resourceName}
                                        </p>
                                    </Popover> :
                                    <p className="content-name">
                                        {record.resourceName}
                                    </p>
                            }
                        </div>
                    )
                }

            },
            /*{
                title: '类型',
                dataIndex: 'resourceFormat',
                key: 'resourceFormat'
            },*/
            {
                title: '素材大小（KB）',
                dataIndex: 'resourceSize',
                key: 'resourceSize'
            },
            {
                title: '下载量',
                dataIndex: 'downloadNum',
                key: 'downloadNum'
            },
            {
                title: '操作',
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => {
                    return <div className='oper'>
                        {
                            record.isDownload === 1 ?
                                <span style={this.state.connecting === true?{cursor:'pointer'}: {cursor:'no-drop'}}
                                      onClick={()=>this.downloadFile(record.fileId,record.resourceName,record.id,record.resourceId)}
                                >下载</span> :null
                        }
                        {
                            record.isLook === 1 ? <span onClick={()=>this.showDrawer(record)}>查看</span>: null
                        }
                    </div>
                }
            }
        ]

        return (
            <div className='CamelCasecoumaterials'>
                {
                    tableLoading?<Spin className="Spin-content" tip="加载中"/>:(
                        <Table className="main-box-header"
                               cellspacing={20}  columns={columns} dataSource={dataSource} pagination={pagination}
                               locale={{emptyText:<> <NoData/></>}}
                        />
                    )
                }

                {/*查看详情开始*/}
                <Drawer
                    className='drawer_wapper class-info-drawer details_info'
                    title={
                        <div className='detileHader'>
                            <div onClick={this.onClose} className='return_btn'><img
                                src={require(`@/assets/img/return.png`)}></img>返回
                            </div>
                            <div className="courseName">{fileDetail.resourceName}</div>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    width={'100%'}
                >
                    <div className="crumbs">您的位置：<span onClick={()=>{this.setState({visible:false})}}>培训课程</span>  /  <span onClick={()=>{this.setState({visible:false})}}>课程资料</span>  /  详情</div>
                    <CoursePreview   fileId={fileId}
                    transSuffix={transSuffix} suffix={suffix} fileDetail={fileDetail} resourceName={resourceName}/>
                </Drawer>
                {/*查看详情结束*/}

            </div>
        )
    }
}
