import React, { Component } from "react";
import Styles from "./index.module.scss";
import { withRouter } from "react-router-dom";
import {gotoPlanListUrl, } from '../../config/secret'
import Cookies from 'js-cookie';
import {logoutUrl } from '@/config/index.js';
import { authorityList, userInfo} from '../../until/authorityList'
import { TwoLevelHeader } from 'gongyongheader'
@withRouter
class Header extends Component {
  state = {
    navName: localStorage.getItem('navName')?localStorage.getItem('navName').split("#")[0]:'智慧培训',
    info: {}, // 个人信息
    userId: '',
    logo: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).logo:'',//机构logo,
    schoolData: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')):'',//院校信息,
    name:JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).name:'',//机构名称,
    search:this.props.location.search||"",
    nav:"",
    token:"",
    type_config:{},
    menuList: [
      {
        name: "首页",
        skipUrl: "/",
      },
      {
        name: "培训计划",
        skipUrl:"/traininglistp",
      },
      {
        name: "培训课程",
        skipUrl:"/courselist",
      },
      {
        name: "培训资讯",
        skipUrl:"/newsinformation",
      },
      {
        name: "学习故事",
        skipUrl:"/learnstory",
      },
    ],

  };

  componentDidMount() {
    let token = Cookies.get("info")?JSON.parse(Cookies.get("info")).token: ''
    this.setState({
      userId: userInfo.id,
      userInfo: userInfo,
      token:token?token: '',
      type_config:window.$$type_config
    });
    this.state.menuList.map(i=>{
      i.skipUrl=i.skipUrl+this.state.search
    })
  }
  getQueryVariable(variable) { // 获取参数
    let url = window.location.href
    const query = url.split("?")[1];
    if (query) {
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1]
        }
      }
    }
    return (false)
  }
  getQueryString=(name)=> {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r =this.props.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]); return null;
  }
  gotoPlanlist = () => { // 跳转到学生端个人项目计划列表页
    let cookieData = {
      pageNum: 1,
      pageSize: 10,
      planStatus: 0,
      userId: userInfo.id??'',
    }
    Cookies.set('pathName', `/training/mytraining/trainingplan`); // 存菜单地址
    window.open(gotoPlanListUrl + JSON.stringify(cookieData)).location.reload()
  }

  //确定退出登录
  handleLogout = () => {
    this.hint({
      "title": '温馨提示',
      'type': 'warning',
      'content': `确定要退出登录吗？`,
      'cancelText': '取消',
      'okText': '确定',
      'isCancel': true,
      'sure': this.logout
    })
  };
  logout = () => {
    window.location = `${logoutUrl}?url=${window.location.origin}&out=true`
  }
  //登录
  handleLogin=()=>{
    // window.location = `${logoutUrl}?url=${window.location.origin}`
    window.location = `${logoutUrl}?url=${window.location.origin}&out=true`
  }
  // 进入学校官网
  gotoSchool=()=>{
    window.location = this.state.schoolUrl
  }
  messageTip = () => { // 未开发功能提示
    this.mesWarning('该功能正在建设中，敬请期待…')
  };


  render() {
    const {schoolData, token,userInfo, menuList,type_config} = this.state;
    const schoolId = this.getQueryVariable('schoolId') ? this.getQueryVariable('schoolId') : JSON.parse(localStorage.getItem('enterpriseInfo')) ? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId : '' // 机构id
    return (
        <div className={Styles.CamelCaseheader}>
          <div className='head-h'>
            <TwoLevelHeader
                menuList={menuList}
                navShowMore={6}
                authorityList={authorityList}
                zIndex={2}
                type_config={type_config}
                userinfo={userInfo}
                token={token}
                schoolId={schoolId}
                schoolData={schoolData}
                __props={this.props}
            />
          </div>
        </div>
    );
  }
}

export default Header;
