import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Row, Col, Radio,Input,Button,Tabs,Image,Form } from 'antd';
import { withRouter,Link } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
const { Search } = Input;

export default Form.create()(
    withRouter(
        class Coursematerials extends React.Component {
            state = {

            };

            render() {
                const {} =this.state

                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='CamelCaseCoursematerials'>
                                <div className="Camel-content">
                                    <div className="link-return">
                                        <Link><img src={window.$$img('cvec-space-front-peixun/title_icon34.png')}/>返回</Link>
                                    </div>
                                    <div className="content">
                                        <h2>教育部关于加强和改进新时代基础教育教研工作的意见</h2>
                                        <div className="docv">
                                            2020年国际消费电子展（CES 2020）在美国拉斯维加斯举行。此次大展有逾数千家厂商参会，前来参展的企业将在这里公布和展示自己引以为豪的产品和技术，将横跨5G与物联网、自动驾驶、区块链、AI等多个领域。

                                            大展现场，英特尔、英伟达、高通和AMD纷纷公布了自家新品，彼此之间相互“较劲”火药味儿十足。除了大厂以外如果你足够细心不难发现活动现场还汇聚了多家来自中国的芯片厂商，嘉楠作为边缘AI芯片厂商名列其中。

                                            嘉楠带着自己的使命和愿景第一次参展CES，希望通过自身产品和技术让所有人都能使用超级计算，并让区块链和AI技术广泛应用，提升社会运行效率，改善人类生活方式。1月正是年之初始嘉楠通过“CES 2020”这个舞台，为您带来一场展现自身科技家底的“开年大戏”。

                                            一颗芯片：勘智K21<br/>         长江日报-长江网6月8日讯6月8日，长江日报-长江网记者从武汉市交通运输局民航办获悉，经国家民用航空局批准，9日起，武汉天河机场至北京客运航线航班复航，由南方航-空公司执飞武汉天河机场至北京大兴机场的一个往返航班。长江日报-长江网6月8日讯6月8日，长江日报-长江网记者从武汉市交通运输局民航办获悉，经国家民用航空局批准，9日起，武汉天河机场至北京客运航线航班复航，由南方航-空公司执飞武汉天河机场至北京大兴机场的一个往返航班。
                                            长江日报-长江网6月8日讯6月8日，长江日报-长江网记者从武汉市交通运输局民航办获悉，经国家民用航空局批准，9日起，武汉天河机场至北京客运航线航班复航，由南方航-空公司执飞武汉天河机场至北京大兴机场的一个往返航班。长江日报-长江网6月8日讯6月8日，长江日报-长江网记者从武汉市交通运输局民航办获悉，经国家民用航空局批准，9日起，武汉天河机场至北京客运航线航班复航，由南方航-空公司执飞武汉天河机场至北京大兴机场的一个往返航班。
                                            长江日报-长江网6月8日讯6月8日，长江日报-长江网记者从武汉市交通运输局民航办获悉，经国家民用航空局批准，9日起，武汉天河机场至北京客运航线航班复航。

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
