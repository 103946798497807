import { post,get } from '../../until/axios_instans';
// 获取首页轮播图数据
export function indexTopBanner(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/indexTopBanner', data);
}
// 获取首页课程列表
export function indexCourseList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/indexCourseList', data);
}
// 获取首页学霸列表
export function indexKingStudentList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/indexKingStudentList', data);
}
// 获取首页故事列表
export function indexStoryList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/indexStoryList', data);
}
// 获取首页计划列表
export function indexPlanList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/indexPlanList', data);
}
// 获取首页老师列表
export function indexTeacherList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/indexTeacherList', data);
}
// 根据url获取机构id
export function organizationDetail(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/organizationDetail', data);
}
// 获取首页新闻列表
export function indexNewsList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/indexNewsList', data);
}

// 根据学习id获取机构id
export function schoolInfo(data) {
    return get(`/api/university/portal/schoolInfo/${data}`);
}

//页底样式接口
export function getManageConfig(data) {
    return get(`/api/university/portal/config/${data}`);
}

//页底访问量接口
export function getPageView(data) {
    return get(`/api/university/schoolInfo/visit/${data}`);
}




