import React from 'react';
import '@/reset_style/index.less';
export default class NoData extends React.Component {
    state = {
    };
    render() {
        const { $$setImgSrc } = window;
        return (
            <div className="resource_main">
                <div className="Empty-conet">
                    <div className="img">
                        <img src={`${$$setImgSrc("px-no-data.png")}`}/>
                        <span>暂无数据</span>
                    </div>
                </div>
            </div>
        );
    }
}
