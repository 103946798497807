import { getUserRole, getUserInfo } from '@/api/role';
import Cookies from 'js-cookie';
// 映射关系
const authorityObj = {
    'CBS': {
        path: "guanlizhekongjian",
        text: 'CBS',
        name: "管理者空间"
    },
    'TS': {
        path: "gerenkongjian",
        text: 'TS',
        name: "教学空间"
    },
    'SS': {
        path: "gerenkongjian",
        text: 'SS',
        name: "学习空间"
    },
    'PORTAL': {
        path: "provincemanager",
        text: 'PORTAL',
        name: "门户管理"
    }
};
const token = Cookies.get("info")

    ? JSON.parse(Cookies.get("info")).token
    : "";
// 权限列表
export let authorityList = [];
export let userInfo = {};

export const getAuthorityList = (sys, callBack) => {
    try {
        token?getUserInfo().then((userRes)=>{
            // authorityList.push({
            //     path: "gerenzhongxin",
            //     name: "个人中心",
            //     url:sys['gerenzhongxin']
            // })
            if(!userRes){callBack();return}
            userInfo = userRes;
            let {identityId} = userRes;
            getUserRole().then(res=>{
                if(!res){callBack();return}
                // 将多个权限存放到数组里面
                if(!res[identityId]){callBack();return}
                let auArr = res[identityId].split(',');
                auArr.map((auStr)=>{
                    // 跳转路径
                    if(!authorityObj[auStr]){callBack();return;}
                    authorityObj[auStr]['url'] = sys[authorityObj[auStr]['path']];
                    authorityList.push(authorityObj[auStr])
                });
                callBack();
            });
        }):callBack()
    } catch (error) {
        console.log("bbbbbbbbbb",error);
    }
}
