// 课程大纲
import React from 'react';
import './index.scss';
import {Collapse, Spin,Popover} from 'antd';
import {courseDetailOutline} from "../../api/course/index";
import NoData from '../noData'
const urlPre = "cvec-space-front-peixun/";
const { Panel } = Collapse;


export default class Cououtline extends React.Component {
    state = {
        collapseKey:"0",
        courseId: '', // 课程id
        pageNum: '',
        pageSize: '',
        courseDetailOutlineInfo: '', // 大纲信息
        hasMore: false, //是否存在下一页
        isLoadingMore: false,//是否正在加载
        page: 0, //当前的页码
        tableLoading:true
    }





    //监听滚动条事件
    // componentDidMount () {
    //     console.log("scrollTop")
    //     window.addEventListener('scroll', this.bindHandleScroll)
    // }
    //
    // bindHandleScroll=(event)=> {
    //     let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
    //     console.log(scrollTop)
    // }


    componentDidMount() {
        this.setState({courseId: this.props.courseId})
        this.courseDetailOutline()

    }


    // 初始化数据
    async courseDetailOutline () {
        const {courseId} = this.state
        const courseDetailOutlineInfo = await courseDetailOutline({id: this.props.courseId})
        this.setState({
            courseDetailOutlineInfo,
            pageNum: courseDetailOutlineInfo.pageNum,
            pageSize: courseDetailOutlineInfo.pageSize,
            page: courseDetailOutlineInfo.page,
            tableLoading:false

        })
    }
    callback = (key) => {
        this.setState({
            collapseKey: key
        })
    }
    gotoGerenDetial = (item,citem) => { // 进入学生端课程详情页（个人项目）

    }



    render() {
        const {collapseKey, courseDetailOutlineInfo,tableLoading} =this.state
        const { $$img } = window;
        const openList=[]
        courseDetailOutlineInfo&&courseDetailOutlineInfo.data.map((i,index)=>{
                openList.push(index)
        })
        return (
            <div className='CamelCaseCououtline'>
                {
                    tableLoading?<Spin className="Spin-content" tip="加载中"/>:(
                        <div>
                            {
                                courseDetailOutlineInfo.data.length>0?(
                                    <Collapse accordion expandIconPosition='right' onChange={this.callback} defaultActiveKey={openList}>
                                        {
                                            courseDetailOutlineInfo.data?.map((item, index)=>{
                                                return(
                                                    item.children.length>0?
                                                    <Panel header={
                                                        <div className="header-icon">
                                                            <img src={window.$$img('cvec-space-front-peixun/title_icon24.png')}/>模块：{item.chapterName}
                                                        </div>
                                                    }
                                                           key={index} extra={collapseKey === {index} ? '收起' : '展开'}>
                                                        <ul>
                                                            {
                                                                item.children?.map((citem) => {
                                                                    return(
                                                                        <li>
                                                                            <span><img src={window.$$img('cvec-space-front-peixun/title_icon45.png')}/></span>
                                                                            <Popover placement="bottomLeft" trigger="hover" overlayClassName={'Popover_cot'} content={citem.chapterName} title="">
                                                                                <h3>{citem.chapterName}</h3>
                                                                            </Popover>
                                                                            <em>
                                                                                <i>{citem.teacherName}</i>
                                                                            </em>

                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </Panel>:
                                                        <div className={'ant-collapse-item ant-collapse-item-active'}>
                                                            <div className="ant-collapse-header">
                                                                <div className="header-icon">
                                                                    <img src={window.$$img('cvec-space-front-peixun/title_icon24.png')}/>模块：{item.chapterName}</div>
                                                            </div>
                                                        </div>

                                                )
                                            })
                                        }
                                    </Collapse>
                                ):<NoData/>
                            }
                        </div>
                    )
                }

            </div>
        )
    }
}
