import React ,{useRef}from 'react';
import './index.scss';
import Introduced from "../../components/introduced";
import Outline from "../../components/outline";
import Teachershow from "../../components/teachershow";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import {logoutUrl } from '@/config/index.js';
import {Row, Col, Radio,Button,Tabs, Modal,Form ,Pagination,Spin} from 'antd';
import { withRouter } from "react-router-dom";
import {planDetailTop,planDetailOutline,planDetailTeacherList,planDetailClassStatus, planDetailClassListPage,
    planDetailCourseList, studentEnrollPlan,planDetailSignStudentData, studentCollectionPlan,
    studentCollectionStatus, studentCancelCollectionPlan,insertSocietyStudent
} from '../../api/plan/index'
import '@/reset_style/index.less';
import { loginDomainUrl} from "../../config/secret";
import Cookies from 'js-cookie';
import RenderImg from '../../components/renderImg/index'
import {Fenxiang} from '../../components/iconSvg'
import AddStuInfo from './compents/index'
import NoData from "../../components/noData"
import RegisTration from './compents/registration'
const urlPre = "cvec-space-front-peixun/"
const { TabPane } = Tabs;
function getQueryVariable(variable) { // 获取地址栏参数
    let url = window.location.href
    const query = url.split("?")[1];
    if (query) {
        var vars = query&&query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){
                return pair[1]
            }
        }
    }
    return (false)
}
export default Form.create()(
  withRouter(
    class Plandetails extends React.Component {

      state = {
          activeKey:'1',
          CourseL:[],
          sign:false,
          planDetailInfo:[],//详情信息
          planClassList:[{id: null}],//班级列表
          planClassValue:null,//默认选中班级
          outlineData:[],//大纲列表
          teacherList:[],
          userId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).userId:'',
          classStatus:'',// 报名状态：1暂未开始 2立即报名 3已报名 4报名成功 5暂无班级 6重新报名 7审核驳回 8停止报名 9开始学习 10继续学习 11计划结束
          planId: '', // 计划id
          topTimeVoList: [],// 	时间段Vo
          isModalVisible: false ,// 更多班级弹层
          morClassList: [], // 更多班级列表
          pageNum: 1,
          pageSize: 10,
          total:0,
          enterpriseId:JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'',//机构id,
          planDetailCourseListInfo: [],// 推荐课程列表
          planDetailSignStudentDataInfo: {
              isForm: 0,// 是否需要填写表单，0否1是
          }, // 学生信息
          CollectionStatus: '', // 收藏状态 1 已经收藏 2未收藏
          tableLoading:true,
          fromFlag:false,//表单校验通过标志，通过才调接口
          provinceName:'',//省名
          cityName: "",//市名
          areaName: "",//区名
          isRegTra: false, // 注册弹层
          isWarm:false,  //判断登录注册弹窗
      };

        componentDidMount() {
            const data = this.props.location.search; //地址栏截取
            const param = data.split("?")[1];
            if (param) {
                let planId = ''
                if (param.includes("%")) {
                    const codeParam = decodeURI(param)
                    const jsonparam = JSON.parse(codeParam);
                    planId = jsonparam.id
                } else {
                    planId  = getQueryVariable('id')?getQueryVariable('id') :''
                }
                const {userId}=this.state
                this.setState({
                    planId: planId,
                },()=>{
                   this.planDetailTop();
                   this.planDetailOutline();
                   this.planDetailTeacherList();
                   this.planDetailCourseList();
                    userId&&this.studentCollectionStatus()
                });
            }
            /*暂时先注掉，后期会用到*/
            // this.Loading()
            // 左侧跟随屏幕
            document.body.addEventListener('scroll', this.bindHandleScroll)
            // 左侧跟随屏幕
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });

        }
        // 左侧跟随屏幕
        bindHandleScroll=(event)=>{
            let scrollTop=event.target.scrollTop
            if(scrollTop>=200){
                document.getElementById("left-top")&&document.getElementById("left-top").setAttribute("style","top:60px;position:fixed;");
            }else{
                document.getElementById("left-top")&&document.getElementById("left-top").setAttribute("style","");
            }
        }
        // 左侧跟随屏幕
        handleEnter(e) {
            e.currentTarget.querySelectorAll('span')[0].style.display = 'none';
            e.currentTarget.querySelectorAll('span')[1].style.display = 'inline';
        }
        handleLeave(e) {
            e.currentTarget.querySelectorAll('span')[0].style.display = 'inline';
            e.currentTarget.querySelectorAll('span')[1].style.display = 'none';
        }
        callback = async (key) => {
            await this.setState({activeKey: key})
        }
        cancel = e => {
            this.setState({ sign:false });
        }

        Signup = async (key) => { // 获取学生信息
            let {planDetailInfo, userId, planClassValue, planId, classStatus} = this.state
            let cookieData = {
                planId: planId,
                planStatus: 2,
                classId: planClassValue.id
            }
            if ( classStatus == 2) { // 立即报名
                const planDetailSignStudentDataInfo = await planDetailSignStudentData({id: userId,classId: planClassValue.id})
                // enrollmentTarget:(value = "招生对象 1学生2老师3非机构学生"),identityId:(value = "身份id，1 学生  2 老师"),招收对象和身份相同才可以报名
                if (planDetailSignStudentDataInfo.enrollmentTarget === planDetailSignStudentDataInfo.identityId) {
                    this.setState({ planDetailSignStudentDataInfo, sign:true});
                } else {
                    this.mesWarning('您不符合此班级报名条件！')
                }
            } else if ( classStatus == 6) { // 重新报名
                const planDetailSignStudentDataInfo = await planDetailSignStudentData({id: userId,classId: planClassValue.id})
                if (planDetailSignStudentDataInfo.enrollmentTarget === planDetailSignStudentDataInfo.identityId) {
                    this.setState({ planDetailSignStudentDataInfo, sign:true});
                } else {
                    this.mesWarning('您不符合此班级报名条件！')
                }
            } else if ( classStatus == 9 ) { // 开始学习
                Cookies.set('pathName', `/training/mytraining/trainingplan`); // 存菜单地址
                window.open(loginDomainUrl + JSON.stringify(cookieData)).location.reload()
            } else if ( classStatus == 10 ) { // 继续学习
                Cookies.set('pathName', `/training/mytraining/trainingplan`); // 存菜单地址
                window.open(loginDomainUrl + JSON.stringify(cookieData)).location.reload()
            }

        }
        //接收子组件传来的省名
        setProvinceName(name){
            this.setState({
                provinceName:name
            })
        }
        //接收子组件传来的市名
        setCityName(name){
            this.setState({
                cityName:name
            })
        }
        //接收子组件传来的区名
        setAreaName(name){
            this.setState({
                areaName:name
            })
        }
        studentEnrollPlan = (key) => { // 报名
            const {planClassValue, planId, userId, classStatus,planDetailSignStudentDataInfo,provinceName,cityName,areaName} = this.state
            let data = {};
            if(planDetailSignStudentDataInfo.isForm === 1){//表单数据
                const form = this.formRef.props.form;
                form.validateFields((err, fieldsValue) => {
                    fieldsValue.formProvince = provinceName;
                    fieldsValue.formCity = cityName;
                    fieldsValue.formArea = areaName;
                    if (!err) {
                        this.fromFlag = true;
                        data = {
                            classId: planClassValue.id,
                            planId: planId,
                            userId: userId,
                            classFormQo:JSON.parse(JSON.stringify(fieldsValue))
                        }
                    }else{
                        this.mesWarning('您有未填写信息')
                    }
                });
            }else{
                this.fromFlag = true;
                data = {
                    classId: planClassValue.id,
                    planId: planId,
                    userId: userId,
                }
            }
            if(!this.fromFlag) return
            studentEnrollPlan (data).then(res => {
                this.setState({
                    classStatus: res
                })
                if (res == 4) {
                    let cookieData = {
                        planId: planId,
                        planStatus: classStatus,
                        classId: planClassValue.id
                    }
                    Cookies.set('pathName', `/training/mytraining/trainingplan`); // 存菜单地址
                    window.open(loginDomainUrl + JSON.stringify(cookieData)).location.reload()
                } else {
                    this.cancelBm()
                    this.planDetailClassStatus()
                    // 报名状态：1暂未开始 2立即报名 3已报名 4报名成功 5暂无班级 6重新报名 7审核驳回 8停止报名 9开始学习 10继续学习 11计划结束 12不可以重复报名班级 13超出最多报名班级限制
                    if (res == 3) {
                        this.mesSuccess('已报名，正在审核中')
                    } else if (res == 12) {
                        this.mesSuccess('不可以重复报名班级')
                    } else if (res==13) {
                        this.mesSuccess('超出最多报名班级限制')
                    }
                }
            })
        }
        Loading =()=>{
            const cont =document.getElementById("loading")
            setTimeout(()=>{
                cont.style.display="flex"
            },6000)
        }
        //详情信息
        async planDetailTop(){
            const {planId,userId}=this.state
            const planDetailInfo=await planDetailTop({planId:planId,userId:userId})
            this.setState({
                planDetailInfo,
                // planClassValue:planDetailInfo.planClassList[0],
                planClassList:planDetailInfo.planClassList,
                topTimeVoList: planDetailInfo.topTimeVoList,
                tableLoading:false
            },()=>{
                if (planDetailInfo.planClassList.length != 0) {
                    // this.planDetailClassStatus()
                }
            })
        }
        //切换班级
        changeClass=(value)=>{
            this.setState({
                planClassValue:value
            },()=>{
                this.planDetailClassStatus()
            })
        }
        //获取大纲数据
       async planDetailOutline(){
           const {planId}=this.state
            const  outlineData= await planDetailOutline({id:planId})
           this.setState({
               outlineData,
               tableLoading:false

           })
        }
        //获取大纲数据
        async planDetailTeacherList(){
            const {planId}=this.state
            const  teacherList= await planDetailTeacherList({id:planId})
            this.setState({
                teacherList,
                tableLoading:false
            })
        }
        //查询班级报名状态
       async planDetailClassStatus(){
            const {planClassValue,planId,userId}=this.state
            const planDetailInfo=await planDetailClassStatus({planId:planId,userId:userId,classId:planClassValue.id})
            if (planDetailInfo==-1) {
                this.hint({'title': '温馨提示',
                    'content': '您当前正在该计划的教学团队内，继续报名将离开团队，请确认是否继续报名',
                    'isCancel': true,
                    'cancelText': '取消',
                    'okText': '确定',
                    'sure': this.studentEnroll,
                    'type': 'warning'
                })
            } else if (planDetailInfo===-2) { // isForm 是否需填表报名(0否 1是) enrollmentTarget招生对象 1学生2老师(表单班和教师不可以注册)
                if ( planClassValue.isForm === 1 || planClassValue.enrollmentTarget === 2) {
                    this.mesWarning('您还未登录，请先登录！')
                } else {
                    this.setState({
                        isWarm:true
                    })
                    // this.hint({'title': '温馨提示',
                    //     'content': '您还未登录，请先登录，如未注册，请先注册',
                    //     'isCancel': true,
                    //     'cancelText': '取消',
                    //     'okText': '注册',
                    //     'sure': this.registration,
                    //     'type': 'warning'
                    // })
                }

            } else{
                this.setState({
                   classStatus:planDetailInfo
                })
            }
        }
        registration = () => { // 注册弹层
            this.setState({
                isRegTra: true
            })
        }
        cancelRe = () => { // 取消注册
            this.setState({
                isRegTra: false
            })
            const form = this.formPorps.props.form;
            form.resetFields()
        }
        toRegistration = () => { // 注册
            const {planClassValue, planId, userId,enterpriseId} = this.state
            const form = this.formPorps.props.form;
            form.validateFields((err, fieldsValue) => {
                if (!err) {
                    let formData = JSON.parse(JSON.stringify(fieldsValue))
                    let data = {
                        enrollType: 1, //  1 计划 2课程
                        userClassPlanIdQo: {
                            classId: planClassValue.id,
                            planId: planId,
                            userId: userId
                        },
                        organization: enterpriseId
                    }
                    let datas = Object.assign(formData, data);
                    this.insertSocietyStudent(datas)
                }else{
                    this.mesWarning('您有未填写信息')
                }
            });

        }

        insertSocietyStudent = async (data) => { // 确定注册
            await insertSocietyStudent(data).then( res =>{
                if (res) {
                    this.mesSuccess('注册成功')
                    const form = this.formPorps.props.form;
                    form.resetFields()
                    this.setState({
                        isRegTra: false
                    })
                }
            })
        }
        studentEnroll=()=>{ // 确定继续报名
            this.setState({
                classStatus:2,
                isModalVisible:false,
            },()=>{
                this.Signup(-1)
            })
        }

        Joinstu = () => { // 获取更多班级弹层
            this.setState({
                isModalVisible: true
            }, () => {
                this.planDetailClassListPage()
            })
        }

        async  planDetailClassListPage () { // 获取培训计划详情-查看更多可选班级数据-分页
            const {planId,pageNum,pageSize}=this.state
            let data = {
                planId: planId,
                pageNum: pageNum,
                pageSize: pageSize
            }
            const morClassList  = await planDetailClassListPage(data)
            this.setState({
                morClassList,
                pageNum: morClassList.pageNum,
                total: morClassList.total,
                pageSize: morClassList.pageSize

            },()=> {
            })

        }

        onShowSizeChange=(current, pageSize)=>{ // 分页
            this.setState({
                pageSize:pageSize,
                pageNum:current
            },()=>{
                this.planDetailClassListPage()
            })
        }
        onChange=(page)=>{
            this.setState({
                pageNum:page
            },()=>{
                this.planDetailClassListPage()
            })
        }

        cancel = e => { // 取消更多弹层
            this.setState({ isModalVisible:false });
        }
        cancelBm = e => { // 取消个人信息弹层
            this.setState({ sign:false });
        }

        //获取推荐课程信息
        async planDetailCourseList(){
            const {enterpriseId}=this.state
            const  planDetailCourseListInfo= await planDetailCourseList({enterpriseId:enterpriseId})
            this.setState({
                planDetailCourseListInfo
            })
        }
        async studentCollectionStatus () { // 收藏状态查询
            const {planId, userId, pageNum, pageSize } = this.state
            let data = {
                planId: planId,
                userId: userId,
                pageNum: pageNum,
                pageSize: pageSize
            }
            const CollectionStatus = await studentCollectionStatus(data)
            this.setState({
                CollectionStatus
            })
        }

        collectionPlan = async (key) => { // 收藏计划
            const {planId, userId, pageNum, pageSize } = this.state
            const data = {
                planId: planId,
                userId: userId,
                pageNum: pageNum,
                pageSize: pageSize
            }
            await studentCollectionPlan(data).then( res =>{
                if (res) {
                    this.mesSuccess('收藏成功')
                    this.studentCollectionStatus()
                }
            })
        }

        cancelCollectionPlan = async (key) => { // 取消收藏
            const {planId, userId, pageNum, pageSize } = this.state
            const data = {
                planId: planId,
                userId: userId,
                pageNum: pageNum,
                pageSize: pageSize
            }
            await studentCancelCollectionPlan(data).then( res =>{
                if (res) {
                    this.mesSuccess('取消成功')
                    this.studentCollectionStatus()
                }
            })
            // this.setState({
            //     CollectionStatus: 2
            // })
        }
        messageTip = () => { // 未开发功能提示
            // message.warning('该功能正在建设中，敬请期待…');
            this.mesWarning('该功能正在建设中，敬请期待…')
        };
        //跳转详情
        toDetail=(item)=>{
            this.props.history.push(`/courseintr/?${encodeURI(JSON.stringify({id:item.id}))}`)

        }
        onClititle = () =>{
            this.mesWarning("课程已结束");
        }
        canWarm = () =>{
            this.setState({
                isWarm:false
            })
        }
        //登录
        handleLoginWarm=()=>{
            window.location = `${logoutUrl}?url=${window.location.origin}&out=true`
        }
        render() {
            const {activeKey,isWarm,sign,planDetailInfo,planClassList,planClassValue,outlineData,teacherList,classStatus,
                topTimeVoList,isModalVisible, morClassList, pageNum, total, pageSize, planDetailCourseListInfo,planDetailSignStudentDataInfo,
                CollectionStatus,tableLoading,userId,isRegTra
            } =this.state
            const { $$img,apiPrefix } = window;
            let Introductionpic= <div>
                {
                    tableLoading?<Spin className="Spin-content" tip="加载中"/>:(
                        <Introduced trainingIntroduction={planDetailInfo.trainingIntroduction}/>
                    )
                }
            </div>
            let Syllabus= <div>
                {
                    tableLoading?<Spin className="Spin-content" tip="加载中"/>:(
                        <Outline outlineData={outlineData}/>
                    )
                }
            </div>
            let Teacher= <div>
                {
                    tableLoading?<Spin className="Spin-content" tip="加载中"/>:(
                        <Teachershow teacherList={teacherList}/>
                    )
                }
            </div>
            // let arr = [{ ...one, title: one.name }, two, three];
        return (
            <div className="resource_main">
                <Heder navName={'traininglistp'}/>
                <div className="resource_container">
                    <div className='CamelCasePlandetails'>
                        <div className="Camel-content">
                            <div className="crumbs">您的位置：<span onClick={()=>{this.props.history.go(-1)}}>培训计划</span>  /  培训计划详情</div>
                            <div className="pland">
                                <div className="left-top">
                                    <div className="left">
                                        <div className="pic">
                                            <RenderImg type={planDetailInfo.mediaType} size='m' id={planDetailInfo.planImg}/>
                                            <i>{planDetailInfo.planStatus == 1?'未开始':planDetailInfo.planStatus == 2?'进行中':planDetailInfo.planStatus == 3?'已结束': '已作废' }</i>
                                            <span>{planDetailInfo.registerColseDate? '报名剩余时间：'+planDetailInfo.registerColseDate :''}</span>
                                        </div>
                                        <div className="ophone" onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
                                            {/*planImg*/}
                                            <i><img src={`${$$img(urlPre + "title_icon21.png")}`}/></i>
                                            <span >联系我们</span>
                                            <span style={{display:"none"}}>项目负责人<b>{planDetailInfo.planLeader}&nbsp;&nbsp;{planDetailInfo.planLeaderPhone}</b></span>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <h3>{planDetailInfo.planName}
                                            <div>
                                                {/*1已经收藏 2未收藏*/}
                                                {
                                                    CollectionStatus == 2? <span onClick={this.collectionPlan.bind(this)}><img src={`${$$img(urlPre + "title_icon19.png")}`}/></span>:
                                                        <span><img onClick={this.cancelCollectionPlan.bind(this)} src={require(`@/assets/img/shouc.png`)}/></span>
                                                }
                                                {/*<span className={'svg_fx'} onClick={this.messageTip}><Fenxiang/></span>*/}
                                                <span className={'svg_fx'}> </span>
                                            </div>
                                        </h3>
                                        <p>{planDetailInfo.trainingOverview}</p>
                                        <ul>
                                            <li>
                                                <i>{planDetailInfo.taskNum}</i>
                                                <em>任务</em>
                                            </li>
                                            <li>{planClassValue?planClassValue.classNum==='不限制'?
                                                <i>{planClassValue.classNum}人数</i>:<i>{planClassValue.classNum}人</i>:<i>0人</i>
                                            }
                                                <em>可报名人数</em>
                                            </li>
                                            <li>
                                                <i>{planClassValue?planClassValue.signNum: 0}人</i>
                                                <em>已报名</em>
                                            </li>
                                            <li>
                                                {
                                                    planDetailInfo.learningModel == 0 ? <i>解锁模式</i> : <i>自由模式</i>
                                                }
                                                <em>学习模式</em>
                                            </li>
                                            <li>
                                                <i>{planDetailInfo.planStartTime} ~ {planDetailInfo.planEndTime}</i>
                                                <em>计划周期</em>
                                            </li>
                                        </ul>
                                        <span className="span1">可选班次</span>
                                        <div className='more_btn'>
                                            <Radio.Group  className="list" value={planClassValue ? planClassValue.id : null}  >
                                                {planClassList.length>0?planClassList.map((item,index)=>{
                                                    if (index < 3) {
                                                        return(
                                                            <Radio.Button onClick={()=>{this.changeClass(item)}} key={item.id} value={item.id}>
                                                                {item.className}
                                                            </Radio.Button>
                                                        )
                                                    }
                                                }):<span style={{fontSize:'12px'}}>暂无可选班级</span>}
                                            </Radio.Group>
                                            {
                                                planDetailInfo.planClassNum > 3 ? <li className="li-on" onClick={this.Joinstu}>更多班级</li>  : null
                                            }

                                        </div>

                                        <Modal
                                            title="选择班级"
                                            visible={isModalVisible}
                                            onCancel={this.cancel}
                                            centered={true}
                                            wrapClassName="Selectcent"
                                            width={800}
                                            footer={
                                                <div className='pos-btn'>
                                                    <Button className="but" onClick={this.cancel}>取消</Button>
                                                    <Button className="but" type="primary" onClick={this.cancel}>确定</Button>
                                                </div>
                                            }
                                        >
                                            <div className="table-content">
                                                {
                                                    morClassList?.data?.length>0?(
                                                        <Radio.Group  className="list" value={planClassValue?.id}  >
                                                            {morClassList.data?.map((item,index)=>{
                                                                return(
                                                                    <Radio.Button onClick={()=>{this.changeClass(item)}} key={item.id} value={item.id}>
                                                                        {item.className}
                                                                    </Radio.Button>
                                                                )
                                                            })}
                                                        </Radio.Group>
                                                    ):<NoData/>
                                                }
                                                <Row>
                                                    {
                                                        morClassList?.data?.length>0?(
                                                            <Pagination className="Pagination-fs"
                                                                        showSizeChanger
                                                                        onShowSizeChange={this.onShowSizeChange}
                                                                        onChange={this.onChange}
                                                                // defaultCurrent={1}
                                                                        total={total}
                                                                // pageSize={pageSize}
                                                                        current={pageNum}
                                                            />
                                                        ):""
                                                    }
                                                </Row>
                                            </div>
                                        </Modal>

                                        <Button
                                            type="primary"
                                            disabled={classStatus!==2&&classStatus!==6&&classStatus!==9&&classStatus!==10}
                                            onClick={this.Signup.bind(this)} >
                                            {classStatus===1?'暂未开始':classStatus===2?'立即报名':classStatus===3?'已报名':classStatus===4?'报名成功'
                                            :classStatus===5?'暂无班级':classStatus===6?'重新报名':classStatus===7?'审核驳回':classStatus===8?'停止报名':classStatus===9?'开始学习':classStatus===10?'继续学习':classStatus===11?'计划结束':'选择班级'
                                            }
                                        </Button>
                                    </div>
                                </div>
                                <div className="right-top">
                                    <ul>
                                        {
                                            topTimeVoList?.map((item,index) => {
                                                return(
                                                    <li className={item.status === 1?'active':''}>
                                                        <span><i></i></span>
                                                        <h5>{item.time}</h5>
                                                        <p>{item.name}</p>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="list-bottom">
                                <Row>
                                    <Col span={17}>
                                        <div className="table-conte">
                                        <Tabs activeKey={activeKey} onChange={this.callback.bind(this)}>
                                            <TabPane tab="计划介绍" key="1">
                                            </TabPane>
                                            <TabPane tab="计划任务" key="2">
                                            </TabPane>
                                            <TabPane tab="培训团队" key="3">
                                            </TabPane>
                                        </Tabs>
                                        {
                                            activeKey === "1" ? Introductionpic : (activeKey === "2" ? Syllabus :activeKey === "3" ? Teacher :"")
                                        }
                                    </div>
                                    </Col>
                                    <Col span={7} id="left-top">
                                        <div className="right-table">
                                            <div className="table-top" id="loading" style={{display:"none",background:`url('${$$img("cvec-space-front-peixun/title_icon50.png")}') no-repeat center left`}}>
                                                <span className="img">
                                                    <img src={`${$$img(urlPre + "pic13.png")}`}/>
                                                </span>
                                                <p>用手机扫描二维码<br/>分享课程给朋友～</p>
                                            </div>
                                            <ul>
                                                <h3><img src={`${$$img(urlPre + "title_icon22.png")}`}/>推荐课程</h3>
                                                {
                                                    planDetailCourseListInfo.length>0?planDetailCourseListInfo.map((item,index)=>{
                                                        return(
                                                            <li >
                                                                        <div className="pic" >
                                                                            <RenderImg type={item.mediaType} size='m' id={item.courseImg}/>
                                                                        </div>
                                                                        <div className="text">
                                                                            <h4 onClick={()=>{this.toDetail(item)}}>{item.courseName}</h4>
                                                                            {/*<h4 onClick={()=>{this.onClititle()}}>{item.courseName}</h4>*/}
                                                                            <p>{item.courseSummary}</p>
                                                                            <div className="cont">
                                                                                {
                                                                                    item.fullName&&<span><img src={`${$$img(urlPre + "title_icon23.png")}`}/>{item.fullName}</span>
                                                                                }
                                                                                <em>{item.studentNum}人在学</em>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            }):<NoData/>
                                                        }

                                                    </ul>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <Modal
                            title="报名信息"
                            visible={sign}
                            onCancel={this.cancelBm}
                            centered={true}
                            wrapClassName="signupcont"
                            width={800}
                            footer={
                                <div className='pos-btn'>
                                    <Button className="but" onClick={this.cancelBm}>取消</Button>
                                    <Button className="but" type="primary" onClick={this.studentEnrollPlan.bind(this)}>报名</Button>
                                </div>
                            }
                        >
                            <div className="">
                                {
                                    planDetailSignStudentDataInfo.isForm === 1 ?
                                        // isForm = 1
                                        <AddStuInfo
                                            classId={planClassValue.id}
                                            userId={userId}
                                            wrappedComponentRef={form => {
                                                this.formRef = form
                                            }}
                                            getProvinceName={(name) => {
                                                this.setProvinceName(name)
                                            }}
                                            getCityName={(name) => {
                                                this.setCityName(name)
                                            }}
                                            getAreaName={(name) => {
                                                this.setAreaName(name)
                                            }}
                                        /> :
                                        <ul>
                                            <li>姓名：{planDetailSignStudentDataInfo.fullName}</li>
                                            <li>账号：{planDetailSignStudentDataInfo.telNum}</li>
                                            <li>
                                                性别：{
                                                planDetailSignStudentDataInfo.sex === 0 ? '女' : '男'
                                            }
                                            </li>
                                            <li>机构：{planDetailSignStudentDataInfo.organizationName}</li>
                                        </ul>
                                }

                            </div>
                        </Modal>

                        <Modal
                            title="注册信息"
                            visible={isRegTra}
                            onCancel={this.cancelRe}
                            centered={true}
                            wrapClassName="signupcont"
                            width={800}
                            footer={
                                <div className='pos-btn'>
                                    <Button className="but" onClick={this.cancelRe}>取消</Button>
                                    <Button className="but" type="primary" onClick={this.toRegistration.bind(this)}>确定</Button>
                                </div>
                            }
                        >
                            <RegisTration
                                wrappedComponentRef={form => {
                                    this.formPorps = form
                                }}/>
                        </Modal>
                    </div>
                    <div className="resource_footer">
                        <Footer />
                    </div>
                </div>
                <Modal
                    title="温馨提示"
                    visible={isWarm}
                    onCancel={this.canWarm}
                    centered={true}
                    closable={false}
                    wrapClassName="war_Stration"
                    footer={
                        <div className='pos-btn'>
                            <Button className="but" onClick={this.canWarm}>取消</Button>
                            <Button type="primary" onClick={this.registration}>注册</Button>
                            <Button type="primary" onClick={this.handleLoginWarm}>登录</Button>
                        </div>
                    }
                >
                    <div>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="9" fill="white"></circle><path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10.8594 5V11.6307C10.8594 12.1053 10.4746 12.4901 10 12.4901C9.52539 12.4901 9.14062 12.1053 9.14062 11.6307V5C9.14062 4.52539 9.52539 4.14062 10 4.14062C10.4746 4.14062 10.8594 4.52539 10.8594 5ZM10 13.3594C10.6904 13.3594 11.25 13.919 11.25 14.6094C11.25 15.2997 10.6904 15.8594 10 15.8594C9.30965 15.8594 8.75 15.2997 8.75 14.6094C8.75 13.919 9.30965 13.3594 10 13.3594Z" fill="#FFA325"></path></svg>
                        </span>
                        <p>您还未登录，请先登录，如未注册，请先注册</p>
                    </div>
                </Modal>
            </div>
        );
      }
    }
  )
);
