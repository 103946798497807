import React from 'react';

//警告
const SvgWarning = props => {
    return <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" fill="white" />
        <path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10.8594 5V11.6307C10.8594 12.1053 10.4746 12.4901 10 12.4901C9.52539 12.4901 9.14062 12.1053 9.14062 11.6307V5C9.14062 4.52539 9.52539 4.14062 10 4.14062C10.4746 4.14062 10.8594 4.52539 10.8594 5ZM10 13.3594C10.6904 13.3594 11.25 13.919 11.25 14.6094C11.25 15.2997 10.6904 15.8594 10 15.8594C9.30965 15.8594 8.75 15.2997 8.75 14.6094C8.75 13.919 9.30965 13.3594 10 13.3594Z" fill="#FFA325" />
    </svg>
}

export default SvgWarning;