import { post } from '../../until/axios_instans';
// 查询新闻资讯一级分类
export function peixunIndexNewsOneCategory(data) {
    return post(window.apiZixun + '/api/v1/sdk/news/peixun/index/peixunIndexNewsOneCategory', data);
}
//查询资讯首页的二级分类和新闻列表
export function peixunIndexNewsAndTwoCategory(data) {
    return post(window.apiZixun + '/api/v1/sdk/news/peixun/index/peixunIndexNewsAndTwoCategory', data);
}

//查询二级分类下新闻列表
export function peixunIndexNewsByCategory(data) {
    return post(window.apiZixun + '/api/v1/sdk/news/peixun/index/peixunIndexNewsByCategory', data);
}
//
// //查询新闻详情
// export function peixunIndexNewsDetail(data) {
//     console.log('window.apiZixun',window.apiZixun)
//     return post(window.apiZixun + '/api/v1/sdk/news/peixun/index/peixunIndexNewsDetail', data);
// }
