import Cookies from 'js-cookie';
import { domainUrl } from '../config/secret';
const key = 'Main_Info';

export const setCookieToken = (value, time) => {
    Cookies.set(key, value, { path: '/', expires: time, domain: domainUrl });
}

export const removeCookieToken = ()=>{
    Cookies.remove(key,{path:'/',domain: domainUrl});
}
