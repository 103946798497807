import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Form,Spin, } from 'antd';
import { withRouter, } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import {storyDetail, thumbsUpStudyStory,cancelThumbsUpStudyStory, collectStudyStory,cancelCollectStudyStory, studyStoryCollectThumbsUpStatus} from '../../api/story/index'
import Cookies from "js-cookie";
import RenderImg from '../../components/renderImg/index'
import {getUrlParams} from '../../until/getUrl'
const urlPre = "cvec-space-front-peixun/";
export default Form.create()(
    withRouter(
        class Learnspage extends React.Component {
            state = {
                Relatlist:[],
                learnData:{},
                storyId:'',
                planMainVo:{},//关联计划
                collectionStutas: '', // 收藏状态1 已经收藏 2未收藏
                thumbsUpStatus: '', // 点赞状态1 已经收藏 2未收藏
                relationType: '', // 操作类型：1点赞 2收藏
                userId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).userId:'', // 用户id
                tableLoading:true
            };
            componentDidMount() {
                const {userId}=this.state
                const storyId = getUrlParams ('id',this.props.location.search);
                this.setState({
                    storyId: storyId
                },()=>{
                    this.storyDetail()
                    if(userId){
                        this.studyStoryCollectThumbsUpStatus(1) // 查询点赞状态
                        this.studyStoryCollectThumbsUpStatus(2) // 查询收藏状态
                    }
                })
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
            async storyDetail(){
                const {storyId}=this.state
                const  learnData=await storyDetail({storyId:storyId})
                this.setState({
                    learnData,
                    planMainVo:learnData.planMainVo,
                    tableLoading:false
                })
            }
            goBackPage = () => {
                this.props.history.goBack();  //返回上一页这段代码
            }
            collection = async (key) => { // 收藏学习故事
                const {storyId, userId} = this.state
                const data = {
                    storyId: storyId,
                    userId: userId,
                }
                await collectStudyStory(data).then( res =>{
                    if (res) {
                        this.mesSuccess('收藏成功')
                        this.storyDetail()
                        this.studyStoryCollectThumbsUpStatus(2)
                        // this.storyDetail()
                    }
                })
            }

            cancelCollection = async (key) => { // 取消收藏
                const {storyId, userId} = this.state
                const data = {
                    storyId: storyId,
                    userId: userId,
                }
                await cancelCollectStudyStory(data).then( res =>{
                    if (res) {
                        this.mesSuccess('取消成功')
                        this.storyDetail()
                        this.studyStoryCollectThumbsUpStatus(2)
                        // this.storyDetail()
                    }
                })
            }
            thumbsUp = async (key) => { // 点赞学习故事
                const {storyId, userId} = this.state
                const data = {
                    storyId: storyId,
                    userId: userId,
                }
                await thumbsUpStudyStory(data).then( res =>{
                    if (res) {
                        this.mesSuccess('点赞成功')
                        this.storyDetail()
                        this.studyStoryCollectThumbsUpStatus(1)
                        // this.storyDetail()
                    }
                })
            }

            cancelThumbsUp = async (key) => { // 取消点赞
                const {storyId, userId} = this.state
                const data = {
                    storyId: storyId,
                    userId: userId
                }
                await cancelThumbsUpStudyStory(data).then( res =>{
                    if (res) {
                        this.mesSuccess('取消成功')
                        this.storyDetail()
                        this.studyStoryCollectThumbsUpStatus(1)
                        // this.storyDetail()
                    }
                })
            }
            async studyStoryCollectThumbsUpStatus (type) { // 点赞收藏状态查询
                const {storyId, userId} = this.state
                const data = {
                    storyId: storyId,
                    userId: userId,
                    relationType: type
                }
                const relationType =  await studyStoryCollectThumbsUpStatus(data)
                if (type == 1 ) {
                    this.setState({
                        thumbsUpStatus: relationType
                    })
                } else {
                    this.setState({
                        collectionStutas: relationType
                    })
                }
            }
            messageTip = () => { // 未开发功能提示
                // message.warning('该功能正在建设中，敬请期待…');
                this.mesWarning('该功能正在建设中，敬请期待…')
            };

            render() {
                const {planMainVo,learnData, thumbsUpStatus,collectionStutas,tableLoading} =this.state
                const { $$img, } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'learnstory'}/>
                        <div className="resource_container">
                            <div className='CamelCaseNewsNewscont'>
                                <div className="Camel-content learnspage" style={{minHeight: 'calc(100vh - 281px)'}}>
                                    <div className="link-return">
                                        <div className="crumbs">您的位置：<span onClick={()=>{this.props.history.go(-1)}}>学习故事</span>  /  故事详情</div>
                                    </div>
                                    {
                                        tableLoading?<Spin className="Spin-content" tip="加载中"/>:(
                                            <div className="content">
                                                <h2>{learnData.storyTitle}</h2>
                                                <span>
                                            <i className="i-on">{/*<img src={$$img(learnData.portraitId)}/>*/}<RenderImg type={learnData.mediaType} size='m' id={learnData.portraitId}/>{learnData.createUserName}</i>
                                                    {/*<i>来源：澎湃新闻  2020-07-23 16:24  </i>*/}
                                                    <i>{learnData.createTime} </i>
                                            <em>
                                                {/*是否点赞（0否 1是）*/}
                                                {
                                                    thumbsUpStatus === 2 ? <b onClick={this.thumbsUp.bind(this)}><img src={`${$$img(urlPre + "title_icon35.png")}`}/>{learnData.thumbsUpNum}</b>:
                                                        <b onClick={this.cancelThumbsUp.bind(this)}><img src={`${$$img(urlPre + "title_icon36.png")}`}/>{learnData.thumbsUpNum}</b>
                                                }
                                                {/*是否收藏（0否1是)*/}
                                                {
                                                    collectionStutas === 2?<b onClick={this.collection.bind(this)}><img src={`${$$img(urlPre + "title_icon37.png")}`}/>{learnData.collectionNum}</b>:
                                                        <b onClick={this.cancelCollection.bind(this)}><img src={`${$$img(urlPre + "title_icon38.png")}`}/>{learnData.collectionNum}</b>
                                                }

                                                {/*分享图片*/}
                                                {/*<b onClick={this.messageTip}><img src={`${$$img(urlPre + "title_icon39.png")}`}/></b>*/}
                                            </em>
                                        </span>
                                                <div className="docv tableStyle" dangerouslySetInnerHTML={{__html: learnData.storyContent}}>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        planMainVo?(
                                            <div className='spanPlan'>
                                                <h5 className='span_plan_title'>关联计划</h5>
                                                <div className='plan_box'>
                                                    <div className="pic-img">
                                                        {/*<img src={$$img(planMainVo.planImg)}/>*/}
                                                        <RenderImg type={planMainVo.mediaType} size='m' id={planMainVo.planImg}/>
                                                    </div>

                                                    <div className="text">
                                                        <h3 className='plan_name'>{planMainVo.planName}</h3>
                                                        <div className='plan_con'>{planMainVo.trainingOverview}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ):""
                                    }
                                </div>
                            </div>
                            <div style={{display:"none"}}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#27CDA2" />
                                    <g clip-path="url(#clip0)">
                                        <path d="M16.3716 6.75867L8.95181 14.1785C8.58434 14.546 7.98855 14.546 7.62107 14.1785L3.62888 10.1863C3.2614 9.81884 3.2614 9.22303 3.62888 8.85557C3.99636 8.4881 4.59214 8.4881 4.95962 8.85557L8.28645 12.1824L15.0409 5.42795C15.4084 5.06048 16.0042 5.06048 16.3716 5.42795C16.7391 5.79539 16.7391 6.3912 16.3716 6.75867Z" fill="white" />
                                    </g>
                                    <path d="M9.99998 20.0002C4.47707 20.0002 0 15.5229 0 10C0 4.4773 4.47709 0 9.99998 0C15.5229 0 20 4.4773 20 10C20 15.5229 15.5229 20.0002 9.99998 20.0002ZM16.1101 6.48392L15.7887 6.16255C15.6112 5.98499 15.3235 5.98499 15.146 6.16255L8.48008 12.6902L6.03295 10.1291C5.95258 10.0488 5.7435 10.1278 5.56593 10.3054L5.24455 10.6268C5.06699 10.8043 4.98846 11.0134 5.06878 11.0935L8.33406 14.5104C8.41395 14.5907 8.62303 14.5115 8.80061 14.3342L9.12155 14.0128C9.17835 13.9564 9.22008 13.898 9.25469 13.8405L16.1101 7.12692C16.2877 6.94957 16.2877 6.66148 16.1101 6.48392Z" fill="#27CDA2" />
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33398 3.16663)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
