import { post,get } from '../../until/axios_instans';
// 获取用户角色列表
export let getUserRole = () => {
    return post("/api/gongyong/api/v1/address/identityIdEnum")
}

// 获取个人信息接口
export let getUserInfo = () => {
    return post("/api/ucenter/api/v1/verification/effective")
}
