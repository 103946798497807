import React from 'react';
import './index.scss';
import {Collapse} from 'antd';
import NoData from '../noData'
const { Panel } = Collapse;
const urlPre = "cvec-space-front-peixun/";
export default class Outline extends React.Component {
    state = {
        collapseKey:"0",
    }
    componentDidMount() {
    }
    handlecallback = (key) => {
        this.setState({
            collapseKey: key
        },()=>{
            console.log('collapseKey',this.state.collapseKey)
        })
    }
    render() {
        const {collapseKey} =this.state
        const {outlineData}=this.props
        const { $$img,apiPrefix } = window;
        let openList=[]
        outlineData.map(i=>{
            openList.push(i.id)
        })
        return (
            <div className='CamelCaseOutline'>
                {
                    outlineData.length>0?(
                        <Collapse expandIconPosition='right' onChange={this.handlecallback} defaultActiveKey={openList}>
                            {outlineData&&outlineData.map((item,index)=>{
                                //taskType=1为模型
                                return(
                                    item.taskType===1&&item.childList.length>0?<Panel header={
                                        <div className="header-icon">
                                            <img src={`${$$img(urlPre + "title_icon24.png")}`}/>模块：{item.taskName}
                                        </div>
                                    } key={item.id} extra={collapseKey.includes(item.id) ? '收起' : '展开'}>
                                        <ul>
                                            {item.childList&&item.childList.length>0&&item.childList.map((todo,key)=>{
                                                return(
                                                    <li>
                                                        <div className="li_top">
                                                            <span
                                                                className={ todo.taskType===2?"on-1":todo.taskType===3?'on-2':todo.taskType===4?'on-3':todo.taskType===5?'on-4':todo.taskType===6?'on-5':'on-6'}
                                                            >
                                                                {
                                                                    todo.taskType===1 ? '模块' : todo.taskType===2 ?
                                                                        <img src={`${$$img(urlPre + "title_icon6.png")}`}/> :  todo.taskType===3 ?
                                                                            <img src={`${$$img(urlPre + "title_icon4.png")}`}/> :  todo.taskType===4 ?
                                                                                <img src={`${$$img(urlPre + "title_icon5.png")}`}/> : todo.taskType===5 ?
                                                                                    <img src={`${$$img(urlPre + "title_icon7.png")}`}/>:todo.taskType===6 ?
                                                                                        <img src={`${$$img(urlPre + "title_icon49.png")}`}/> :<img src={require('@/assets/img/i5.png')}/>
                                                                }
                                                                {
                                                                    todo.taskType===1 ? '模块' : todo.taskType===2 ? '课程' :  todo.taskType===3 ? '调研' :  todo.taskType===4 ? '作业' : todo.taskType===5 ? '考试' : todo.taskType===6 ?'签到' : '直播'
                                                                }
                                                        </span>
                                                            <h3>
                                                                <div>{todo.taskName}</div>
                                                                {todo.isMust===0?<i className="on">选修</i>:<i>必修</i>}
                                                            </h3>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Panel>:item.taskType===1?<div className={'ant-collapse-item ant-collapse-item-active'}>
                                        <div className="ant-collapse-header">
                                            <div className="header-icon">
                                                <img src={`${$$img(urlPre + "title_icon24.png")}`}/>模块：{item.taskName}
                                            </div>
                                        </div>
                                    </div>: <li>
                                        <div className="li_top">
                                            <span className={ item.taskType===2?"on-1":item.taskType===3?'on-2':item.taskType===4?'on-3':item.taskType===5?'on-4':item.taskType===6?'on-5':'on-6'}>
                                            {
                                                item.taskType===1 ? '模块' : item.taskType===2 ?
                                                    <img src={`${$$img(urlPre + "title_icon6.png")}`}/> :  item.taskType===3 ?
                                                        <img src={`${$$img(urlPre + "title_icon4.png")}`}/> :  item.taskType===4 ?
                                                            <img src={`${$$img(urlPre + "title_icon5.png")}`}/> : item.taskType===5 ?
                                                                <img src={`${$$img(urlPre + "title_icon7.png")}`}/>:item.taskType===6 ?
                                                                    <img src={`${$$img(urlPre + "title_icon49.png")}`}/> :<img src={require('@/assets/img/i5.png')}/>
                                            }
                                                {
                                                    item.taskType===1 ? '模块' : item.taskType===2 ? '课程' :  item.taskType===3 ? '调研' :  item.taskType===4 ? '作业' : item.taskType===5 ? '考试' : item.taskType===6 ?'签到' : '直播'
                                                }
                                        </span>
                                            <h3><div>{item.taskName}</div>{item.isMust===0?<i className="on">选修</i>:<i>必修</i>}</h3>
                                        </div>
                                    </li>

                                )
                            })}
                        </Collapse>
                    ):<NoData/>
                }

            </div>
        )
    }
}
