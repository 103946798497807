import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Input,Form } from 'antd';
import { withRouter, } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import {peixunIndexNewsDetail} from "@/api/indexnews/index"
const { Search } = Input;

export default Form.create()(
    withRouter(
        class Newscont extends React.Component {
            state = {
                newsId: '', // 新闻id
                oneCategoryId: '', // 一级栏目id
                specialId: '', // 专题id
                twoCategoryId: '', // 二级栏目id
                newsLabel: ['123','234'],
                newsInfo: {} // 新闻信息
            };
            componentWillMount () {
                window.scrollTo(0,0)

                const data = this.props.location.search; //地址栏截取
                const param = data.split("?")[1];
                if (param) {
                    const codeParam = param.includes("%") ? decodeURI(param) : param;
                    const jsonparam = JSON.parse(codeParam);
                    this.setState({
                        newsId: jsonparam.newsId?jsonparam.newsId:'',
                        oneCategoryId: jsonparam.oneCategoryId?jsonparam.oneCategoryId:'',
                        specialId: jsonparam.specialId?jsonparam.specialId:'',
                        twoCategoryId: jsonparam.twoCategoryId?jsonparam.twoCategoryId:'',
                    },()=>{
                        this.peixunIndexNewsDetail(this.state.newsId)
                    });
                }
            }
            async peixunIndexNewsDetail (newsId) { // 查询信息
                const {oneCategoryId,specialId,twoCategoryId}=this.state
                let data = {
                    newsId,
                    oneCategoryId,
                    specialId,
                    twoCategoryId
                }
                await peixunIndexNewsDetail(data).then(res=>{
                    if (res) {
                        this.setState({
                            newsInfo: res,
                            newsLabel: res.newsLabel?JSON.parse(res.newsLabel):[]
                        })
                    }
                })
            }

            render() {
                const {newsInfo,newsLabel} =this.state

                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='CamelCaseNewsNewscont'>
                                <div className="crumbs">您的位置：<span onClick={()=>{this.props.history.go(-1)}}>培训资讯</span>  /  资讯详情</div>
                                <div className="Camel-content">
                                    <h3 className="title_h3"><i className={newsInfo.newsType === 1? '': 'on'}>{newsInfo.newsType === 1 ? '原创' : '转载'  }</i>{newsInfo.newsTitle}</h3>
                                    <div className="time_span">
                                        <div className="span_left">
                                            <ul>
                                                {
                                                    newsLabel && newsLabel.map(item=>{
                                                        return(
                                                            <li>{item}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <b>{newsInfo.releaseTime}</b>
                                            {
                                                newsInfo.newsType === 2 ?
                                                    <b>来源：{newsInfo.articleSource}</b> : ''
                                            }
                                        </div>
                                        <span className="span_right">
                                             作者：{newsInfo.authorName}
                                        </span>
                                    </div>
                                    <div className="text_page">
                                        <div className='tableStyle' dangerouslySetInnerHTML={{__html: newsInfo.newsContent}}>
                                        </div>
                                    </div>
                                    <div className="page-fy">
                                        {
                                            newsInfo.lastNewsId && <span className="left-fy" onClick={()=>this.peixunIndexNewsDetail(newsInfo.lastNewsId)}><img src={require('../../assets/img/jt.png')}/><p>上一篇：{newsInfo.lastNewsTitle}</p></span>
                                        }

                                        {
                                            newsInfo.nextNewsId && <span className="right-fy" onClick={()=>this.peixunIndexNewsDetail(newsInfo.nextNewsId)}><img src={require('../../assets/img/jt.png')}/><p>下一篇：{newsInfo.nextNewsTitle}</p></span>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
