import React from 'react';
import './index.scss';
import {Row, Col, Radio,Input,Pagination,Form,} from 'antd';
import { withRouter, } from "react-router-dom";
import '@/reset_style/index.less';
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import {planList, planLabelList,planDetailTop} from '../../api/plan/index'
import Cookies from 'js-cookie';
import RenderImg from '../../components/renderImg/index'
import NoData from "../../components/noData"
const urlPre = "cvec-space-front-peixun/";
const { Search } = Input;

export default Form.create()(
    withRouter(
        class Traininglistp extends React.Component {
            state = {
                PlanDataList:null,
                enterpriseId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'',//机构id,
                // enterpriseId: 'e6ed7ce7b7cd4594a1f54ff90212d724',
                // enterpriseId: '123',
                pageNum:1, // 当前页
                pageSize:10, // 每页记录数
                total:0,
                planName:'',//计划名称
                planStatus:'',//计划状态
                planIds: [] , // 计划id集合
                planLabelListInfo: [], // 计划标签
                labelId: '', // 计划标签id
                value: 1,
                tableLoading:true,
                userId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).userId:'',

            };
            componentDidMount() {
                const data = this.props.location.search; //地址栏截取
                const param = data.split("?")[1];
                this.planLabelList()
                if (param) {
                    const codeParam = param.includes("%") ? decodeURI(param) : param;
                    try {
                        const jsonparam = JSON.parse(codeParam);
                        console.log('jsonparam',jsonparam)
                        this.setState({
                            planIds: jsonparam.planIds,
                        },()=>{
                            this.planList()
                        });
                    } catch(e) {
                        console.log("不是json")
                    }
                } else {
                    this.planList()
                }
                this.planList()
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

            }
            //列表
            async planList(){
                const {enterpriseId,pageNum,planName,planStatus,pageSize, planIds,labelId}=this.state
                const params={
                    enterpriseId:enterpriseId,
                    pageNum:pageNum,
                    pageSize:pageSize,
                    planName:planName,
                    planStatus:planStatus,
                    planIds:planIds,
                    labelId: labelId
                }
                const PlanDataList=await planList(params)
                console.log("PlanDataList.data",PlanDataList.data)
                this.setState({
                    PlanDataList: PlanDataList.data,
                    total:PlanDataList.total,
                    tableLoading:false
                },()=>{
                    window.elGoTop && window.elGoTop('body')
                })

            }
            async planLabelList () { // 获取计划标签
                const {enterpriseId}=this.state
                const params={enterpriseId:enterpriseId}
                const planLabelListInfo=await planLabelList(params)
                console.log("planLabelList.data",planLabelList.data)
                this.setState({
                    planLabelListInfo
                })
            }
            handlLable = (id) => { // 计划标签选择
                this.setState({
                    labelId: id,
                    pageNum:1,
                }, ()=> {
                    this.planList()
                })
            }

            onChangeStu = e => {
                console.log('radio checked', e.target.value);
                this.setState({
                    pageNum:1,
                    planStatus: e.target.value,
                }, ()=> {
                    this.planList()
                });
            };

            onShowSizeChange=(current, pageSize)=>{
                this.setState({
                    pageSize:pageSize,
                    pageNum:current
                },()=>{
                    this.planList()
                })
            }
            onChange=(page)=>{
                this.setState({
                    pageNum:page
                },()=>{
                    this.planList()
                })
            }
            //搜索
            onSearch = (value) => {
                this.setState({
                    planName:value,
                    pageNum: 1
                },()=>{
                    this.planList()
                })
            }
            //跳转详情
            toDetail=async(item)=>{
                const {userId}=this.state
                const planDetailInfo=await planDetailTop({planId:item.planId,userId:userId})
                if (planDetailInfo) {
                    this.props.history.push(`/plandetails/?${encodeURI(JSON.stringify({id:item.planId}))}`)
                } else {
                    return
                }
            }
            render() {
                const {PlanDataList,total,pageSize, pageNum,planLabelListInfo, planStatus,tableLoading} =this.state
                const { $$img,apiPrefix } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'traininglistp'}/>
                        <div className="resource_container">
                            <div className='CamelCaseTraininglist'>
                                <div className="classification">
                                    <div className="Camel-content">
                                        <Row>
                                            <Col span={24} className="col-ul">
                                                <span className="span1">培训计划类别</span>
                                                <Radio.Group  className="nav-tab" defaultValue={''}>
                                                    <Radio.Button key={-1} value={''} onClick={() => this.handlLable('')}>全部</Radio.Button>

                                                    {planLabelListInfo?.map((item,index)=>{
                                                        return(
                                                            <Radio.Button key={item.labelId} value={item.labelId} onClick={() => this.handlLable(item.labelId)}>
                                                                {item.labelName}
                                                            </Radio.Button>
                                                        )
                                                    })}
                                                </Radio.Group>
                                            </Col>
                                            <Col span={24} className="col-ul">
                                                <span className="span1">培训计划状态</span>
                                                <Radio.Group  className="nav-tab"  onChange={this.onChangeStu} value={this.state.planStatus}>
                                                    {/*<Radio.Button key={0} value="0">*/}
                                                    {/*    报名中*/}
                                                    {/*</Radio.Button>*/}
                                                    <Radio.Button key={1} value={1} >
                                                        未开始
                                                    </Radio.Button>
                                                    <Radio.Button key={2} value={2}>
                                                        进行中
                                                    </Radio.Button>
                                                    <Radio.Button key={3} value={3}>
                                                        已结束
                                                    </Radio.Button>
                                                    <Radio.Button key={4} value={4}>
                                                        已作废
                                                    </Radio.Button>
                                                </Radio.Group>
                                            </Col>
                                            <Col span={24} className="col-bottom">
                                                <div className="left">
                                                    <span>共查到{total}个计划</span>
                                                </div>
                                                <div className="right">
                                                    <Search   onSearch={this.onSearch} placeholder="请输入计划名称"/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                {/*培训计划*/}
                                <div className='CamelCaseplan'>
                                    {
                                        tableLoading? <section style={{ textAlign: "center" }}>
                                            <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                            <p>数据正在加载中...</p>
                                        </section>:(
                                            <div className="Camel-content">
                                                {
                                                    PlanDataList.length>0?(
                                                        <Row>
                                                            {
                                                                PlanDataList&&PlanDataList.map((item,index)=>{
                                                                    return(
                                                                        <Col className="col">
                                                                            <div className="left" onClick={()=>{this.toDetail(item)}} >
                                                                                <RenderImg type={item.mediaType} size='m' id={item.planImg}/>
                                                                            </div>
                                                                            <div className="right">
                                                                                <div className="title-h3" onClick={()=>{this.toDetail(item)}} >
                                                                                    <img src={`${$$img(urlPre + "title_icon3.png")}`}/>{item.planName}
                                                                                </div>
                                                                                <ul className="ul1">
                                                                                    {
                                                                                        item.labelList&&item.labelList.length>0&&item.labelList.map((itm)=>{
                                                                                            return(
                                                                                                <li>{itm}</li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                                <p>{item.trainingOverview}</p>
                                                                                <ul className="ul2">
                                                                                    {
                                                                                        item.planTaskList&& item.planTaskList.length>0&&item.planTaskList.map((itma)=>{
                                                                                            return(
                                                                                                <li>
                                                                                                    {itma==='课程'?
                                                                                                        <i className="i1"><img src={`${$$img(urlPre + "title_icon6.png")}`}/></i>:itma==='调研'?
                                                                                                            <i className="i2"><img src={`${$$img(urlPre + "title_icon4.png")}`}/></i>:itma==='作业'?
                                                                                                                <i className="i3"><img src={`${$$img(urlPre + "title_icon5.png")}`}/></i>:itma==='考试'?
                                                                                                                    <i className="i4"><img src={`${$$img(urlPre + "title_icon7.png")}`}/></i>:itma==='签到'?
                                                                                                                        <i className="i5"><img src={`${$$img(urlPre + "title_icon49.png")}`}/></i>:<i className="i6"><img src={require('@/assets/img/i5.png')}/></i>
                                                                                                    }
                                                                                                    <span>{itma}</span>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </ul>
                                                                                <div className="more">
                                                                                    <span>培训计划周期：{item.planStartTime}-{item.planEndTime?item.planEndTime:'至今'}</span>
                                                                                    <a onClick={()=>{this.toDetail(item)}} href="javascript:;">进入计划&nbsp;&nbsp;<img src={`${$$img(urlPre + "title_icon8.png")}`}/></a>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    ): <NoData/>
                                                }
                                                <Row>
                                                    {
                                                        total?(
                                                            <Pagination style={{float:"right"}}
                                                                        showSizeChanger
                                                                        onShowSizeChange={this.onShowSizeChange}
                                                                        onChange={this.onChange}
                                                                        defaultCurrent={1}
                                                                        total={total}
                                                                        pageSize={pageSize}
                                                                        current={pageNum}
                                                            />
                                                        ):""
                                                    }

                                                </Row>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
