import React from 'react';
import './index.scss';
import NoData from '../noData'
const urlPre = "cvec-space-front-peixun/"

export default class Introduced extends React.Component {
    state = {
        introduced:{}

    }
    componentDidMount() {

    }

    render() {
        const {trainingIntroduction} =this.props
        const { $$img,apiPrefix } = window;
        return (
            <div>
                {
                    trainingIntroduction?(
                        <div className='CamelCaseintroduced tableStyle' dangerouslySetInnerHTML = {{ __html: trainingIntroduction}}>
                        </div>
                    ):<NoData/>
                }
            </div>
        )
    }
}
