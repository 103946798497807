import { post } from '../../until/axios_instans';

//省下拉
export function getProvinceSel(data) {
    return post('/api/ucenter/api/v1/area/province', data);
}
//市下拉
export function getCitySel(data) {
    return post('/api/ucenter/api/v1/area/city', data);
}
//区|县下拉
export function getDistrictSel(data) {
    return post('/api/ucenter/api/v1/area/area', data);
}
