
//前台加密主要是为了防止post请求明文传递密码


//使用例子
// var CryptoJS = require("crypto-js");

// var data = [{id: 1}, {id: 2}]

// // Encrypt
// var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123').toString();

// // Decrypt
// var bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
// var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


import {setDomain, setPreviewQuestion} from "./index";

export const SECRETKEY = 'front_666666';
//应用类型改资源类型 29个删除改成5个
//教学资源  实训资源  文献资料  行业资源  其他
export const resourceFormatList = [
    {
        id: 1, text: '教学资源'
    }, {
        id: 2, text: '实训资源'
    }, {
        id: 3, text: '文献资料'
    }, {
        id: 4, text: '行业资源'
    }, {
        id: 99, text: '其它'
    }
]

export const mediumTypeList = [
    {
        id: 1, text: '文本'
    }, {
        id: 5, text: '视频'
    }, {
        id: 4, text: '音频'
    }, {
        id: 8, text: 'PPT'
    }, {
        id: 3, text: '图片'
    }, {
        id: 6, text: '动画'
    }, {
        id: 9, text: '网页链接'
    }, {
        id: 2, text: '微课'
    }, {
        id: 7, text: '虚拟仿真'
    }, {
        id: 99, text: '其它'
    }
]
const REACT_APP_BUILD_URL = process.env.REACT_APP_BUILD_URL;
const urlObj = {};

export let domainUrl = '' // 域名
export let previewUrl = ''
export let resource_preview = ''
export let baseUrl = 'https://gw.cveducloud.com'//axios baseURL
export let usUrl = '' // 个人中心
export let loginDomainUrl = '' // 学生端个人项目计划详情页
export let gotoClassDetUrl = '' // 学生端个人项目课程详情页
export let gotoPlanListUrl = '' // 学生端个人项目计划列表页
export let gotoAddStoryUrl = '' // 学生端个人项目添加故事页


export const setDomainUrl = (url)=>{
    domainUrl = url
}
export const setResourcePreview = (url)=>{
    resource_preview = url
}
export const setPreviewUrl = (url)=>{
    previewUrl = url
}
export const setBaseUrl = (url)=>{
    baseUrl = url
}
export const setUsUrl = (url)=>{
    usUrl = url
}
export const setLoginDomainUrl = (url)=>{
    loginDomainUrl = url
}
export const setGotoClassDetUrl = (url) => {
    gotoClassDetUrl = url
}
export const setGotoPlanListUrl = (url) => {
    gotoPlanListUrl = url
}
export const setGotoAddStoryUrl = (url) => {
    gotoAddStoryUrl = url
}

// 依赖07接口
export const initSecretUrlByType07 = (config) => {
    setBaseUrl(config?.gw ?? '')
    setUsUrl(config?.sys?.gerenkongjian ?? '')
    let domain = config?.gw?.split('.').reverse().slice(0, 2).reverse().join('.')
    setDomainUrl(domain ? `.${domain}` : '')
    // setResourcePreview('https://cvec-prod.oss-cn-beijing.aliyuncs.com')
    setResourcePreview(localStorage.getItem("innerWebsite")+'public_resource')
    setPreviewUrl(localStorage.getItem("innerWebsite"));
    setLoginDomainUrl(config?.sys?.gerenkongjian + '/#/training/mytraining/trainingpage?')
    setGotoClassDetUrl(config?.sys?.gerenkongjian + '/#/training/mytraining/coursepage?')
    setGotoPlanListUrl(config?.sys?.gerenkongjian + '/#/training/mytraining/trainingplan?')
    setGotoAddStoryUrl(config?.sys?.gerenkongjian + '/#/training/mytraining/learnshar?')
}



// oss地址
//分辨率
export const RESOLVING_POWER= "?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,w_160,h_219,m_fast"
export const url = 'https://fj.cveducloud.com/public_resource/';


export const zyObj = {
    txtNumber: 1, // 文档
    videoNumber: 2, // 视频
    audioNumber:3, // 音频
    imgNumber: 4 // 图片
}
