// 专注时刻
import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Form,} from 'antd';
import { withRouter } from "react-router-dom";
import 'swiper/dist/css/swiper.min.css';
import Swiper from 'swiper';
import RenderImg from '../../components/renderImg/index'

export default Form.create()(
    withRouter(
        class Focusontime extends React.Component {
            state = {
                imgCurPage:1,
                childInfo: {},
                pageNum: '', // 当前页
                pageSize: '', // 当前页每页条数
                zzskList: [], // 图片列表总数
                childList: [], // 展示图片列表
                pageNumChild: 1, // 当前页弹层
                pageSizeChild: 10, // 每页条数弹层
                pagesChild: '', // 总页数弹层
                indexChild: null,// 默认展示图片的索引
                pageClose:false
            };

            handleCloseSwiper=()=>{
                this.galleryTop.destroy(true,true)
                this.setState({
                    pageClose:true
                },()=>{
                    this.getChildList()
                })
            }

            componentDidUpdate(nextPros){

                if(this.props.indexChild!=this.state.indexChild){
                    this.setState({
                        indexChild:this.props.indexChild
                    },()=>{
                        this.getChildList()
                    })
                }
                if(this.props.childInfo!=this.state.childInfo) {
                    let childInfo = this.props.childInfo
                    this.setState({
                        childInfo: childInfo,
                        zzskList: childInfo.data,
                        pageNum: childInfo.pageNum,
                        pageSize: childInfo.pageSize,
                        pagesChild: Math.ceil(childInfo.data.length/10),
                        pageNumChild: Math.ceil(this.props.indexChild/10)
                    },()=>{
                        this.getChildList()
                    })
                }
            }
            componentDidMount() {
                this.props.onRef(this)
                let childInfo = this.props.childInfo
                this.setState({
                    childInfo: childInfo,
                    zzskList: childInfo.data,
                    pageNum: childInfo.pageNum,
                    pageSize: childInfo.pageSize,
                    pagesChild: Math.ceil(childInfo.data.length/10),
                    pageNumChild: Math.ceil(this.props.indexChild/10)
                },()=>{
                    this.getChildList()
                })

            }
            newSwper = () => { // 创建轮播
                let indexChild = this.state.indexChild
                let isindex = ''
                if (indexChild > 9) {
                    isindex = indexChild%10
                } else {
                    isindex = indexChild
                }
                console.log('isindex',isindex)
                this.galleryTop = new Swiper('.gallery-top', {
                    nextButton: '.swiper-button-next',
                    prevButton: '.swiper-button-prev',
                    spaceBetween: 10,
                    initialSlide: isindex
                });
               var galleryThumbs = new Swiper('.gallery-thumbs', {
                    spaceBetween: 10,
                    centeredSlides: true,
                    slidesPerView: 10,
                    touchRatio: 0.2,
                    slideToClickedSlide: true,
                    initialSlide: isindex
                });
                this.galleryTop.params.control = galleryThumbs;
                galleryThumbs.params.control = this.galleryTop;
            }
            changeImgList = pageNum => { // 改变图片列表
                const { zzskList } = this.state
                if (zzskList.length > pageNum*10) {
                    this.setState({
                        childList: zzskList.slice((pageNum-1)*10,(pageNum*10))
                    },()=>{
                        this.newSwper()
                    })
                } else {
                    this.setState({
                        childList: zzskList.slice((pageNum -1 )*10,zzskList.length)
                    },()=>{
                        this.newSwper()
                    })
                }

            }
            getChildList = () => { // 初始化图片列表
                let pageNum = Math.ceil((this.props.indexChild + 1)/10)
                this.changeImgList(pageNum)
            }
            handleImgPage=(type)=>{ // 分页
                const {pageNumChild, pagesChild} = this.state
                if (type === 'next') {
                    if (pageNumChild < pagesChild) {
                        this.setState({
                            pageNumChild: pageNumChild + 1
                        },()=>{
                            this.changeImgList(pageNumChild + 1)
                        })
                    } else {
                        this.setState({
                            pageNumChild: pageNumChild
                        },()=>{
                            this.changeImgList(pageNumChild)
                        })
                    }
                } else {
                    if (pageNumChild > 1) {
                        this.setState({
                            pageNumChild: pageNumChild - 1
                        },()=>{
                            this.changeImgList(pageNumChild-1)
                        })
                    } else {
                        this.setState({
                            pageNumChild: 1
                        },()=>{
                            this.changeImgList(1)
                        })
                    }
                }
            }
            render() {
                const { childList,pageNumChild,pagesChild} =this.state
                return (
                    <div className="lb_main">
                        <div className="swiper-bg">
                            <div className="swiper-container gallery-top">
                                <div className="swiper-wrapper">
                                    {
                                        childList && childList.map((item,index)=>{
                                            return(
                                                <div className="swiper-slide">
                                                    <RenderImg type={item.mediaType} size='m' id={item.imgUrl}/>
                                                    <div className="text">
                                                        {item.textDescription}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="swiper-button-next swiper-button-white"></div>
                            <div className="swiper-button-prev swiper-button-white"></div>
                        </div>
                        <div className="seiper_bottom">
                            <div className="swiper-container gallery-thumbs">
                                <div className="swiper-wrapper swiper-wrappers" style={{transForm: 'translate3d(0,0,0) !important'}}>
                                    {
                                        childList && childList.map((item,index)=> {
                                            return(
                                                <div className="swiper-slide">
                                                    {/*<img src={require('../../assets/img/li_img1.png')}/>*/}
                                                    <RenderImg type={item.mediaType} size='m' id={item.imgUrl}/>
                                                </div>
                                            )

                                        })

                                    }
                                </div>
                            </div>
                            {
                                pageNumChild < pagesChild && <div className="swiper-button-nextas swiper-button-white" onClick={()=>{this.handleImgPage("next")}}></div>
                            }
                            {
                                pageNumChild > 1 && <div className="swiper-button-prevas swiper-button-white" onClick={()=>{this.handleImgPage("pre")}}></div>
                            }
                        </div>
                    </div>
                );
            }
        }
    )
);
