
export default {
    LOGIN_SUBMIT(state, action) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify(Object.assign({}, action.data))
        )
        console.log(action.data.token)
        localStorage.setItem(
          "OBS_token",
          action.data.token
        )
    }
}
