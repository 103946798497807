import React from 'react';
import './index.scss';
import { Row, Col, Carousel, Radio, Input, Pagination, Form, Spin } from 'antd';
import { withRouter, Link } from "react-router-dom";
import '@/reset_style/index.less';
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import { courseList, courseCategoryOneList, courseCategoryTwoList, labelList } from '../../api/course/index'
import NoData from '../../components/noData'
import RenderImg from '../../components/renderImg/index'
const urlPre = "cvec-space-front-peixun/";
const { Search } = Input;

export default Form.create()(
    withRouter(
        class Courselist extends React.Component {
            state = {
                enterpriseId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'',//机构id,,
                CourseList: [],
                pageNum: 1,
                pageSize: 10,
                total: 0,
                courseName: '', // 课程名称
                firstCategory: [],//一级分类
                firstCategoryId: '',//一级分类id
                twoCategory: [], // 二级分类
                twoCategoryId: '', // 二级分类id
                labelLists: [], // 标签
                labelId: '', // 标签id
                tableLoading: true
            };
            componentDidMount() {
                if(localStorage.getItem('enterpriseInfo')){
                    console.log(localStorage.getItem('enterpriseInfo'))
                    this.setState({
                        enterpriseId:JSON.parse(localStorage.getItem('enterpriseInfo'))?.schoolId
                    })
                    // localStorage.getItem('enterpriseInfo') ? JSON.parse(localStorage.getItem('enterpriseInfo'))?.schoolId : '',//机构id,
                }
                this.courseList()
                this.courseCategoryOneList()
                this.courseCategoryTwoList()
                this.labelList()
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

            }
            //获取培训计划列表-分页
            async courseList() {
                const { enterpriseId, pageNum, pageSize, firstCategoryId, twoCategoryId, labelId, courseName } = this.state
                const param = {
                    "categoryOne": firstCategoryId,
                    "categoryTwo": twoCategoryId,
                    "courseName": courseName,
                    "enterpriseId": enterpriseId,
                    "labelId": labelId,
                    "pageNum": pageNum,
                    "pageSize": pageSize
                }
                const CourseList = await courseList(param)
                this.setState({
                    CourseList: CourseList.data,
                    total: CourseList.total,
                    pageNum: CourseList.pageNum,
                    pageSize: CourseList.pageSize,
                    tableLoading: false
                }, () => {
                    console.log("totaltotal", this.state.total)
                    window.elGoTop && window.elGoTop('body')
                })
            }

            //搜索
            onSearch = (value) => {
                console.log(value)
                this.setState({
                    courseName: value,
                    pageNum: 1
                }, () => {
                    this.courseList()
                })
            }
            changePage=(page)=>{  // 分页
                this.setState({
                    pageNum:page
                },()=>{
                    // this.courseList()
                })
            }
            onShowSizeChange = (current, pageSize) => { // 切换默认条数
                this.setState({
                    pageSize: pageSize,
                    pageNum: current
                }, () => {
                    // this.courseList()
                })
            }

            //一级分类切换
            handlFirstCategory = (id) => {
                this.setState({
                    firstCategoryId: id,
                    pageNum: 1
                }, () => {
                    this.courseList()
                })

            }

            // 二级分类切换
            handlTwoCategory = (id) => {
                this.setState({
                    twoCategoryId: id,
                    pageNum: 1
                }, () => {
                    this.courseList()
                })
            }
            // 标签切换
            handlLable = (id) => {
                this.setState({
                    labelId: id,
                    pageNum: 1
                }, () => {
                    this.courseList()
                })
            }

            //获取一级分类
            async courseCategoryOneList() {
                const { enterpriseId } = this.state
                const firstCategory = await courseCategoryOneList({ enterpriseId: enterpriseId })
                this.setState({
                    firstCategory
                })

            }
            // 获取二级分类
            async courseCategoryTwoList() {
                const { enterpriseId } = this.state
                const twoCategory = await courseCategoryTwoList({ enterpriseId: enterpriseId })
                this.setState({
                    twoCategory
                })
            }
            // 获取标签
            async labelList() {
                const { enterpriseId } = this.state
                const labelLists = await labelList({ enterpriseId: enterpriseId })
                this.setState({
                    labelLists
                })
            }

            //跳转详情
            toDetail = (item) => {
                console.log("item", item)
                this.props.history.push(`/courseintr/?${encodeURI(JSON.stringify({ id: item.id }))}`)
            }

            onClititle = () =>{
                this.mesWarning("课程已结束");
            }
            render() {
                const { CourseList, total, pageNum, pageSize, firstCategory, twoCategory, labelLists, tableLoading } = this.state
                const { $$img, apiPrefix } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'courselist'} />
                        <div className="resource_container">
                            <div className='CamelCaseCourselist'>
                                <div className="classification">
                                    <div className="Camel-content">
                                        <Row>
                                            <Col span={24} className="col-ul">
                                                <span className="span1">一级分类</span>
                                                <Radio.Group className="nav-tab" defaultValue={''}>
                                                    <Radio.Button key={-1} value={''} onClick={() => this.handlFirstCategory()}>全部</Radio.Button>
                                                    {firstCategory.map((item, index) => {
                                                        return (
                                                            <Radio.Button key={item.id} value={item.id} onClick={() => this.handlFirstCategory(item.id)}>
                                                                {item.categoryName}
                                                            </Radio.Button>
                                                        )
                                                    })}
                                                </Radio.Group>
                                            </Col>
                                            <Col span={24} className="col-ul">
                                                <span className="span1">二级分类</span>
                                                <Radio.Group className="nav-tab" defaultValue={''}>
                                                    <Radio.Button key={-1} value={''} onClick={() => this.handlTwoCategory()}>全部</Radio.Button>
                                                    {
                                                        twoCategory.map((item, index) => {
                                                            return (
                                                                <Radio.Button key={item.id} value={item.id} onClick={() => this.handlTwoCategory(item.id)}>
                                                                    {item.categoryName}
                                                                </Radio.Button>
                                                            )
                                                        })
                                                    }
                                                </Radio.Group>
                                            </Col>
                                            <Col span={24} className="col-ul">
                                                <span className="span1">标签</span>
                                                <Radio.Group className="nav-tab" defaultValue={''}>
                                                    <Radio.Button key={-1} value={''} onClick={() => this.handlLable()}>全部</Radio.Button>
                                                    {
                                                        labelLists.map((item, index) => {
                                                            return (
                                                                <Radio.Button key={item.labelId} value={item.labelId} onClick={() => this.handlLable(item.labelId)}>
                                                                    {item.labelName}
                                                                </Radio.Button>
                                                            )
                                                        })
                                                    }
                                                </Radio.Group>
                                            </Col>
                                            <Col span={24} className="col-bottom">
                                                <div className="left">
                                                    <span>共查到{total}个课程</span>
                                                </div>
                                                <div className="right">
                                                    <Search onSearch={this.onSearch} placeholder="请输入你感兴趣的课程" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                {/*培训计划*/}
                                <div className='CamelCaseplan'>
                                    {
                                        tableLoading? <section style={{ textAlign: "center" }}>
                                            <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                            <p>数据正在加载中...</p>
                                        </section>:(
                                            <div className="Camel-content">
                                                {
                                                    CourseList.length > 0 ? (
                                                        <Row>
                                                            <ul className="ul">
                                                                {
                                                                    CourseList && CourseList.map((item, index) => {
                                                                        return (
                                                                            // <li onClick={()=>this.onClititle(item)}>
                                                                            <li onClick={()=>this.toDetail(item)}>
                                                                                <div className="pic" >
                                                                                    {/*{item.pro==='进行中'?<span className="on">{item.pro}</span>:item.pro==='已结束'?<span className="active">{item.pro}</span>:<span className="em">{item.pro}</span>}*/}
                                                                                    {/*<img src={$$img(item.courseImg)} alt=""/>*/}
                                                                                    <RenderImg type={item.mediaType} size='m' id={item.courseImg} />
                                                                                </div>
                                                                                <div className="text">
                                                                                    <h2>{item.courseName}</h2>
                                                                                    <div className="ul">
                                                                                        {
                                                                                            item.labelList.map((title) => {
                                                                                                return (
                                                                                                    <span>{title}</span>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    <p>学习课时：{item.courseNum}课时</p>
                                                                                    <div className="more">
                                                                                        <i><img src={window.$$img('cvec-space-front-peixun/title_icon48.png')} />{item.studentNum}人</i>
                                                                                        <a href="javascript:;">进入课程&nbsp;&nbsp;<img src={window.$$img('cvec-space-front-peixun/title_icon9.png')} /></a>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </Row>
                                                    ) : <NoData />
                                                }
                                                <Row>
                                                    {
                                                        total ? (
                                                            <Pagination  style={{float:"right"}}
                                                                defaultCurrent={1}
                                                                pageSizeOptions={["20","40","60","80"]}
                                                                showSizeChanger
                                                                defaultPageSize={20}
                                                                total={20}
                                                            />
                                                        ) : ""
                                                    }
                                                </Row>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
