import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Row, Col, Input, Button, Pagination,Form, } from 'antd';
import { withRouter,Link } from "react-router-dom";
import {storyTopList,storyList} from '../../api/story/index'
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import {gotoAddStoryUrl} from "../../config/secret";
import RenderImg from '../../components/renderImg/index'
import Cookies from 'js-cookie';
import NoData from "../../components/noData";
const urlPre = "cvec-space-front-peixun/";
const { Search } = Input;

export default Form.create()(
    withRouter(
        class Learnstory extends React.Component {
            state = {
                enterpriseId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'',//机构id,
                // enterpriseId:'90c4a953555344cd9473dd29cfce96e6',
                learnDatalist:[],
                storyDataList:[],
                topStorypage:1,//学习故事top页码
                totalTop:0,
                pageNum:1,
                pageSize:10,
                total:0,
                storyTitle:'',//故事名称
                userId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).userId:'', // 用户id
                tableLoading:true

            };
            componentDidMount() {
                this.storyTopList()
                this.storyList()
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

            }
            //顶部故事
            async storyTopList(){
                const {enterpriseId,topStorypage}=this.state
                const params={
                    "enterpriseId": enterpriseId,
                    "pageNum": topStorypage,
                    "pageSize": 4
                }
                const learnDatalist= await storyTopList(params)
                this.setState({
                    learnDatalist:learnDatalist.data,
                    totalTop:learnDatalist.total,
                    tableLoading:false
                })
            }
            //分页故事
            async storyList(){
                const {enterpriseId,pageNum,pageSize,storyTitle}=this.state
                const params={
                    "enterpriseId": enterpriseId,
                    "pageNum": pageNum,
                    "pageSize": pageSize,
                    "storyTitle":storyTitle
                }
                const storyDataList= await storyList(params)
                this.setState({
                    storyDataList:storyDataList.data,
                    total:storyDataList.total,
                    tableLoading:false
                },()=>{
                    window.elGoTop && window.elGoTop('body')
                })
            }
            onSearch = (value) => {
                this.setState({
                    storyTitle:value,
                    pageNum: 1
                },()=>{
                    this.onChange()
                })
            }
            //学习故事换一换
            turnStory=()=>{
                const {topStorypage}=this.state
                this.setState({
                    topStorypage:topStorypage===1?2:1
                },()=>{
                    this.storyTopList()
                })
            }
            onChange=(page)=>{
                this.setState({
                    pageNum:page
                },()=>{
                    this.storyList()
                })
            }
            onShowSizeChange=(current, pageSize)=> {
                this.setState({
                    pageSize:pageSize,
                    pageNum:current
                },()=>{
                    this.storyList()
                })
            }
            goAddStory = () => { // 进入个人项目学生端添加故事页面
                Cookies.set('pathName', `/training/mytraining/learnshar`); // 存菜单地址
                window.open(gotoAddStoryUrl).location.reload()
            }

            // var str = '<p><code>uni-app</code> 这是要输出的 <code>Vue</code> 一些废话</a>。</p> <img src="https://p.yes.com/file/2019/11/06/1573023652712246.png" title="file/2019/11/06/1573023652712246.png" alt="file/2019/11/06/1573023652712246.png"/>';
            //
            // console.log(str.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,''));


            render() {
                const {learnDatalist,storyDataList,total,pageSize,userId, pageNum,tableLoading} =this.state
                const { $$img } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'learnstory'}/>
                        <div className="resource_container">
                            <div className='CamelCaseLearnstory'>
                                <div className="Learnbanner" style={{background:`url('${$$img("cvec-space-front-peixun/pic28.png")}') no-repeat center`}}>
                                    {
                                        tableLoading?<section style={{ textAlign: "center" }}>
                                            <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                            <p>数据正在加载中...</p>
                                        </section>:(
                                            <div className="Camel-content">
                                                <div className="top">
                                                    <h2>今日推荐</h2>
                                                    {
                                                        learnDatalist&&(
                                                            <span onClick={this.turnStory}><img src={`${$$img(urlPre + "title_icon40.svg")}`}/>换一换</span>
                                                        )
                                                    }
                                                </div>
                                                {
                                                    learnDatalist.length>0?(
                                                        <ul>
                                                            {
                                                                learnDatalist&&learnDatalist.map((item,index)=>{
                                                                    return(
                                                                        <li>
                                                                            <Link to={`/learnspage/?id=${item.storyId}`}>
                                                                                {item.storyImg?
                                                                                    <div className="pic">
                                                                                        {/*<img src={$$img(item.storyImg)}/>*/}
                                                                                        <RenderImg type={item.mediaType} size='m' id={item.storyImg}/>
                                                                                    </div>:""
                                                                                }
                                                                                <div className="text">
                                                                                    <h3>{item.storyTitle}</h3>
                                                                                    {item.storyImg?
                                                                                        <div className='p' dangerouslySetInnerHTML={{__html: item.storyContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'')}}></div>
                                                                                        : <div className="p-on" dangerouslySetInnerHTML={{__html: item.storyContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'')}}></div>
                                                                                    }

                                                                                    <div className="time">
                                                                                        <span>{/*<img src={$$img(item.portraitId)}/>*/}<RenderImg type={item.mediaType} size='s' id={item.portraitId}/>{item.createUserName}</span>
                                                                                        <i><img src={`${$$img(urlPre + "title_icon32.png")}`}/>浏览&nbsp;&nbsp;{item.lookNum}</i>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    ):(
                                                        <NoData></NoData>
                                                    )
                                                }

                                            </div>
                                        )
                                    }
                                </div>
                                <div className="Camel-content">
                                    {
                                        tableLoading?<section style={{ textAlign: "center" }}>
                                            <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                            <p>数据正在加载中...</p>
                                        </section>:(
                                            <div className="bottomLear">
                                                <h3><img src={`${$$img(urlPre + "title_icon3.png")}`}/>全部故事</h3>
                                                <div className="sech-right">
                                                    <div className="left">
                                                        <Search onSearch={this.onSearch} placeholder="请输入您想查询的学习故事"/>

                                                    </div>
                                                    {/*未登录没有该按钮，登录跳转到学生端个人项目添加故事界面*/}
                                                    {
                                                        userId?<Button type="primary" onClick={this.goAddStory}>分享我的学习故事</Button>: null
                                                    }
                                                </div>
                                                {
                                                    storyDataList.length>0?(
                                                        <Row>
                                                            {
                                                                storyDataList&&storyDataList.map((item,index)=>{
                                                                    return(
                                                                        <Col span={24} key={index}>
                                                                            <Link className="a-link" to={`/learnspage/?id=${item.id}`}>
                                                                                <h4>{item.storyTitle}<span>{item.createTime}</span></h4>
                                                                                <p dangerouslySetInnerHTML={{__html: item.storyContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'')}}></p>
                                                                                <div className="time">
                                                                                    <span>{/*<img src={$$img(item.portraitId)}/>*/}<RenderImg type={item.mediaType} size='m' id={item.portraitId}/>{item.createUserName}</span>
                                                                                    <em>
                                                                                        <b className="b1" style={{background:`url('${$$img("cvec-space-front-peixun/title_icon32.png")}') no-repeat center left`,backgroundSize: '19px 19px'}}>浏览 {item.lookNum}</b>
                                                                                        <b className="b2" style={{background:`url('${$$img("cvec-space-front-peixun/title_icon36.png")}') no-repeat center left`,backgroundSize: '16px 16px'}}>点赞  {item.thumbsUpNum}</b>
                                                                                        <b className="b3" style={{background:`url('${$$img("cvec-space-front-peixun/title_icon38.png")}') no-repeat center left`,backgroundSize: '18px 18px'}}>收藏  {item.collectionNum}</b>
                                                                                    </em>
                                                                                </div>
                                                                            </Link>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    ):(
                                                        <NoData></NoData>
                                                    )
                                                }
                                                <Row>
                                                    {
                                                        total?(
                                                            <Pagination style={{float:"right"}}
                                                                        showSizeChanger
                                                                        onShowSizeChange={this.onShowSizeChange}
                                                                        onChange={this.onChange}
                                                                        defaultCurrent={1}
                                                                        total={total}
                                                                        pageSize={pageSize}
                                                                        current={pageNum}
                                                            />
                                                        ):""
                                                    }

                                                </Row>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
