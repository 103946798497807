// 专题列表
import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Input, Tabs, Form, } from 'antd';
import { withRouter, } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import RenderImg from '@/components/renderImg/index'
import {peixunSpecialDetail} from "@/api/hottopic/index";
import NoData from "../../components/noData";
const { Search } = Input;
const { TabPane } = Tabs;
const urlPre = "cvec-space-front-peixun/"
function onShowSizeChange(current, pageSize) {
    console.log(current, pageSize);
}

export default Form.create()(
    withRouter(
        class Projectlist extends React.Component {
            state = {
                activeKey:'1',
                pageNum: 1,
                pageSize: 10,
                total:0,
                id:"",
                allContent:{},
                specialId:""
            };
            onSearch = (value) => {
                console.log(value)
            }
            callback = async (key) => {
                await this.setState({activeKey: key})
            }
            onShowSizeChange=(current, pageSize)=>{ // 分页
                this.setState({
                    pageSize:pageSize,
                    pageNum:current
                },()=>{
                    // this.planDetailClassListPage()
                })
            }
            onChange=(page)=>{
                this.setState({
                    pageNum:page
                },()=>{
                    // this.planDetailClassListPage()
                })
            }
            componentDidMount () {
                const data = this.props.location.search; //地址栏截取
                const param = data.split("?")[1];
                console.log("param",param)
                if (param) {
                    const codeParam = param.includes("%") ? decodeURI(param) : param;
                    console.log(codeParam)
                    const jsonparam = JSON.parse(codeParam);
                    console.log(jsonparam)
                    this.setState({
                        id: jsonparam.id,
                    },()=>{
                        this.getList({id:this.state.id})
                    });
                }
            }
            //获取初始数据
            async getList(data){
                const allContent=await peixunSpecialDetail(data)
                // console.log(res)
                this.setState({
                    allContent,
                    specialId:allContent.specialId
                })
                console.log("子页面allContent",this.state.allContent)
            }
            //进入新闻详情
            newsDetail(value,index){
                console.log("详情",value,index,this.state.specialId)
                let specialId=this.state.specialId
                let oneCategoryId=value.columnId
                let twoCategoryId=value.columnNewsList[index].columnNewsId
                let newsId=value.columnNewsList[index].columnNewsId.newsId
                console.log(value.columnId,value.columnNewsList[index].newsId)
                let data={
                    specialId:this.state.specialId,
                    oneCategoryId:value.columnId,
                    twoCategoryId:value.columnNewsList[index].columnNewsId,
                    newsId:value.columnNewsList[index].newsId
                }
                this.props.history.push(`/newscont/?${encodeURI(JSON.stringify(data))}`)
                console.log(value.columnNewsList[index])
            }

            render() {
                const {allContent} =this.state
                const specialColumnList=allContent.specialColumnList
                console.log("specialColumnList",allContent.specialColumnList)
                const { $$img } =window
                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='CamelHottopic'>
                                <div className="projectpage_cont">
                                    <div className="projectpage_listtop">
                                        <div className="Camel-content">
                                            <div className="pic_main">
                                                <h3>{allContent.specialName}</h3>
                                                <div className="main_page">
                                                    <div className="pic_img">
                                                        <RenderImg type={allContent.mediaType} size='m' id={allContent.specialImg}/>
                                                    </div>
                                                    <div className="pic_right">
                                                        <h4>摘 要</h4>
                                                        <div className="text">
                                                            <div>
                                                                {allContent.specialDesc}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="projectpage_listbottom">
                                        <div className="Camel-content">
                                            {specialColumnList && specialColumnList.map(item=>
                                                <div className="list_ul">
                                                    <h5>{item.columnName}</h5>
                                                    <ul>
                                                        {
                                                            item.columnNewsList && item.columnNewsList.length > 0 ? item.columnNewsList.map((itemChild,index)=>
                                                            <li onClick={()=>this.newsDetail(item,index)} className="newsTitle">
                                                                <h6>{itemChild.newsTitle}</h6>
                                                                <span className="time">{itemChild.createTime.split(" ",1)}</span>
                                                            </li>) :  <NoData></NoData>
                                                        }
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
