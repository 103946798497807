import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Checkbox, } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { LOGIN_SUBMITFN } from '../../store/OBS/login/action'
import { userLogin,} from '../../api/login'
import {setCookieToken} from '../../until/cookie'
import md5 from "js-md5"
import './index.scss'
const FormItem = Form.Item
class Login extends Component {
    componentDidMount() {
        let pathName= window.location.href
        var regExp=/#(\S*)/;
        pathName= pathName.replace(regExp,"")
        console.log("pathName",pathName)
        localStorage.setItem("innerWebsite",pathName)
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields(async (err, values) => {
            console.log(values)
            const userName = values.userName
            const password = md5(values.password)
            if (!err) {
                userLogin({
                    username: userName,
                    password: password,
                    loginType:100003,
                    // platform:'obs'
                }).then(res => {
                    if (res){
                        let cookieData = {
                            id: res.id ? res.id : '', // 用户Id
                            humanName: res.fullName ? res.fullName : '', // 姓名
                            portraitId: res.portraitId ? res.portraitId : '', // 头像Id
                            userName: res.userName ? res.userName : '', // 用户名，登录名
                            telNum: res.telNum ? res.telNum : '', // 电话
                            sex: res.sex ? res.sex : '', // 性别（0女，1男）
                            introduction: res.introduction ? res.introduction : '', // 个人描述
                            epsId: res.organization ? res.organization : '', // 企业id
                            organization: res.organization ? res.organization : '', // 组织Id
                            innerWebsite: localStorage.getItem("innerWebsite"), // 当前域名地址
                            token: res.token, // 登录token
                        }
                        setCookieToken(cookieData, 2)
                    }
                    this.resetUserInfo(res, values)
                })
            }
        })
    }
    resetUserInfo(res, values) {
        const userInfo = res;
        if (userInfo) {
            this.props.setUserInfo(Object.assign({}, userInfo))
            this.props.history.replace('/')
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form
        return (

            <div className="Portallogin-wrap-main">
                <div className="login-top">
                    <div className="width-main">
              <span className="logo"><Link to="/">
              </Link></span>
                    </div>
                </div>
                <div className="main-warptext">
                    <div className='content-wrap'>
                        <div className="width-main">
                            <div className="img-left">
                                {/*<video src={require('../../assets/img/login.mp4')} loop="loop" autoPlay="autoplay">*/}
                                {/*</video>*/}
                            </div>
                            <div className="main-login-wrap">
                                <div className="login-box">

                                    <div className="login-title">
                                        <div>账号登录</div>
                                    </div>
                                    <Form className="login_form" onSubmit={this.handleSubmit}>
                                        <FormItem className="form_it">
                                            <p>用户名</p>
                                            {getFieldDecorator("userName", {
                                                rules: [
                                                    { required: true, message: "请输入账号!" },
                                                    {
                                                        max: 20,
                                                        message: "用户名不能超过20位",
                                                    },
                                                    {
                                                        pattern: /^(?=.*[A-Za-z])[A-Za-z\d]{0,20}$|^1[3456789]\d{9}/,
                                                        message: "用户名不符",
                                                    },
                                                ],
                                                initialValue: localStorage.userName || "",
                                            })(
                                                <Input
                                                    allowClear
                                                    maxLength={20}
                                                    placeholder={this.props.userFlag ? "用户名" : "请输入手机号"}
                                                />
                                            )}
                                        </FormItem>
                                        <FormItem className="form_it form-it-last">
                                            <p>密码</p>
                                            {getFieldDecorator("password", {
                                                rules: [{ required: true, message: "请输入密码!" }],
                                                initialValue: localStorage.password || "",
                                            })(
                                                <Input
                                                    allowClear
                                                    maxLength={20}
                                                    style={{ width: !this.props.userFlag ? 150 : "100%" }}
                                                    type={this.props.userFlag ? "password" : "text"}
                                                    placeholder={this.props.userFlag ? "密码" : "请输入验证码"}
                                                />
                                            )}
                                        </FormItem>
                                        <FormItem>
                                            {getFieldDecorator("remember", {
                                                valuePropName: "checked",
                                                initialValue: localStorage.getItem("ischecked") == 1 ? true : false,
                                            })(<Checkbox onChange={this.onChangeRemember}>记住密码</Checkbox>)}
                                        </FormItem>
                                        <FormItem>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="login_form_button"
                                                onSubmit={this.handleSubmit}
                                            >
                                                登录
                                            </Button>
                                        </FormItem>
                                        <div className="login_mainwarp">
                                            <div className="text_span">
                                                <Link to="/registered">申请注册</Link>
                                            </div>
                                            <p onClick={() => this.props.history.push('/forget')} className='forget-password'>忘记密码</p>
                                        </div>
                                    </Form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="footer-p">CopyRight©2020cveducloud.com.All rights reserved <a target="_blank" href="http://www.beian.gov.cn/portal/index?token=320d0fad-67f3-4691-a1a4-60346951292e">*/}
                {/*    京IC备案20020432号-3  </a> </div>*/}
            </div>
        )
    }
}

const mapStateToProps = ({ LOGIN }) => {
    return {
        userFlag: LOGIN.userFlag,
        uname: LOGIN.uname,
        pwd: LOGIN.pwd
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo(data) {
            dispatch(LOGIN_SUBMITFN(data))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(Login)))
