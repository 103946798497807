import React from 'react';
import './index.scss';
import {Row, Col, Radio,Form ,Select, Input} from 'antd';
import {getProvinceSel, getCitySel, getDistrictSel} from "@/api/ucenter/index"
import { getPlanClassFormData,getUserInfoDetailsVo } from "@/api/plan/index"
import Cookies from "js-cookie";
const {Option} = Select;
export default Form.create()(
    class AddStuInfo extends React.Component {
        state = {
            provinceCode: '',
            cityCode: '',
            areaCode: '',
            provinceName: null,
            cityName: null,
            companyList:[{
                companyId: 1,
                companyName: '单位1'
            }], // 公司下拉列表
            schoolList: [{
                schoolId: 1,
                schoolName: '学院1'
            }], // 院校下拉框
            classId: '', // 班级id
            userId:'',//用户id
            formList: [], // 获取到的表单信息 (1, "姓名",2 "用户名", 3 "联系方式",4性别,5学号,6工号,7省,8市,9区/县,10住址,11单位/院校,12部门/院系,13院校,14院系,15职务,16是否住宿,17是否用餐)
            classFormQo:{},//用户信息
            mainOrgId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).mainOrgId:'', // 单位/院校id
            mainOrgName: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).mainOrgName:'', // 单位/院校名
        }
        UNSAFE_componentWillReceiveProps(nextProps) {
            const { classId,userId,...options  } = nextProps;
            if (classId && classId != this.state.classId) {
                this.setState({
                    classId: this.props.classId,
                    userId:this.props.userId,
                },()=>{
                    this.getUserInfoDetailsVo(); //获取用户信息
                });
            }
        }
        componentDidMount () {
            this.setState({
                classId: this.props.classId,
                userId:this.props.userId
            },()=>{
                this.getUserInfoDetailsVo(); //获取用户信息
            });
        }
        getPlanClassFormData = async () =>{ // 获取表单信息
            await getPlanClassFormData ({id: this.props.classId}).then(res => {
                this.setState({
                    formList: res
                },()=>{
                    if (res.find((v,index) => v === 7)) {
                        this.getProvinceSel();//获取省
                    }
                })
            })
        }
        //获取用户信息
        getUserInfoDetailsVo = async()=>{
            let detailInfo = await getUserInfoDetailsVo({id:this.props.userId});
            this.setState({
                classFormQo:JSON.parse(JSON.stringify(detailInfo))
            },()=>{
                this.getPlanClassFormData();
            })
        }
        // 校验手机号格式
        validatePhone = (rule, value, callback) => {
            let pattern = /^1[3|4|5|7|8][0-9]\d{8}$/;
            if (value && !pattern.test(value)) {
                callback('请输入正确的号，如：13988886666')
            } else {
                callback();
            }
        }
        //省下拉
        getProvinceSel = async () => {
            let res = await getProvinceSel();
            res && this.setState({provinceSel: res});
        }
        //市下拉
        getCitySel = async () => {
            if (this.state.provinceCode) {
                let res = await getCitySel({provinceCode: this.state.provinceCode});
                res && this.setState({citySel: res});
            }
        }
        //区县下拉
        getDistrictSel = async () => {
            if (this.state.cityCode) {
                let res = await getDistrictSel({cityCode: this.state.cityCode});
                res && this.setState({districtSel: res});
            }
        }

        //省下拉框change
        changeProvince = (e, item) => {
            let {props: {children}} = item;
            this.props.getProvinceName(children);//获取省名，传回父级
            this.props.form.setFieldsValue({
                cityId: undefined,
                areaId: undefined,
                areaRange: '',
                areaName: '',
                cityName: ''
            });
            this.setState({
                provinceCode: e,
                cityName: '',
                areaName: '',
                provinceName: children,
                citySel: [],
                districtSel: []
            }, () => this.getCitySel());
        }
        //市下拉框change
        changeCity = (e, item) => {
            let {props: {children}} = item;
            this.props.getCityName(children);//获取市名，传回父级
            this.props.form.setFieldsValue({areaId: undefined, areaRange: '', areaName: ''});
            this.setState({
                cityCode: e,
                cityName: children,
                areaName: '',
                areaCode: undefined,
                districtSel: []
            }, () => this.getDistrictSel());
        }
        //区县下拉框change
        changeDistrict = (e, item) => {
            this.props.getAreaName(item.props.children);//获取市名，传回父级
            this.props.form.setFieldsValue({areaRange: ''});
            this.setState({areaCode: e, areaName: item.props.children});
        }
        render() {
            const {getFieldDecorator} = this.props.form;
            const {provinceSel, citySel, districtSel,companyList,schoolList,formList,classFormQo,mainOrgName} = this.state
            return (
                <Form className='add_stu_info'>
                    {
                        formList.find((v,index) => v === 1) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>姓名</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formFullName', {
                                                initialValue: classFormQo.fullName,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '姓名不能为空'
                                                    },
                                                    {
                                                        max: 10,
                                                        message: "文本过长，最多10字"
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '').replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
                                            })(
                                                <Input placeholder='请输入名称'  maxLength={10} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`formFullName`) ? this.props.form.getFieldValue(`formFullName`).length : 0}/10</span>}/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }

                    {
                        formList.find((v) => v === 2) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>用户名</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formUserName', {
                                                initialValue: classFormQo.fullName,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '用户名不能为空'
                                                    },
                                                    {
                                                        max: 10,
                                                        message: "文本过长，最多10字"
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '').replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
                                            })(
                                                <Input placeholder='请输入用户名'  maxLength={10} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`formUserName`) ? this.props.form.getFieldValue(`formUserName`).length : 0}/10</span>}/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 3) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>联系方式</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formPhone', {
                                                initialValue: classFormQo.telNum,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '联系方式不能为空'
                                                    }, {
                                                        validator: this.validatePhone
                                                    }
                                                ]
                                            })(
                                                <Input placeholder='请输入联系方式'/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 4) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>性别</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formSex', {
                                                initialValue: classFormQo.sex
                                            })(
                                                <Radio.Group>
                                                    <Radio value={1}>男</Radio>
                                                    <Radio value={2}>女</Radio>
                                                </Radio.Group>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 5) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>学号/工号</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formStudentNum', {
                                                initialValue: classFormQo.jobNum
                                            })(
                                                <Input disabled/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 7) && formList.find((v) => v === 8)&&formList.find((v) => v === 9)||formList.find((v) => v === 10) ?
                            <>
                                <Row>
                                    <Col span={3}><span className='addRess'><span className='red'>*</span>地址</span></Col>
                                    <Col className='location_box' span={6}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator('formProvince', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择省'
                                                        }
                                                    ],
                                                })(
                                                    <Select
                                                        onChange={this.changeProvince}
                                                        getPopupContainer={triggerNode => triggerNode.parentNode}
                                                        placeholder='请选择省'>
                                                        {
                                                            provinceSel?.map(v => <Option key={v.provinceCode}
                                                                                          value={v.provinceCode}>{v.name}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col className='location_box' span={6}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator('formCity', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择市'
                                                        }
                                                    ],
                                                })(
                                                    <Select
                                                        getPopupContainer={triggerNode => triggerNode.parentNode}
                                                        onChange={this.changeCity}
                                                        placeholder='请选择市'
                                                    >
                                                        {
                                                            citySel?.map(v => <Option key={v.cityCode}
                                                                                      value={v.cityCode}>{v.name}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col className='location_box' span={6}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator('formArea', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '请选择区'
                                                        }
                                                    ],
                                                })(
                                                    <Select
                                                        getPopupContainer={triggerNode => triggerNode.parentNode}
                                                        onChange={this.changeDistrict}
                                                        placeholder='请选择区'
                                                    >
                                                        {
                                                            districtSel?.map(v => <Option key={v.areaCode}
                                                                                          value={v.areaCode}>{v.name}</Option>)
                                                        }
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Row>
                                        <Col span={3}></Col>
                                        <Col span={15}>
                                            {
                                                getFieldDecorator('formAddress', {
                                                    initialValue: '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '详细地址不能为空'
                                                        },
                                                        {
                                                            max: 20,
                                                            message: "文本过长，最多20字"
                                                        },
                                                        {
                                                            pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
                                                            message: '禁止输入特殊字符',
                                                        }
                                                    ],
                                                    getValueFromEvent: event => {
                                                        return event.target.value.replace(/\s+/g, '')
                                                    }
                                                })(
                                                    <Input placeholder='请输详细地址'  maxLength={20} suffix={<span className='len'>
                                        {this.props.form.getFieldValue(`formAddress`) ? this.props.form.getFieldValue(`formAddress`).length : 0}/20</span>}/>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </> : null
                    }
                    {
                        formList.find((v) => v === 11) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>单位/院校</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formCompany', {
                                                initialValue: mainOrgName,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '单位/院校不能为空'
                                                    }
                                                ]
                                            })(
                                                <Input placeholder='请输入单位/院校' disabled/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 13) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>部门/院系</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formDepartment', {
                                                initialValue: classFormQo.facultyName,
                                                rules: [
                                                ]
                                            })(
                                                <Input disabled/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 15) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'>*</span>职务</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formDuties', {
                                                initialValue: classFormQo.formDuties,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '职务不能为空'
                                                    },
                                                    {
                                                        max: 10,
                                                        message: "文本过长，最多10字"
                                                    }
                                                ],
                                                getValueFromEvent: event => event.target.value.replace(/\s+/g, '').replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
                                            })(
                                                <Input placeholder='请输入职务'  maxLength={10} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`formDuties`) ? this.props.form.getFieldValue(`formDuties`).length : 0}/10</span>}/>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 16) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'></span>住宿</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formIsLodgings', {
                                                initialValue: ''
                                            })(
                                                <Radio.Group>
                                                    <Radio value={1}>住宿</Radio>
                                                    <Radio value={0}>不住宿</Radio>
                                                </Radio.Group>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }
                    {
                        formList.find((v) => v === 17) ?
                            <Form.Item>
                                <Row>
                                    <Col span={3}><span><span className='red'></span>用餐</span></Col>
                                    <Col span={15}>
                                        {
                                            getFieldDecorator('formIsEat', {
                                                initialValue: ''
                                            })(
                                                <Radio.Group>
                                                    <Radio value={1}>用餐</Radio>
                                                    <Radio value={0}>不用餐</Radio>
                                                </Radio.Group>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Form.Item> : null
                    }

                </Form>
            )
        }

    }
)
