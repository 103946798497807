import { post } from '../../until/axios_instans';
// 获取培训计划列表-分页
export function planList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planList', data);
}
// 获取培训计划详情
export function planDetailTop(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planDetailTop', data);
}
// 获取培训计划详情大纲数据
export function planDetailOutline(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planDetailOutline', data);
}
// 获取培训计划详情师资展示数据
export function planDetailTeacherList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planDetailTeacherList', data);
}
// 获取培训计划详情-查询班级报名状态
export function planDetailClassStatus(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planDetailClassStatus', data);
}

// 获取培训计划-标签数据
export function planLabelList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planLabelList', data);
}
// 获取培训计划详情-查看更多可选班级数据-分页
export function planDetailClassListPage(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planDetailClassListPage', data);
}

// 获取培训计划详情推荐课程
export function planDetailCourseList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planDetailCourseList', data);
}

// 用户报名计划
export function studentEnrollPlan(data) {
    return post(window.apiPrefix+'/api/v1/student/plan/studentEnrollPlan', data);
}

// 查询报名人信息
export function planDetailSignStudentData(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/plan/planDetailSignStudentData', data);
}

// 计划收藏接口
export function studentCollectionPlan(data) {
    return post(window.apiPrefix+'/api/v1/student/plan/studentCollectionPlan', data);
}

// 计划收藏状态查询
export function studentCollectionStatus(data) {
    return post(window.apiPrefix+'/api/v1/student/plan/studentCollectionStatus', data);
}

// 计划取消收藏
export function studentCancelCollectionPlan(data) {
    return post(window.apiPrefix+'/api/v1/student/plan/studentCancelCollectionPlan', data);
}

// 获取动态表单
export function getPlanClassFormData(data) {
    return post(window.apiPrefix+'/api/v1/student/plan/getPlanClassFormData', data);
}
//获取用户信息
export function getUserInfoDetailsVo(data) {
    return post(window.apiUcenter+'/api/v1/adminUser/getUserInfoDetailsVo', data);
}
// 社会学生注册
export function insertSocietyStudent(data) {
    return post(window.apiUcenter+'/api/v1/adminUser/insertSocietyStudent', data);
}