import { post } from '../../until/axios_instans';
// 获取培训计划列表-分页
export function courseList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseList', data);
}
// 获取学习故事-顶部数据
export function storyTopList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/story/storyTopList', data);
}
//获取学习故事-详情
export function storyDetail(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/story/storyDetail', data);
}
// 获取课程一级分类
export function courseCategoryOneList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseCategoryOneList', data);
}
// 获取课程二级分类
export function courseCategoryTwoList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseCategoryTwoList', data);
}
// 获取标签数据
export function labelList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/labelList', data);
}

// 获取课程详情信息
export function courseDetail(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetail', data);
}
// 获取课程详情信息-主讲老师
export function courseDetailTeacher(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetailTeacher', data);
}
// 获取课程详情信息-教师团队
export function courseDetailTeacherList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetailTeacherList', data);
}
// 获取课程详情信息-关联计划数据
export function courseDetailRelationPlan(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetailRelationPlan', data);
}
// 获取课程详情信息-课程大纲
export function courseDetailOutline(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetailOutline', data);
}
// 获取课程详情信息-查询课程资料数据
export function courseDetailResource(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetailResource', data);
}

// 获取课程详情信息-查看更多班级
export function courseDetailAllClass(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetailAllClass', data);
}

// 获取课程详情信息-查询班级状态
export function courseDetailClass(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/course/courseDetailClass', data);
}

// 获取课程详情信息-根据课程id查询计划数据
export function courseDetailGetPlan(data) {
    return post(window.apiPrefix + '/api/v1/ts/portal/course/courseDetailGetPlan', data);
}

// 学生加入课程
export function studentJoinCourse(data) {
    return post(window.apiPrefix + '/api/v1/student/course/studentJoinCourse', data);
}
// 课程资源下载
export function courseResourceDownload(data) {
    return post(window.apiPrefix + "/api/v1/ts/course/resource/courseResourceDownload", data);
}
//根据资源id查询资源信息
export function getByPlanTransId(data) {
    return post(window.apiPrefix + "/api/v1/ts/portal/resource/getByPlanTransId", data);
}
