import { post } from '../../until/axios_instans';
// 获取学习故事-分页
export function storyList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/story/storyList', data);
}
// 获取学习故事-顶部数据
export function storyTopList(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/story/storyTopList', data);
}
//获取学习故事-详情
export function storyDetail(data) {
    return post(window.apiPrefix+'/api/v1/ts/portal/story/storyDetail', data);
}

// 点赞学习故事
export function thumbsUpStudyStory(data) {
    return post(window.apiPrefix+'/api/v1/student/story/thumbsUpStudyStory', data);
}

// 取消点赞学习故事
export function cancelThumbsUpStudyStory(data) {
    return post(window.apiPrefix+'/api/v1/student/story/cancelThumbsUpStudyStory', data);
}

// 收藏学习故事
export function collectStudyStory(data) {
    return post(window.apiPrefix+'/api/v1/student/story/collectStudyStory', data);
}

// 取消收藏学习故事
export function cancelCollectStudyStory(data) {
    return post(window.apiPrefix+'/api/v1/student/story/cancelCollectStudyStory', data);
}

// 查询学习故事收藏点赞状态
export function studyStoryCollectThumbsUpStatus(data) {
    return post(window.apiPrefix+'/api/v1/student/story/studyStoryCollectThumbsUpStatus', data);
}