import React, { Component } from "react";
// import { getPageView } from "@/api/university_space";
import S from "gongyongicon";
import { foot } from "./getfoot";
import "./index.scss";
import {LocationH,PostOfficeH,E_mailH,TelH} from '../iconSvg'

export default class School_footer extends Component {
  render() {
    const { schoolData,footStyleCode, visitCount } = this.props;
    return (
        <div className={footStyleCode === "0"?"school_footer_wrap":"school_footer_wrap school_footer_wrap_color"}  style={{
          backgroundImage:
              footStyleCode === "0" ?
                  `url(${window.$$setImgSrc("yxkj-footer-b.png")})`
                  : `url(${window.$$setImgSrc("yxkj-footer-w.png")})`,
          backgroundColor: footStyleCode === "0" ? "#151b33" : "#f6f6f6"
        }}>
          <div className="school_footer_container">
            <div className="school_name">
              <div className="top">
                <div>
                  {
                    footStyleCode === "0"?(
                        <>
                          {schoolData.icon && JSON.parse(schoolData.icon).imgValue && (
                              <img src={window.$$imgSrc(JSON.parse(schoolData.icon).imgValue)} alt="" />
                          )}
                        </>
                    ):(
                        <>
                          {schoolData.logo && JSON.parse(schoolData.logo).imgValue && (
                              <img src={window.$$imgSrc(JSON.parse(schoolData.logo).imgValue)} alt="" />
                          )}
                        </>
                    )
                  }
                  <p>
                    {schoolData?.name}
                  </p>
                </div>
              </div>
              <div className="bottom">
                <p>
                                <span>
                                    {
                                      footStyleCode === "0"?<S.Location />:<LocationH />
                                    }
                                  联系地址：
                                </span>
                  <span>{schoolData.linkAddress && schoolData.linkAddress} </span>
                </p>
                <p>
                                <span>
                                    {
                                      footStyleCode === "0"?<S.PostOffice />:<PostOfficeH />
                                    }
                                  邮编：
                                </span>
                  <span>{schoolData.linkZipcode && schoolData.linkZipcode}</span>
                </p>
                <p>
                                <span>
                                    {
                                      footStyleCode === "0"?<S.E_mail />:<E_mailH />
                                    }
                                  电子邮箱：
                                </span>
                  <span>{schoolData.linkEmail && schoolData.linkEmail}</span>
                </p>
                <p>
                                <span>
                                    {
                                      footStyleCode === "0"?<S.Tel style={{ verticalAlign: -3 }} />:<TelH style={{ verticalAlign: -3 }} />
                                    }
                                  联系电话：
                                </span>
                  <span>{schoolData?.linkPhone}</span>
                </p>
              </div>
            </div>
            <div className="school_code">
              {schoolData.publicQrcode && JSON.parse(schoolData.publicQrcode).imgValue ? (
                  <div>
                    <img src={window.$$imgSrc(JSON.parse(schoolData.publicQrcode).imgValue)} alt="" />
                    <p>公众号</p>
                  </div>
              ) : (
                  ""
              )}
              {schoolData.miniQrcode && JSON.parse(schoolData.miniQrcode).imgValue ? (
                  <div>
                    <img src={window.$$imgSrc(JSON.parse(schoolData.miniQrcode).imgValue)} alt="" />
                    <p>小程序</p>
                  </div>
              ) : null}
            </div>
          </div>
          <footer style={{
            backgroundColor: footStyleCode === "0" ? "rgba(0,0,0,0.1)" : "rgba(241,241,241,0.8)"
          }}>
            <div className="footerContainer">
              <div>
                  {
                      foot.suport&&<span>技术支持：{foot?.suport}</span>
                  }
                  {document.version && <span>版本：{document.version}</span>}
                {
                  foot.ICP&&<span className={'spanurl'} onClick={() => {
                    window.open(`${foot?.ICPurl}`)
                  }}>{foot?.ICP}</span>
                }
                {
                  foot.publicNetworkSecurity&&<span className={'spanurl'} onClick={() => {
                    window.open(`${foot?.publicNetworkSecurityUrl}`)
                  }}><img src={window.$$setImgSrc('beian.png')} alt=''/>{foot?.publicNetworkSecurity}</span>
                }

              </div>
              <p>
                {
                  footStyleCode === "0" ?(
                      <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            opacity="0.8"
                            d="M10.202 4.84744C10.6782 4.84744 11.0646 5.23381 11.0646 5.71V11.4572C11.0646 11.9334 10.6782 12.3198 10.202 12.3198H9.05302C8.57683 12.3198 8.19033 11.9334 8.19033 11.4572V5.70891C8.19033 5.23272 8.57669 4.84621 9.05302 4.84621H10.202V4.84744ZM2.15542 5.99643C2.63162 5.99643 3.01798 6.38279 3.01798 6.85899V11.4562C3.01798 11.9323 2.63162 12.3188 2.15542 12.3188H1.00644C0.530248 12.3188 0.143881 11.9325 0.143881 11.4562V6.85789C0.143881 6.3817 0.530248 5.9952 1.00644 5.9952H2.15542V5.99643ZM6.17865 7.14541C6.65484 7.14541 7.04121 7.53178 7.04121 8.00797V11.4562C7.04121 11.9323 6.65484 12.3188 6.17865 12.3188H5.02966C4.55347 12.3188 4.16697 11.9325 4.16697 11.4562V8.00797C4.16697 7.53178 4.55333 7.14541 5.02966 7.14541H6.17865ZM9.15406 1.54924L8.47552 1.55375C8.23791 1.55498 8.04363 1.36399 8.04144 1.12527C8.03912 0.888888 8.2312 0.694611 8.46882 0.692286L10.1985 0.679708C10.3099 0.678615 10.4213 0.720724 10.5065 0.8059C10.5486 0.848009 10.5803 0.896818 10.6008 0.94795C10.6201 0.993341 10.6327 1.05022 10.6327 1.11051V2.8426C10.6327 3.08022 10.4395 3.2734 10.202 3.2734C9.96453 3.2734 9.77121 3.08022 9.77121 2.8426V2.15162L5.90931 6.01338C5.78312 6.13957 5.59226 6.17485 5.4297 6.10197L0.256401 3.80401C0.0392913 3.70735 -0.0595563 3.45278 0.0371038 3.23457C0.133627 3.01746 0.388334 2.91861 0.606537 3.01528L5.50832 5.19389L9.15406 1.54924Z"
                            fill="white"
                        />
                      </svg>
                  ):(
                      <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            opacity="0.8"
                            d="M10.202 4.84744C10.6782 4.84744 11.0646 5.23381 11.0646 5.71V11.4572C11.0646 11.9334 10.6782 12.3198 10.202 12.3198H9.05302C8.57683 12.3198 8.19033 11.9334 8.19033 11.4572V5.70891C8.19033 5.23272 8.57669 4.84621 9.05302 4.84621H10.202V4.84744ZM2.15542 5.99643C2.63162 5.99643 3.01798 6.38279 3.01798 6.85899V11.4562C3.01798 11.9323 2.63162 12.3188 2.15542 12.3188H1.00644C0.530248 12.3188 0.143881 11.9325 0.143881 11.4562V6.85789C0.143881 6.3817 0.530248 5.9952 1.00644 5.9952H2.15542V5.99643ZM6.17865 7.14541C6.65484 7.14541 7.04121 7.53178 7.04121 8.00797V11.4562C7.04121 11.9323 6.65484 12.3188 6.17865 12.3188H5.02966C4.55347 12.3188 4.16697 11.9325 4.16697 11.4562V8.00797C4.16697 7.53178 4.55333 7.14541 5.02966 7.14541H6.17865ZM9.15406 1.54924L8.47552 1.55375C8.23791 1.55498 8.04363 1.36399 8.04144 1.12527C8.03912 0.888888 8.2312 0.694611 8.46882 0.692286L10.1985 0.679708C10.3099 0.678615 10.4213 0.720724 10.5065 0.8059C10.5486 0.848009 10.5803 0.896818 10.6008 0.94795C10.6201 0.993341 10.6327 1.05022 10.6327 1.11051V2.8426C10.6327 3.08022 10.4395 3.2734 10.202 3.2734C9.96453 3.2734 9.77121 3.08022 9.77121 2.8426V2.15162L5.90931 6.01338C5.78312 6.13957 5.59226 6.17485 5.4297 6.10197L0.256401 3.80401C0.0392913 3.70735 -0.0595563 3.45278 0.0371038 3.23457C0.133627 3.01746 0.388334 2.91861 0.606537 3.01528L5.50832 5.19389L9.15406 1.54924Z"
                            fill="#333333"
                        />
                      </svg>
                  )
                }

                访问量：<span>{visitCount}</span>人
              </p>
            </div>
          </footer>
        </div>
    );
  }
}
