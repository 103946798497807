import React, { Component } from 'react'
import { pdfjs } from 'react-pdf';
import './index.scss'
import VideoPlayer from '../../components/Video'
import PdfPreview from './PdfPreview'
import FileViewer from 'react-file-viewer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default class FilePreview extends Component {
    state={
        full_flag:true,
        scalcnum: 1,
        currentpage:0,
        totalPage:0,
        resourceDetail:{},
        more:false,
        fileId:'',
    };

    componentDidMount(){
        this.props.onRef(this)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.fileId!=this.props.fileId){
        this.setState({
            fileId:nextProps.fileId
        })
        }

    }
    set_full_flag=(flag)=>{
        this.setState({
            full_flag:!flag
        },()=>{
            this.props.setFullType(!flag)

        })
    };

    set_full_flag_child=(flag)=>{
        this.setState({
            full_flag:!flag
        })
    };

    scalc_c=(type,n)=>{
        if(type==='-'&&n>1){
            this.setState({
                scalcnum:n-1
            })
        }else if(type==='+'&&n<2){
            this.setState({
                scalcnum:n*1+1
            })
        }else if(n===2){
            this.setState({
                full_flag:false
            })
        }
    };

    prev=(cur)=>{
        if(cur>=1){
            this.setState({
                currentpage:cur-1
            },()=>{
                // this.Document_wrap_cel
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }else{
            this.setState({
                currentpage:this.Document_wrap_cel.pages.map((v)=>v).length-2
            },()=>{
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }
    };

    next=(cur)=>{
        // if(cur<this.Document_wrap_cel.pages.map((v)=>v).length-2){
        if(cur<this.Document_wrap_cel.pages.map((v)=>v).length-1){
            this.setState({
                currentpage:cur+1
            },()=>{
                // this.Document_wrap_cel
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }else{
            this.setState({
                currentpage:0
            },()=>{
                this.Document_wrap_cel.pages.map((v)=>v)[this.state.currentpage].scrollIntoView(false)
            })
        }

    };

    onDocumentLoadSuccess = (e) => {
        this.setState({
            totalPage: e.numPages
        })
    };
    onRef = (ref) => {
        this.child = ref
    }
    setFullType = (flag) => {
        this.setState({
            full_flag: flag,
            ishover: flag
        }, () => {
            this.child.set_full_flag_child(!flag)
        })
    }

    render() {
        const {fileId}=this.state
        //   resourceType    1,"文档",2,"视频",3,"音频" 4,"图片",
        const {resourceType, resourceName,resourceSuffix, suffix} = this.props;
        // console.log('resourceType--->', resourceType)
        // console.log('fileId--->',fileId)
        const {$$webOfficeDownload,$$uploadRc}=window
        // const suffix = fileId.slice(fileId.lastIndexOf('.') + 1) // 文件后缀
        // console.log('suffix--->', resourceSuffix)

        // const url = resource_preview;
        return (
            <div className={resourceType === 1 ?"left_bottom left-con word-left":"left_bottom left-con"}>
                {resourceType === 1 ?"":<div style={{height:"60px"}}/>}

                <div className={resourceType === 1 ?"main_preview word-pre":"main_preview"}>
                    { resourceType === 1 ?
                        /*文档*/
                        <div className={'world-box'}>
                            {/*<iframe style={{width:"100%",marginTop:"20px",height:"500px"}}*/}
                            {/*        src={`${$$webOfficeDownload(fileId)}`+`/`+ resourceName}/>*/}
                            {suffix !== 'pdf'?
                                <FileViewer fileType={suffix} filePath={window.$$createUploadFilePath(fileId)}/>
                                :
                                <PdfPreview
                                    src={window.$$createUploadFilePath(fileId)}
                                    setFullType={this.setFullType}
                                    onRef={this.onRef}
                                />}
                        </div>:
                        resourceType === 2 ?
                            /* {视频} */
                            <div>
                                <VideoPlayer checkDpi={true} permitExpress={false} videoId={'videoId1'}
                                             src={$$uploadRc(fileId)} ref={(e) => {
                                    this.childPlay = e
                                }}/>

                            </div> :
                            resourceType === 3 ?
                                /* {音频} */
                                <div className="c_a_dio">
                                    <audio  id="media" autoPlay="autoPlay"  src={ $$uploadRc(fileId)} controls="controls">
                                    </audio>
                                </div>:
                                resourceType === 4 ?
                                    <div className="imgModal">
                                        <img src={$$uploadRc(fileId)} alt=""/>
                                    </div>
                                    :
                                    <div className="iframe_preview" >
                                        <iframe width="100%" height="75%" src={`${$$webOfficeDownload(fileId)}`+`/`+ resourceName} frameBorder="0"/>
                                    </div>
                    }
                </div>
                <div style={{height:"20px"}}/>
            </div>
        )
    }
}
