import React, { Component } from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import store from "./store";
import RouterView from "./router/router_view";
import { ConfigProvider,message } from "antd";
import routers from "./router/router_config";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
message.config({
  duration: 3,// 持续时间
  top:`40vh`, // 到页面顶部距离
  maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});
moment.locale("en");
window.elGoTop = function (n) {
  document.querySelector(n) && document.querySelector(n).scrollTo({
    top: 0,
    behavior: "smooth"
  });
}
moment.locale("en");
export default class App extends Component {
  render() {
    return (
        <Provider store={store}>
          <HashRouter>
            <ConfigProvider direction="rtl" locale={zhCN}>
              <RouterView routers={routers}></RouterView>
            </ConfigProvider>
          </HashRouter>
        </Provider>
    );
  }
}
