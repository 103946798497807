import React from "react";
import { Form,Row, Col,Carousel,} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import { withRouter,Link } from "react-router-dom";
import NoData from "../../components/noData"
import S from 'gongyongicon';
import { CricleLoding } from "gongyongloading";
import {
    indexTopBanner,
    indexCourseList,
    indexKingStudentList,
    indexStoryList,
    indexPlanList,
    indexTeacherList,
    indexNewsList,
    schoolInfo,
} from '../../api/index/index'
import RenderImg from '../../components/renderImg/index'
import moment from "moment";
const urlPre = "cvec-space-front-peixun/";
function getQueryVariable(variable) { // 获取参数
    let url = window.location.href
    const query = url.split("?")[1];
    if (query) {
        var vars = query&&query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){
                return pair[1]
            }
        }
    }
    return (false)
}

export default Form.create()(
    withRouter(
        class Index extends React.Component {
            state = {
                enterpriseId :JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'',//机构id,
                // enterpriseId : '123',
                bannerList:[],
                enterpriseInfo:{},//机构相关信息
                bannerImgList:[],//banner
                CourseList:[],
                List:[],
                PlanList:[],
                defIndex: 0,
                TeacherList: [],
                studentsList:[],//学霸列表
                LearnStoryList:[],//学习故事列表
                newsList:[], // 新闻资讯列表
                storyPageNum:1,//学习故事页数
                storyTotals:0,//学习故事总页数
                tableLoading:true,
                dataSourcesStart: '', // 数据来源开始时间
                dataSourcesEnd: '', // 数据来源结束时间
                loading: false,

            };

            componentDidMount() {
                this.getDateSources()
                // let curUrl=window.location.href
                // var regExp=/#(\S*)/;
                // curUrl= curUrl.replace(regExp,"")
                const schoolId  = getQueryVariable('schoolId')?getQueryVariable('schoolId') : JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:''
                schoolInfo(schoolId).then((res)=>{
                    this.setState({
                        enterpriseId : res?.schoolId,//机构id,
                    },()=>{
                        this.indexTopBanner()//banner列表
                        this.indexCourseList()//课程列表
                        this.indexKingStudentList()//学霸列表
                        this.indexStoryList()//学习故事
                        this.indexPlanList()
                        this.indexNewsList()
                        this.indexTeacherList()
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    })
                })
            }
            componentWillUnmount() {
                clearInterval(this.timer);
            }

            getDateSources = () => { // 获取当前时间所在周的日期范围
                var now =new Date();
                var nowTime =now.getTime();
                var day =now.getDay();
                var oneDayTime =24*60*60*1000;
                var MondayTime =nowTime - (day-1)*oneDayTime;
                var SundayTime = nowTime +(7-day)*oneDayTime;
                this.setState({
                    dataSourcesStart: moment(MondayTime).format("YYYY-MM-DD"),
                    dataSourcesEnd: moment(SundayTime).format("YYYY-MM-DD")
                })
            }
            carousel(){
                let speed = 10 * 1000;
                let index = -1;
                let carousel = document.querySelector('.content-list');
                if(!carousel){
                    return
                }
                let lis = carousel?carousel.querySelectorAll('li'):null;
                if (typeof(arguments[0]) === 'undefined') {
                    index = 0
                } else {
                    index = arguments[0];
                    this.setState({
                        defIndex: index
                    });
                    for (let i = 0; i < lis.length; i++) {
                        lis[i].classList.remove('active');
                    }
                    lis[index].classList.add('active');
                }
                clearInterval(this.timer);
                this.timer = setInterval(() => {
                    lis[index++].classList.remove('active');
                    index = index >= lis.length ? 0 : index;
                    lis[index].classList.add('active');
                    /*
                    切换的同时换大图和简介
                    */
                    this.setState({
                        defIndex: index
                    });
                }, speed);
            }
            handleOver(index) {
                let carousel = document.querySelector('.content-list');
                let lis = carousel.querySelectorAll('li');
                lis[index].classList.add('current');
            }
            handleOut(index) {
                let carousel = document.querySelector('.content-list');
                let lis = carousel.querySelectorAll('li');
                for (let i = 0; i < lis.length; i++) {
                    lis[i].classList.remove('current');
                }
            }
            //banner
            async indexTopBanner(){
                const {enterpriseId}=this.state
                this.setState({ loading: true });
                const bannerImgList=await indexTopBanner({'id':enterpriseId})
                this.setState({
                    bannerImgList,
                    loading: false
                })
            }

            //获取首页新闻列表
            async indexNewsList(){
                const {enterpriseId}=this.state
                const newsList=await indexNewsList({'id':enterpriseId})
                this.setState({
                    newsList,
                    tableLoading:false
                })
            }
            //跳转新闻资讯详情
            handLinknews = (item)=>{
                this.props.history.push(`/newscont/?${encodeURI(JSON.stringify({specialId:this.state.specialId,newsId: item.objectId,oneCategoryId:item.oneCategoryId,twoCategoryId:item.twoCategoryId}))}`)
            }
            //获取首页计划列表
            async indexPlanList(){
                const {enterpriseId}=this.state
                const PlanList=await indexPlanList({'id':enterpriseId})
                this.setState({
                    PlanList,
                    tableLoading:false
                })
            }
            //获取首页课程列表
            async indexCourseList(){
                const {enterpriseId}=this.state
                const CourseList=await indexCourseList({'id':enterpriseId})
                this.setState({
                    CourseList,
                    tableLoading:false
                })
            }
            //学霸列表
            async indexKingStudentList(){
                const {enterpriseId}=this.state
                const studentsList=await indexKingStudentList({'id':enterpriseId})
                this.setState({
                    studentsList,
                    tableLoading:false
                })
            }
            //教师列表
            async indexTeacherList(){
                const {enterpriseId}=this.state
                const TeacherList=await indexTeacherList({'id':enterpriseId})
                this.setState({
                    TeacherList,
                    tableLoading:false
                },()=>{
                    this.carousel();
                })
            }
            //学习故事列表
            async indexStoryList(){
                const {enterpriseId,storyPageNum}=this.state
                const params={enterpriseId:enterpriseId,pageNum:storyPageNum,pageSize:4}
                const LearnStoryList=await indexStoryList(params)
                this.setState({
                    LearnStoryList:LearnStoryList.data,
                    storyTotals:LearnStoryList.pages,
                    tableLoading:false
                })
            }
            //学习故事换一换
            turnStory=()=>{
                const {storyPageNum}=this.state
                this.setState({
                    storyPageNum:storyPageNum===1?2:1
                },()=>{
                    this.indexStoryList()
                })
            }
            //跳转计划详情
            toPlanDetail=(item)=>{
                this.props.history.push(`/plandetails/?${encodeURI(JSON.stringify({id:item.id}))}`)
            }

            //跳转课程详情
            toCourseDetail=(item)=>{
                this.props.history.push(`/courseintr/?${encodeURI(JSON.stringify({id:item.id}))}`)
            }

            // 轮播图左右箭头
            next = () => {
                this.slider.slick.slickNext();
            }
            prev = () => {
                this.slider.slick.slickPrev();
            }
            onClititle = () =>{
                this.mesWarning("课程已结束");
            }

            render() {
                const {newsList,CourseList,PlanList,defIndex, TeacherList,bannerImgList,studentsList,
                    LearnStoryList,storyTotals,tableLoading,dataSourcesStart,dataSourcesEnd,loading,footStyleCode} =this.state
                const { $$img,$$uploadRc,$$setImgSrc } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'index'}/>
                        <div className="resource_container">
                            <div className='CamelCaseindex'>
                                {/*轮播图*/}
                                <div className="banner">
                                    {loading ? (
                                        <CricleLoding className="asfd" />
                                    ) : (
                                        <>
                                            <Carousel autoplay ref={el => (this.slider = el)}>
                                                {bannerImgList&&bannerImgList.map((item,index)=>{
                                                    return(
                                                        <div key={index}>
                                                            {/*<img className='bannerImg' src={$$img(item.pictureId)}/>*/}
                                                            <a href={item.link} target='_blank'>
                                                                <RenderImg  type={item.mediaType} id={item.pictureId}/>
                                                            </a>

                                                        </div>
                                                    )
                                                })}
                                            </Carousel>
                                            {bannerImgList.length>0&&<span className="left-circle" onClick={this.prev}><S.Previcon/></span>}
                                            {bannerImgList.length>0&&<span className="right-circle" onClick={this.next}><S.Previcon/></span>}
                                        </>
                                    )
                                    }
                                    <div className="banner-list">
                                        <Row className="Camel-content">
                                            {
                                                newsList&&newsList.map((item,index) =>{
                                                    return(
                                                        <Col span={6} key={index} onClick={()=>{this.handLinknews(item)}}>
                                                            <h3>{item.newsTitle}</h3>
                                                            <span className='showP' dangerouslySetInnerHTML={{__html: item.newsContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'').replace(/\s/g,"")}}></span>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            {
                                                newsList?(
                                                    <Col span={6}>
                                                        <span onClick={()=>{this.props.history.push('/newsinformation')}}>查看更多 ></span>
                                                    </Col>
                                                ):""
                                            }

                                        </Row>
                                    </div>
                                </div>
                                {/*培训计划*/}
                                <div className='CamelCaseplan'>
                                    <div className="Camel-content">
                                        <Row>
                                            <Col className="title"><img src={`${$$img(urlPre + "title_icon1.png")}`} alt=""/><img src={`${$$img(urlPre + "title_icon2.png")}`}/>培训计划</Col>
                                        </Row>
                                        {
                                            tableLoading? <section style={{ textAlign: "center" }}>
                                                <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                                <p>数据正在加载中...</p>
                                            </section>:(
                                                <div>
                                                    {
                                                        PlanList.length>0?(
                                                            <Row>
                                                                {
                                                                    PlanList&&PlanList.map((item,index)=>{
                                                                        return(
                                                                            <Col className="col">
                                                                                <div className="left">
                                                                                    <RenderImg type={item.mediaType} size='m' id={item.planImg}/>
                                                                                </div>
                                                                                <div className="right">
                                                                                    <div className="title-h3">
                                                                                        <img src={`${$$img(urlPre + "title_icon3.png")}`}/>{item.planName}
                                                                                    </div>
                                                                                    <ul className="ul1">
                                                                                        {
                                                                                            item.labels&&item.labels.map((itm)=>{
                                                                                                return(
                                                                                                    <li>{itm}</li>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                    <p>{item.trainingOverview}</p>
                                                                                    <ul className="ul2">
                                                                                        {
                                                                                            item.taskName&&item.taskName.map((itma)=>{
                                                                                                return(
                                                                                                    <li>
                                                                                                        {itma==='课程'?
                                                                                                            <i className="i1"><img src={`${$$img(urlPre + "title_icon6.png")}`}/></i>:itma==='调研'?
                                                                                                                <i className="i2"><img src={`${$$img(urlPre + "title_icon4.png")}`}/></i>:itma==='作业'?
                                                                                                                    <i className="i3"><img src={`${$$img(urlPre + "title_icon5.png")}`}/></i>:itma==='考试'?
                                                                                                                        <i className="i4"><img src={`${$$img(urlPre + "title_icon7.png")}`}/></i>:itma==='签到'?
                                                                                                                            <i className="i5"><img src={`${$$img(urlPre + "title_icon49.png")}`}/></i>:
                                                                                                                            <i className="i6"><img src={require('@/assets/img/i5.png')}/></i>
                                                                                                        }
                                                                                                        <span>{itma}</span>
                                                                                                    </li>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </ul>
                                                                                    <div className="more">
                                                                                        <span>培训计划周期：{item.planStartTime? item.planStartTime : '--'} ~ {item.planStartTime?item.planEndTime?item.planEndTime:'至今':'--'}</span>
                                                                                        <a onClick={()=>{this.toPlanDetail(item)}} href="javascript:;">进入计划&nbsp;&nbsp;<img src={`${$$img(urlPre + "title_icon8.png")}`}/></a>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                        ):<NoData></NoData>
                                                    }
                                                    {
                                                        PlanList&&(
                                                            <Row>
                                                                <Col className="more-link">
                                                                    <Link to='/traininglistp'>查看更多 ></Link>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }


                                    </div>
                                </div>
                                {/*培训课程*/}
                                <div className='CamelCasecourse'>
                                    <div className="Camel-content">
                                        <Row>
                                            <Col className="title"><img src={`${$$img(urlPre + "title_icon1.png")}`}/><img src={`${$$img(urlPre + "title_icon2.png")}`}/>培训课程</Col>
                                        </Row>
                                        {
                                            tableLoading?<section style={{ textAlign: "center" }}>
                                                <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                                <p>数据正在加载中...</p>
                                            </section>:(
                                                <div>
                                                    {
                                                        CourseList.length>0?(
                                                            <Row>
                                                                <ul>
                                                                    {
                                                                        CourseList&&CourseList.map((item,index)=>{
                                                                            return(
                                                                                <li>
                                                                                    <div  className="pic" >  <RenderImg type={item.mediaType} size='m' id={item.courseImg}/>
                                                                                    </div>

                                                                                    <div className="text">
                                                                                        {/*<h2 onClick={()=>this.onClititle()}>{item.courseName}</h2>*/}
                                                                                        <h2 onClick={()=>{this.toCourseDetail(item)}}>{item.courseName}</h2>
                                                                                        <div className="ul">
                                                                                            {
                                                                                                item?.labels?.map((title)=>{
                                                                                                    return(
                                                                                                        <span>{title}</span>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                        <p>学习课时：{item.num}课时</p>
                                                                                        <div className="more">
                                                                                            <i><img src={`${$$img(urlPre + "title_icon48.png")}`} />{item.studentNum}人</i>
                                                                                            {/*<a onClick={()=>this.onClititle()} href="javascript:;">进入课程&nbsp;&nbsp;<img src={`${$$img(urlPre + "title_icon9.png")}`}/></a>*/}
                                                                                            <a onClick={()=>{this.toCourseDetail(item)}} href="javascript:;">进入课程&nbsp;&nbsp;<img src={`${$$img(urlPre + "title_icon9.png")}`}/></a>
                                                                                        </div>
                                                                                        {/*<a onClick={()=>{this.toCourseDetail(item)}} href="javascript:;">进入课程&nbsp;&nbsp;<img src={`${$$img(urlPre + "title_icon9.png")}`}/></a>*/}
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </Row>
                                                        ):(
                                                            <NoData></NoData>
                                                        )
                                                    }
                                                    <Row>
                                                        {
                                                            CourseList&&(
                                                                <Col className="more-link">
                                                                    <Link to='/courselist'>查看更多 ></Link>
                                                                </Col>
                                                            )
                                                        }
                                                    </Row>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                                {/*学习故事*/}
                                <div className='CamelCaseLearn' style={{background:`url('${$$img("cvec-space-front-peixun/learnbg.png")}') no-repeat top center`,backgroundSize: 'cover'}}>
                                    <div className="Camel-content">
                                        <Row>
                                            <Col className="title"><img src={`${$$img(urlPre + "title_icon1.png")}`}/><img src={`${$$img(urlPre + "title_icon2.png")}`}/>学习故事{storyTotals>1&&<i onClick={this.turnStory}><img src={`${$$img(urlPre + "title_icon11.svg")}`}/>换一换</i>} </Col>
                                        </Row>
                                        {
                                            tableLoading?<section style={{ textAlign: "center" }}>
                                                <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                                <p>数据正在加载中...</p>
                                            </section>:(
                                                <div>
                                                    {
                                                        LearnStoryList.length>0?(
                                                            <Row className="row-con">
                                                                <Col span={11} className="li-on">
                                                                    {LearnStoryList&&LearnStoryList.map((item,index)=>{
                                                                        if(index<2){
                                                                            return(
                                                                                <li>
                                                                                    <Link to={`/learnspage/?id=${item.storyId}`}>
                                                                                        <div className="h4-title">
                                                                                            {/*<img src={$$img(item.portraitId)}/>*/}
                                                                                            <RenderImg type={item.mediaType} size='m' id={item.portraitId}/>
                                                                                            <h4>{item.createUserName}</h4>
                                                                                            <span>{item.organizationName}</span>
                                                                                        </div>
                                                                                        <div className="text">
                                                                                            <div className='p' dangerouslySetInnerHTML={{__html: item.storyContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'')}}></div>
                                                                                            {index===1&&<span>
                                                                                        <RenderImg type={item.mediaType} size='m' id={item.storyImg}/>
                                                                                    </span>}
                                                                                        </div>
                                                                                        <div className="more-a">
                                                                                            <h5>{item.storyTitle}</h5>
                                                                                            <span><img src={`${$$img(urlPre + "title_icon10.png")}`}/>浏览 {item.collectionNum}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    })}
                                                                </Col>
                                                                <Col span={13} className="li-active">
                                                                    {LearnStoryList&&LearnStoryList.map((item,index)=>{
                                                                        if(1<index&&index<4){
                                                                            return(
                                                                                <li>
                                                                                    <Link to={`/learnspage/?id=${item.storyId}`}>
                                                                                        <div className="h4-title">
                                                                                            <RenderImg type={item.mediaType} size='m' id={item.portraitId}/>
                                                                                            <h4>{item.createUserName}</h4>
                                                                                        </div>
                                                                                        <div className="text text-on">
                                                                                            {
                                                                                                index===3?<div className='p on-p' dangerouslySetInnerHTML={{__html: item.storyContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'')}}></div>:(
                                                                                                    <div className='p' dangerouslySetInnerHTML={{__html: item.storyContent.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g,'').replace(/&nbsp;/ig,'')}}></div>
                                                                                                )
                                                                                            }
                                                                                            {index===3&& <span>
                                                                                        <RenderImg type={item.mediaType} size='m' id={item.storyImg}/>
                                                                                    </span>}
                                                                                        </div>
                                                                                        <div className="more-a">
                                                                                            <h5>{item.storyTitle}</h5>
                                                                                            <span><img src={`${$$img(urlPre + "title_icon10.png")}`}/>浏览 {item.collectionNum}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Col>
                                                            </Row>
                                                        ):(
                                                            <NoData></NoData>
                                                        )
                                                    }

                                                    <Row>
                                                        {
                                                            LearnStoryList&&(
                                                                <Col className="more-link">
                                                                    <Link to='/learnstory'>发现更多故事 ></Link>
                                                                </Col>
                                                            )
                                                        }
                                                    </Row>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                                {/*教师队伍*/}
                                <div className='CamelCaseteachers' style={{background:`url('${$$img("cvec-space-front-peixun/js_bg.png")}') no-repeat top center`}}>
                                    <div className="Camel-content">
                                        <Row>
                                            <Col className="title"><img src={`${$$img(urlPre + "title_icon1.png")}`}/><img src={`${$$img(urlPre + "title_icon2.png")}`}/>教师队伍</Col>
                                        </Row>
                                        {
                                            tableLoading?<section style={{ textAlign: "center" }}>
                                                <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                                <p>数据正在加载中...</p>
                                            </section>:(
                                                <div>
                                                    {TeacherList.length>0? <Row className="content-list">
                                                        <Col className="left">
                                                            <div className="pic">
                                                                {
                                                                    TeacherList[defIndex].portraitId?
                                                                        <RenderImg type={TeacherList[defIndex].mediaType} size='m' id={TeacherList[defIndex].portraitId}/>:
                                                                        <img src={window.$$imgSrc('gr.png')}/>
                                                                }
                                                            </div>
                                                            {/*<RenderImg type={TeacherList[defIndex].mediaType} size='s' id={TeacherList[defIndex].portraitId}/>*/}
                                                            <div className="text">
                                                                <h3>{TeacherList[defIndex].fullName}</h3>
                                                                <span>{TeacherList[defIndex].introduction}</span>
                                                                <p>{TeacherList[defIndex].keyWord}</p>
                                                            </div>
                                                        </Col>
                                                        <Col className="right" >
                                                            <ul>
                                                                {
                                                                    TeacherList&&TeacherList.map((item, index) => {
                                                                        return(
                                                                            <li className={index == 0 ? 'active' : ''} onClick={() => this.carousel(index)} onMouseOver={() => this.handleOver(index)} onMouseOut={() => this.handleOut(index)}>
                                                                                <i></i>
                                                                                {
                                                                                    item.portraitId?<RenderImg type={item.mediaType} size='m' id={item.portraitId}/>:
                                                                                        <img src={window.$$imgSrc('gr.png')}/>
                                                                                }

                                                                            </li>
                                                                        )
                                                                    })}

                                                            </ul>
                                                        </Col>
                                                    </Row>:(
                                                        <NoData></NoData>
                                                    )}
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                                {/*周学霸排行榜*/}
                                <div className='CamelCaserankings' style={{background:`url('${$$img(urlPre + "pic8.png")}') no-repeat top center`}}>
                                    <div className="Camel-content">
                                        <Row>
                                            <Col className="title"><img src={`${$$img(urlPre + "title_icon1.png")}`}/><img src={`${$$img(urlPre + "title_icon2.png")}`}/>周学霸排行榜<span>每周周日 2:00 更新数据</span></Col>
                                        </Row>
                                        {
                                            tableLoading?<section style={{ textAlign: "center" }}>
                                                <img src={window.$$setImgSrc("yxkj_loading.gif")} />
                                                <p>数据正在加载中...</p>
                                            </section>:(
                                                <div>
                                                    {
                                                        studentsList.length>0?(
                                                            <Row>
                                                                <Col span={24} className="li-pic">
                                                                    {

                                                                        studentsList&&studentsList.map((item,index)=>{
                                                                            if(index<=2){
                                                                                return(
                                                                                    <li>
                                                                                        <h4 style={index===0?{background:`url('${$$img(urlPre + "title_icon17.png")}')`}:index===1?{background:`url('${$$img(urlPre + "title_icon15.png")}')`}:index===2?{background:`url('${$$img(urlPre + "title_icon16.png")}')`}:{background:`url('${$$img(urlPre + "pic17.png")}') no-repeat `}}>
                                                                                            <i><img src={`${$$img(urlPre + "title_icon18.png")}`}/></i>
                                                                                            <h5>{index+1}</h5>
                                                                                        </h4>
                                                                                        <span className="pic">
                                                                                    <i>{index===0?<img className="an-crown" src={`${$$setImgSrc("px_title_icon14.png")}`} />:index===1?<img className="an-crown" src={`${$$setImgSrc("px_title_icon12.png")}`} />:index===2?<img className="an-crown" src={`${$$setImgSrc("px_title_icon13.png")}`} />:""}<RenderImg type={item.mediaType} size='m' id={item.portraitId}/></i>
                                                                                    <span className="span_img" style={index===0?{background:`url('${$$img(urlPre + "img_bor1.png")}')`}:index===1?{background:`url('${$$img(urlPre + "img_bor2.png")}')`}:index===2?{background:`url('${$$img(urlPre + "img_bor3.png")}')`}:""} >{/*<img src={$$img(item.portraitId)} />*/}<RenderImg type={item.mediaType} size='m' id={item.portraitId}/></span>
                                                                                </span>
                                                                                        <div className="text">
                                                                                            <h3>{item.fullName}</h3>
                                                                                            <span>学习时间：<i>{item.totalDuration?(item.totalDuration/60).toFixed(2):0}</i>分钟</span>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            }

                                                                        })
                                                                    }
                                                                </Col>
                                                                <ul className="li-pica">
                                                                    {
                                                                        studentsList&&studentsList.map((item,index)=>{
                                                                            if(index>2){
                                                                                return(
                                                                                    <li className={'li_box_px'}>
                                                                                        {
                                                                                            index >= 9?
                                                                                                <div className={'li_number'}>{index+1}</div>:
                                                                                                <div className={'li_number'}>0{index+1}</div>
                                                                                        }

                                                                                        <RenderImg type={item.mediaType} size='m' id={item.portraitId}/>
                                                                                        <div>
                                                                                            <div className={'li_name_px'}>{item.fullName}</div>
                                                                                            <div className={'li_time_px'}>学习时长：{item.totalDuration?(item.totalDuration/60).toFixed(2):0}分钟</div>
                                                                                        </div>

                                                                                    </li>
                                                                                )
                                                                            }

                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="last-li" style={{paddingTop:'10px'}}>
                                                                    数据来源：{dataSourcesStart} 00:00 到 {dataSourcesEnd} 24:00的数据
                                                                </div>
                                                            </Row>
                                                        ):(
                                                            <NoData></NoData>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer footStyleCode={footStyleCode}/>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
