// 搜索结果页
import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import { Input, Tabs, Pagination, Form, } from 'antd';
import { withRouter, } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import {peixunSearchNewsAndSpecial} from '@/api/indexnews'
import RenderImg from '../../components/renderImg/index'
import NoData from "../../components/noData";
const { Search } = Input;
const { TabPane } = Tabs;
const urlPre = "cvec-space-front-peixun/";

function onShowSizeChange(current, pageSize) {
    console.log(current, pageSize);
}

export default Form.create()(
    withRouter(
        class Searchresult extends React.Component {
            state = {
                activeKey:'1',
                pageNum: 1,
                pageSize: 10,
                total:0,
                pageNumz: 1,
                pageSizez: 10,
                totalz:0,
                keyword: '', // 搜索关键字
                organizationId:  JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'', // 机构id
                platform: 'TS', // 业务系统标识
                newsList: [], // 新闻列表
                specialList: [], // 专题列表
            };

            componentWillMount () {
                const data = this.props.location.search; //地址栏截取
                const param = data.split("?")[1];
                console.log("param",param)
               if (param) {
                   const codeParam = param.includes("%") ? decodeURI(param) : param;
                   const jsonparam = JSON.parse(codeParam);
                   this.setState({
                       keyword: jsonparam.keyword,
                   },()=>{
                       this.peixunSearchNewsAndSpecial(1,10)
                   });
               }
            }

            onSearch = async (value) => {
                this.setState({
                    keyword: value,
                },()=>{
                    let {activeKey}=this.state
                    let pageNum = ''
                    let pageSize = ''
                    if (activeKey === '1') {
                        pageNum = 1
                        pageSize = this.state.pageSize
                    } else {
                        pageNum = 1
                        pageSize = this.state.pageSizez
                    }
                    this.peixunSearchNewsAndSpecial(pageNum,pageSize)
                });
            }

            async peixunSearchNewsAndSpecial (pageNum,pageSize) {
                const {organizationId,platform,keyword}=this.state
                console.log("organizationId",organizationId)
                let data = {
                    organizationId: organizationId,
                    platform: platform,
                    pageNum: pageNum,
                    pageSize: pageSize,
                    keyword: keyword
                }
                await peixunSearchNewsAndSpecial(data).then(res=>{
                    if (res) {
                        this.setState({
                            newsList: res.newsList,
                            specialList: res.specialList
                        })

                    }
                })
            }
            callback = async (key) => {
                await this.setState({activeKey: key})
            }
            onShowSizeChangeNew=(current, pageSize)=>{ // 分页
                this.setState({
                    pageSize:pageSize,
                    pageNum:current
                },()=>{
                    this.peixunSearchNewsAndSpecial(current,pageSize)
                })
            }
            onChangeNew=(page)=>{
                this.setState({
                    pageNum:page
                },()=>{
                    this.peixunSearchNewsAndSpecial(page,this.state.pageSize)
                })
            }
            onShowSizeChangez=(current, pageSize)=>{ // 分页
                this.setState({
                    pageSizez:pageSize,
                    pageNumz:current
                },()=>{
                    this.peixunSearchNewsAndSpecial(current,pageSize)
                })
            }
            onChangez=(page)=>{
                this.setState({
                    pageNumz:page
                },()=>{
                    this.peixunSearchNewsAndSpecial(page,this.state.pageSizez)
                })
            }
            gotoNewsDetial = item => { // 进入新闻详情页
                this.props.history.push(`/newscont/?${encodeURI(JSON.stringify({newsId: item.newsId,oneCategoryId:item.oneCategoryId,twoCategoryId:item.twoCategoryId}))}`)
            }
            gotoSpeList = item => { // 进入专题详情页
                this.props.history.push(`/projectlist/?${encodeURI(JSON.stringify({id: item.specialId}))}`)
            }

            render() {
                const {activeKey,newsList,specialList} =this.state
                const { $$img } = window;
                // 资 讯
                let Introductionpic=<div className="Introductionpic_ul">
                    <div className="ul">
                        {
                            newsList.data && newsList.data.length > 0 ? newsList.data.map((item,indx)=>{
                                return (
                                    <div className="li"  onClick={()=>this.gotoNewsDetial(item)}>
                                        <h3>{item.newsTitle}</h3>
                                        <div className="text">
                                            <div className="left-img" style={{background:`url('${window.$$img('cvec-web-global/global_placeholder_1.png')}')no-repeat`}}>
                                                {/*<img src={require('../../assets/img/news-img1.jpg')}/>*/}
                                                <RenderImg size='m' id={item.newsImg} type={item.mediaType}/>
                                            </div>
                                            <div className="right-text">
                                                <div className="dov">
                                                    {item.newsContent}
                                                </div>
                                                <div className="link">
                                                    <h5><i className={item.newsType === 1? '': 'on'}>{item.newsType === 1 ? '原创' : '转载'  }</i>来源：{item.authorName}</h5>
                                                    <span>{item.releaseTime}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            }) :  <NoData></NoData>

                        }
                    </div>
                    <Pagination className="Pagination-fs"
                                showSizeChanger
                                onShowSizeChange={()=>this.onShowSizeChangeNew()}
                                onChange={()=>this.onChangeNew()}
                                total={newsList.total}
                                current={newsList.pageNum}
                    />
                </div>
                // 专 题
                let Special= <div className="Special_ul">
                    <div className="project-ul">
                        {
                            specialList.data && specialList.data.length > 0 ? specialList.data.map((item,index)=>{
                                return (
                                    <div className="li" onClick={()=>this.gotoSpeList(item)}>
                                        <div className="bottom-text">
                                            <div className="left-pic">
                                                <h3 className="search-h5">{item.specialName}</h3>
                                                <div className="pic-img">
                                                    <RenderImg type={item.mediaType} size='m' id={item.specialImg}/>
                                                    {/*<img src={require('../../assets/img/news-img1.jpg')}/>*/}
                                                    <div className="pic" style={{background:`url('${window.$$img('cvec-space-front-peixun/zt-bg.png')}')no-repeat`}}>
                                                        <span className="dov">
                                                            {item.specialDesc}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-text">
                                                <span className="more">专题详情 ></span>
                                                <div className="li-zt">
                                                    {
                                                        item.specialNewsList && item.specialNewsList.map((i,indes)=>{
                                                            return (
                                                                <div className="li-title">
                                                                    <h4><em className="i"></em>{i.newsTitle}</h4>
                                                                    <span className="time">[{i.releaseTime}]</span>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            }) :
                                <NoData></NoData>
                        }
                    </div>
                    <Pagination className="Pagination-fs"
                                showSizeChanger
                                onShowSizeChange={this.onShowSizeChangez}
                                onChange={this.onChangez}
                                total={specialList.total}
                                current={specialList.pageNum}
                    />
                </div>
                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='CamelCasesearchresult'>
                                <div className="back_img_box"
                                     style={{background:`url('${window.$$img('cvec-space-front-peixun/search_banner.png')}')no-repeat`,height:'160px'}}>
                                    <div className="Camel-content">
                                        <div className="title_box">
                                            <div className="modal_tile">搜索结果</div>
                                            <div className="modal_con">精准资讯 · 快人一步</div>
                                        </div>
                                        <div className="right_tex">
                                            <div className="modal_input_box-1">
                                                <Search
                                                    placeholder="请输入关键字"
                                                    allowClear
                                                    enterButton="搜索"
                                                    size="large"
                                                    onSearch={this.onSearch}
                                                />
                                            </div>
                                            <span className="text_span">共搜索到 {specialList.total + newsList.total} 条内容</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-list">
                                    <div className="Camel-content">
                                        <div className="news_top">
                                            <Tabs activeKey={activeKey} onChange={this.callback.bind(this)}>
                                                <TabPane tab="资 讯" key="1">
                                                </TabPane>
                                                <TabPane tab="专 题" key="2">
                                                </TabPane>
                                            </Tabs>
                                            {
                                                activeKey === "1" ? Introductionpic : (activeKey === "2" ? Special :"")
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
