// 我的兴趣课程资源预览
import React from 'react';
import {Form,} from 'antd';
import './index.scss';
import PeixunPreview from "@/components/PeixunTwoPreview";
import {zyObj} from '@/config/secret'
class CoursePreview extends React.Component {
    state = {
        fileDetail:this.props.fileDetail,
        id:{},
        txtNumber: zyObj.txtNumber, // 文档
        videoNumber: zyObj.videoNumber, // 视频
        audioNumber:zyObj.audioNumber, // 音频
        imgNumber: zyObj.imgNumber, // 图片
        fileId:'',
        transFileDetail:this.props.fileDetail,
        transSuffix:'',
        suffix: ''
    };
    componentDidMount() {
        const {fileId,transSuffix,fileDetail,resourceName, suffix}=this.props
        this.setState({
            fileId:fileId,
            transSuffix:transSuffix,
            resourceName: resourceName,
            suffix: suffix
        })

    }
    componentDidUpdate(nextPros){
        const {fileId,transSuffix,fileDetail,resourceName}=this.props
        if(fileId!=this.state.fileId){
            this.setState({
                fileId:fileId,
                transSuffix:transSuffix,
                resourceName:resourceName
            },()=>{
            })
        }
    }
    setFullType=(flag)=>{
        this.setState({
            full_flag:flag,
            ishover:flag
        },()=>{
            this.child.set_full_flag_child(!flag)
        })
    }
    onRef = (ref) => {
        this.child = ref
    }
    render() {
        const {transFileDetail,resourceName,fileId, suffix}=this.state
        // console.log('fffffff--->', fileId)
        const {fileDetail}=this.props
        return (
            <div className="coursePreview_box">
                {
                    <PeixunPreview type={1} startTime={0} pageSize={1} fileId={fileId} resourceType={fileDetail.dataType}
                                   resourceContent={fileDetail.resourceContent} resourceName={resourceName} transSuffix={fileDetail.transSuffix}
                                   resourceSuffix={fileDetail.resourceSuffix} setFullType={this.setFullType} suffix={suffix}
                                   onRef={this.onRef}/>

                }
            </div>
        );
    }
}
CoursePreview = Form.create()(CoursePreview)
export default CoursePreview;
