// 热门专题
import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import { Pagination, Form} from 'antd';
import { withRouter } from "react-router-dom";
import Heder from "../../components/headerroom";
import {peixunIndexNewsSpecialList} from "@/api/hottopic/index";
import RenderImg from '@/components/renderImg/index'
import Footer from "../../components/footer";
import NoData from "../../components/noData";
const urlPre = "cvec-space-front-peixun/"

export default Form.create()(
    withRouter(
        class Hottopic extends React.Component {
            state = {
                organizationId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'', //机构id,
                activeKey:'1',
                pageNum: 1,
                pageSize: 10,
                total:0,
                newListBox:[]
            };
            onSearch = (value) => {
            }
            callback = async (key) => {
                await this.setState({activeKey: key})
            }
            onShowSizeChange=(current, pageSize)=>{ // 分页
                this.setState({
                    pageSize:pageSize,
                    pageNum:current
                },()=>{
                    let data={
                        pageSize:pageSize,
                        pageNum:current,
                        platform: "TS",
                        organizationId:this.state.organizationId
                    }
                    this.getList(data)
                })
            }
            onChange=(page)=>{
                this.setState({
                    pageNum:page
                },()=>{
                    let data={
                        pageSize:this.state.pageSize,
                        pageNum:page,
                        platform: "TS",
                        organizationId:this.state.organizationId
                    }
                    // this.planDetailClassListPage()
                })
            }
            componentDidMount(){
                let data = {
                    pageNum: 1,
                    pageSize: 10,
                    platform: "TS",
                    organizationId:this.state.organizationId
                }
                this.getList(data)
            }
            //获取初始数据
            async getList (data) {
                await peixunIndexNewsSpecialList(data).then(res=>{
                    this.setState({
                        newListBox:res.data,
                        total:res.total
                    })
                })
            }
            //点击新闻进入详情页
            topicDetails(value){
                let data={
                    id:value
                }
                this.props.history.push(`/projectlist/?${encodeURI(JSON.stringify(data))}`)
            }
            render() {
                const {pageNum, total, pageSize,newListBox} =this.state
                const { $$img } =window
                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='CamelHottopic'>
                                {/*背景大图*/}
                                <div className="back_img_box" style={{background:`url('${window.$$img('cvec-space-front-peixun/news-list1.png')}')no-repeat`,height:'360px'}}>
                                    <div className="Camel-content">
                                        <div className="title_box">
                                            <div className="modal_tile">专题详情</div>
                                            <div className="modal_con">聚焦职教热点，关注行业发展</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="project_list">
                                    {newListBox.length>0?<div className="Camel-content">
                                        {newListBox.map(item=>(
                                            <div className="project-ul">
                                                <div className="li"  onClick={()=>this.topicDetails(item.specialId)}>
                                                    <div className="bottom-text">
                                                        <div className="left-pic">
                                                            <h3 className="search-h5">
                                                                {item.specialName}
                                                            </h3>
                                                            <div className="pic-img">
                                                                <RenderImg type={item.mediaType} size='m' id={item.specialImg}/>
                                                                <div className="pic" style={{background:`url('${window.$$img('cvec-space-front-peixun/zt-bg.png')}')no-repeat`}}>
                                                                    <span className="dov">
                                                                        {item.specialDesc}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right-text">
                                                            <span className="more">专题详情 ></span>
                                                            <div className="li-zt">
                                                                {item.specialNewsList.map(itemChild=>(
                                                                    <div className="li-title">
                                                                        <h4><em className="i"></em>{itemChild.newsTitle}</h4>
                                                                        <span className="time">{itemChild.releaseTime}</span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <Pagination className="Pagination-fs"
                                                    showSizeChanger
                                                    onShowSizeChange={this.onShowSizeChange}
                                                    onChange={this.onChange}
                                            // defaultCurrent={1}
                                                    total={total}
                                                    pageSize={pageSize}
                                                    current={pageNum}
                                        />
                                    </div>:(
                                        <NoData></NoData>
                                    )}

                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
