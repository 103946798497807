// 专注时刻
import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Row, Modal,Form, Pagination} from 'antd';
import { withRouter,Link } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import Swiperlist from "./swiper";
import {absorbedTimeList} from "@/api/indexnews/index"
import RenderImg from '../../components/renderImg/index'
const urlPre = "cvec-space-front-peixun/";

export default Form.create()(
    withRouter(
        class Focusontime extends React.Component {
            state = {
                pageNum: 1,
                pageSize: 20,
                total:0,
                zzskList: [], // 专注时刻列表
                setIsModalVisible:false,
                childInfo: {}, // 往弹层传的值
                indexChild: '', // 选择的图片索引
                id: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'' //机构id,,
            };
            componentDidMount() {
                this.absorbedTimeList()
            }
            async absorbedTimeList () { // 专注时刻列表
                const { id, pageNum, pageSize } = this.state
                let data = {
                    id: id,
                    pageNum,
                    pageSize
                }
                await absorbedTimeList(data).then(res=>{
                    if (res) {
                        this.setState({
                            zzskList: res.data,
                            pageNum: res.pageNum,
                            pageSize: res.pageSize,
                            total: res.total,
                            childInfo: res
                        })
                    }
                })
            }

            onShowSizeChange = (current, pageSize) => { // 分页
                this.setState({
                    pageSize:pageSize,
                    pageNum:current
                },()=>{
                    window.scrollTo(0,0)
                    this.absorbedTimeList()
                })
            }

            onChange = (page) => { // 分页
                this.setState({
                    pageNum:page
                },()=>{
                    window.scrollTo(0,0)
                    this.absorbedTimeList()
                })
            }
            showModal = (index) => { // 展示弹层
                this.setState({
                    setIsModalVisible:true,
                    indexChild: index
                })
            }
            onClear = () => { // 关闭弹层
                this.setState({
                    setIsModalVisible:false,
                    indexChild:'',
                })

                this.children.handleCloseSwiper()
            }
            render() {
                const {pageNum, total,setIsModalVisible,zzskList,childInfo, indexChild} =this.state
                const { $$img } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='CamelCaseFocusontime'>
                                <div className="Camel-content">
                                    <div className="information_title">
                                        <div className="title">
                                            <h2>培训风采</h2>
                                        </div>
                                    </div>
                                    <ul className="Focusontime_list">
                                        {
                                            zzskList && zzskList.map((item,index)=> {
                                                return (
                                                    <li onClick={()=>this.showModal(index)}>
                                                        <span className="pic">
                                                            <RenderImg type={item.mediaType} size='m' id={item.imgUrl}/>
                                                        </span>
                                                        <span className="text">
                                                            {item.textDescription}
                                                        </span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <Row>
                                        <Pagination className="Pagination-fs"
                                                    showSizeChanger
                                                    defaultPageSize = {20}
                                                    onShowSizeChange={this.onShowSizeChange}
                                                    onChange={this.onChange}
                                                    total={total}
                                                    current={pageNum}
                                        />
                                    </Row>
                                </div>
                                <Modal className="swiperImg-content"
                                       maskStyle={{background:'rgba(0, 0, 0, 0.8)'}}
                                       visible={setIsModalVisible}
                                       footer={null}
                                       centered={true}
                                       closable={true}
                                       onCancel={this.onClear}
                                       closeIcon={
                                           <div>
                                               <img src={require('../../assets/img/cha.png')}/>
                                           </div>
                                       }
                                >
                                    <Swiperlist onRef={c=>this.children=c} childInfo = {childInfo} indexChild = {indexChild}/>
                                </Modal>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
