
//全局变量配置文件  区分生产和测试环境
//pro 生产环境 dev测试环境
const REACT_APP_BUILD_URL = process.env.REACT_APP_BUILD_URL;
const urlObj = {};



export let loginUrl = ''//登录中心
export let ucenterUrl = ''//用户中心
export let baseURL = 'https://gw.cveducloud.com'//axios baseURL
export let domain = '' // 域名
export let viewQuestion = ''
export let previewQuestion = ''//问卷预览地址
export let createQnUrl = ''// 创建问卷地址
export let msgUrl = ''
export let logoutUrl = '' // 退出登录
export let footer = {} // 页脚信息

export const setLoginUrl = (url) => {
    loginUrl = url
}
export const setLogoutUrl = (url) => {
    logoutUrl = url
}
export const setUcenterUrl = (url) => {
    ucenterUrl = url
}
export const setBaseURL = (url) => {
    baseURL = url
}
export const setDomain = (url) => {
    domain = url
}
export const setMsgUrl = (url) => {
    msgUrl = url
}
export const setViewQuestion = (url) => {
    viewQuestion = url
}
export const setCreateQnUrl = (url) => {
    createQnUrl = url
}
export const setPreviewQuestion = (url) => {
    previewQuestion = url
}
export const setFooter= (url) => {
    footer = url
}


// 依赖07接口
export const initUrlByType07 = (config) => {
    setLoginUrl(config?.sys?.logincenter ?? '')
    setUcenterUrl(config?.sys?.gerenzhongxin ?? '')
    setBaseURL(config?.gw ?? '')
    let domain = config?.gw?.split('.').reverse().slice(0, 2).reverse().join('.')
    setDomain(domain ? `.${domain}` : '')
    setMsgUrl(config?.sys?.xiaoxizhongxin ?? '')
    setViewQuestion(config?.sys?.wenjuan ?? '')
    setCreateQnUrl(config?.sys?.wenjuan + '/create')
    setPreviewQuestion(config?.sys?.webjuan + '/previewQuestion?libid=')
    setLogoutUrl(config?.sys?.logoutcenter ?? '')
    setFooter(config?.foot)
}

