import React from "react";
import Styles from './index.module.scss'
import S from "gongyongicon";
import { footer } from '../../config/index'
import { getImageOssBaseurl } from "@/api/Baseurl";
import {userInfo} from "../../until/authorityList";
import { PortalFooter} from 'gongyongheader'
import SchoolFooter from '../school_footer'
import {getManageConfig, schoolInfo} from '../../api/index/index'
export default class Footer extends React.Component {
  state = {
    enterpriseInfo: localStorage.getItem('enterpriseInfo') ? JSON.parse(localStorage.getItem('enterpriseInfo')) : '{}',//机构信息,
    officialAddress: '',
    schoolData: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')):'',//院校信息,
    logo: localStorage.getItem('enterpriseInfo') ? JSON.parse(localStorage.getItem('enterpriseInfo')).logo : '',//机构logo,
    type_config:{},
    visitCount: 0, //浏览量
    footStyleCode:localStorage.getItem('footStyle') ? JSON.parse(localStorage.getItem('footStyle')).footStyleCode : '',//底部色值,
  }
  componentDidMount() {
    this.schoolInfo()
    getImageOssBaseurl({ type: '100007' }).then(res => {
      this.setState({
        type_config:res.config
      });
    })
    this.setBody();
  }

  schoolInfo = () => {
    let schoolId = this.state.enterpriseInfo.schoolId;
    schoolInfo(schoolId).then((res) => {
      this.setState({
        visitCount: res.visitCount
      })
    })
  }
  getManageConfig = () => {
    let schoolId = this.state.enterpriseInfo.schoolId;
    getManageConfig(schoolId).then((res) => {
      this.setState({
        footStyleCode: res.footStyleCode,

      })
    })
  }

  onLink = (val) => {
    if (val == 1) {
      window.open("http://www.moe.gov.cn/");
    }
    if (val == 2) {
      window.open("http://www.moe.gov.cn/s78/A07/index.html");

    }
    if (val == 3) {
      window.open("https://www.ndrc.gov.cn/");
    } else {
      window.open("http://www.miit.gov.cn/");
    }
  };
  setBody() {
    var ifMobile = this.isIphone();
    var _this = this;
    if (ifMobile) {
      let meta = document.createElement("meta");
      let content = "";

      _this.presentFloat = true;
      document.getElementsByTagName("body")[0].style.width = "1920px";
      var phoneWidth = parseInt(window.screen.width);
      var phoneScale = phoneWidth / 1920; //除以的值按手机的物理分辨率					console.log('phoneScale',phoneScale)
      var ua = navigator.userAgent;
      if (/Android (\d+\.\d+)/.test(ua)) {
        var version = parseFloat(RegExp.$1);
        if (version > 2.3) {
          content =
            "width=device-width,initial-scale=" +
            phoneScale +
            ",minimum-scale=" +
            phoneScale +
            ",maximum-scale=" +
            phoneScale +
            ",user-scalable=no";
          // andriod 2.3以上
        } else {
          content = "width=device-width,user-scalable=no";
        }
      } else {
        //console.log("ios")
        content =
          "width=device-width, initial-scale=" +
          phoneScale +
          ",minimum-scale=" +
          phoneScale +
          ",maximum-scale =" +
          phoneScale +
          ",user-scalable=no";
      }
      meta.content = content;
      meta.name = "viewport";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
  }
  ismobile() {
    var mobileArry = [
      "iPhone",
      "iPad",
      "Android",
      "Windows Phone",
      "BB10; Touch",
      "BB10; Touch",
      "PlayBook",
      "Nokia",
    ];
    var ua = navigator.userAgent;
    var res = mobileArry.filter(function (arr) {
      return ua.indexOf(arr) > -1;
    });
    return res.length > 0;
  }
  isIphone() {
    return /iphone/gi.test(navigator.userAgent);
  }
  render() {
    const { enterpriseInfo, logo ,schoolData,type_config, visitCount,footStyleCode} = this.state
    const imgValue = logo ? JSON.parse(logo).imgValue : ''
    const miniQrcode = enterpriseInfo.miniQrcode
    const miniImg = miniQrcode ? JSON.parse(miniQrcode).imgValue : ''
    const publicQrcode = enterpriseInfo.publicQrcode
    const publicImg = publicQrcode ? JSON.parse(publicQrcode).imgValue : ''
    return (
      <div className={Styles.school_footer_wrap}>
        <SchoolFooter {...this.props} schoolData={schoolData} visitCount={visitCount} footStyleCode={footStyleCode}/>
        {/*<PortalFooter*/}
        {/*    schoolData={schoolData}*/}
        {/*    type_config={type_config}*/}
        {/*/>*/}
      </div>
    );
  }
}
