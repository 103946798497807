import React, {Component} from 'react'

export default class render_Img extends Component {
    state = {
        imgFlag:1,
        holdImg:'cvec-web-global/global_placeholder_2.png',//默认图片
        imgList:[],
        id:''
    }

    componentDidMount() {
        this.handleImg(this.props)
    }

    UNSAFE_componentWillReceiveProps(props) {
        const {id}=this.state
        if (props.id !== id) {
            this.setState({
                imgFlag:1,
                id:props.id
            })
            this.handleImg(props)

        }
    }

    componentWillUnmount(){
        this.setState({
            id:''
        })
    }

    handleImg=(props)=>{
        const {holdImg}=this.state
        const {id,size,type}=props
        this.setState({
            id:id
        })
        let imgList=[`${id}_${size}.${type}`,id,holdImg]
        this.setState({
            imgList:[...imgList]
        })
    }

    render() {
        const {$$img, $$uploadRc} = window;
        const {imgList,imgFlag}=this.state
        return (
            <>
                <img  src={$$uploadRc(imgList[0])}
                      onError={e => {
                          if(imgFlag<3){

                              if(imgFlag===2){
                                  e.target.src = $$img(imgList[imgFlag])
                              }else{
                                  e.target.src = $$uploadRc(imgList[imgFlag])
                              }
                              this.setState({
                                  imgFlag:this.state.imgFlag+1
                              })
                          }
                      } }
                      alt=''
                />
            </>
        )
    }
}
