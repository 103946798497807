import LoadableComponent from "../until/LoadableComponent";
let moduleFile = require.context("../page", true, /\index.(jsx|js)$/);
let result = moduleFile.keys().reduce((prev, item) => {
  let str = item.split("/")[item.split("/").length - 2];
  let name = str[0].toLocaleUpperCase() + str.slice(1);
  prev = Object.assign({}, prev, {
    [name]: LoadableComponent(import("../page" + item.slice(1))),
  });
  return prev;
}, {});

export default [
  {
    name: "首页",
    path: "/",
    component: result.Index,
    exact: true,
  },
  {
    name: "登录",
    path: "/login",
    component: result.Login,
    exact: true,
  },
  // {
  //   name: "首页",
  //   path: "/",
  //   component: result.Index
  // },
  {
    name: "培训计划",
    path: "/traininglistp",
    component: result.Traininglistp
  },
  {
    name: "培训计划详情",
    path: "/plandetails",
    component: result.Plandetails
  },
  {
    name: "新闻资讯",
    path: "/newsinformation",
    component: result.Newsinformation
  },
  {
    name: "新闻列表",
    path: "/newslist",
    component: result.Newslist
  },
  {
    name: "新闻详情",
    path: "/newscont",
    component: result.Newscont
  },
  {
    name: "时刻专注",
    path: "/focustime",
    component: result.Focustime
  },
  {
    name: "学习故事",
    path: "/learnstory",
    component: result.Learnstory
  },
  {
    name: "学习故事详情",
    path: "/learnspage",
    component: result.Learnspage
  },
  {
    name: "培训课程",
    path: "/courselist",
    component: result.Courselist
  },
  {
    name: "课程介绍",
    path: "/courseintr",
    component: result.Courseintr
  },
  {
    name: "课程资料",
    path: "/coursematerials",
    component: result.Coursematerials
  },
  {
    name: "搜索结果",
    path: "/searchresult",
    component: result.Searchresult
  },
  {
    name: "热门专题",
    path: "/hottopic",
    component: result.Hottopic
  },
  {
     name: "专题列表",
     path: "/projectlist",
     component: result.Projectlist
  },
  {
    name: "专注时刻",
    path: "/focusontime",
    component: result.Focusontime
  },
];
