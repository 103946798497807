import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "antd/dist/antd.css";
import "./reset_style/index.less";
import "@/components/global_components/confirm/index.css";
import { apiPrefix, apiRcenter, apiZixun, apiUcenter } from "./config/apiPrefix";
import { schoolInfo, getPageView,getManageConfig } from "./api/index"
import "@/components/global_components/confirm/index.js";
import store from './store'
import { Provider } from 'react-redux'
import { getImageOssBaseurl } from "./api/Baseurl";
import { baseURL, initUrlByType07 } from './config'
import { initSecretUrlByType07 } from './config/secret'
import Http from './until/axios_instans'
import { getAuthorityList } from './until/authorityList'
import { getFoot } from "./components/school_footer/getfoot";


window.apiPrefix = apiPrefix(); //培训api前缀
window.apiRcenter = apiRcenter(); //资源api前缀
window.apiZixun = apiZixun(); //资讯api前缀
window.apiUcenter = apiUcenter(); //资讯api前缀


function create(src) {
    return function (path) {
        if (!path) return;
        if (path.indexOf("/") === 0) {
            return src + path.slice(1);
        } else {
            return src + path;
        }
    }
}
function getQueryVariable(variable) { // 获取参数
    let url = window.location.href
    const query = url.split("?")[1];
    if (query) {
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1]
            }
        }
    }
    return (false)
}
const schoolId = getQueryVariable('schoolId') ? getQueryVariable('schoolId') : JSON.parse(localStorage.getItem('enterpriseInfo')) ? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId : '' // 机构id
if (schoolId) {
    // 请求前将访问量+1
    getPageView(schoolId).then((num) => {
        // console.log('num------>', num)
        schoolInfo(schoolId).then(res => {
            localStorage.setItem("enterpriseInfo", JSON.stringify(res))
        })
    })
    getManageConfig(schoolId).then(res => {
        localStorage.setItem("footStyle", JSON.stringify(res))
        // if (res) this.setState(
        //     { footStyleCode: res.footStyleCode }, () => {
        //     const colorStyle = this.state.footStyleCode === "0" ? "#FFF" : "#333"
        //     document.documentElement.style.setProperty("--footer_color", colorStyle)
        // })
    })
}
// 获取logo后名称
let data = window.location.href
if (data) {
    let params = new URLSearchParams(data);
    const nav = params.get("nav")
    if(nav){
        let navName = decodeURI(decodeURI(nav))
        localStorage.setItem("navName", navName)
    }
}

getImageOssBaseurl({ type: '100007' }).then(res => {
    const {
        'static-img': staticImg,
        'front-img': frontImg,
        'office-public-resource': officePublicResource,
        'public-resource': publicResource,
        'web-office-download': webOfficeDownload,
        'gw': gw,
    } = res.config
    window.$$setImgSrc = create(staticImg);
    window.$$ImageOssBaseurl = frontImg;
    window.$$img = create(frontImg);
    window.$$uploadRc = create(publicResource);
    window.$$serverImageUrl = create(publicResource);
    window.$$uploadDoc = create(officePublicResource);
    window.$$imgSrc = create(publicResource);
    window.$$imgSrcLcl = publicResource;
    window.$$createUploadFilePath = create(publicResource);
    window.$$gw = create(gw);
    window.$$webOfficeDownload = create(webOfficeDownload);
    window.$$type_config = res.config;
    if (res) {
        document.title = res?.config?.title.TS;
        document.version = res?.config?.version;  //版本号
        document.querySelector('link[rel="icon"]').href = window.$$setImgSrc(res?.config?.favicon)
    }
    if (res?.config) {
        initUrlByType07(res?.config)
        initSecretUrlByType07(res?.config)
        getFoot(res.config?.foot);
        Http.defaults.baseURL = baseURL
    }

    // 获取权限列表
    getAuthorityList(res.config.sys, () => {
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>
            ,
            document.getElementById('root')
        );
    })
})
serviceWorker.unregister();
