import React from 'react';

//成功
const SvgSuccess = props => {
    return <svg  {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#27CDA2"/>
        <g clip-path="url(#clip0_2321_269)">
            <path d="M16.3719 6.75892L8.95205 14.1788C8.58458 14.5462 7.9888 14.5462 7.62132 14.1788L3.62913 10.1866C3.26164 9.81908 3.26164 9.22327 3.62913 8.85581C3.9966 8.48834 4.59238 8.48834 4.95987 8.85581L8.28669 12.1827L15.0412 5.42819C15.4086 5.06072 16.0044 5.06072 16.3719 5.42819C16.7394 5.79564 16.7394 6.39144 16.3719 6.75892Z" fill="white"/>
        </g>
        <path d="M9.99998 20.0002C4.47707 20.0002 0 15.5229 0 10C0 4.4773 4.47709 0 9.99998 0C15.5229 0 20 4.4773 20 10C20 15.5229 15.5229 20.0002 9.99998 20.0002ZM16.1101 6.48392L15.7887 6.16255C15.6112 5.98499 15.3235 5.98499 15.146 6.16255L8.48008 12.6902L6.03295 10.1291C5.95258 10.0488 5.7435 10.1278 5.56593 10.3054L5.24455 10.6268C5.06699 10.8043 4.98846 11.0134 5.06878 11.0935L8.33406 14.5104C8.41395 14.5907 8.62303 14.5115 8.80061 14.3342L9.12155 14.0128C9.17835 13.9564 9.22008 13.898 9.25469 13.8405L16.1101 7.12692C16.2877 6.94957 16.2877 6.66148 16.1101 6.48392Z" fill="#27CDA2"/>
        <defs>
            <clipPath id="clip0_2321_269">
                <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33398 3.16675)"/>
            </clipPath>
        </defs>
    </svg>
}

export default SvgSuccess;
