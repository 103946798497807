import React from 'react';
import './index.scss';
import NoData from '../noData'
import RenderImg from '../..//components/renderImg/index'
const urlPre = "cvec-space-front-peixun/"

export default class Teachershow extends React.Component {
    state = {
        remarkShow:false,
        TeacherList:[],
    }
    componentDidMount() {

    }
    toggleForm = (e) => {
        let classList = e.currentTarget.classList;
        let oParent   = e.currentTarget.parentElement;
        if (classList.contains('lilotpage-linkon')) {
            oParent.style.height = '64px';
            oParent.style.overflow = 'hidden';
            e.currentTarget.querySelector('em').innerText = '显示全部';
            classList.remove('lilotpage-linkon');
        } else {
            let conet = document.querySelectorAll('.conet');
            for (let i = 0; i < conet.length; i++) {
                conet[i].style.height = '64px';
                conet[i].querySelector('.lilotpage-link').classList.remove('lilotpage-linkon');
                conet[i].querySelector('.lilotpage-link em').innerText = '显示全部';
            }
            oParent.style.height = '';
            e.currentTarget.querySelector('em').innerText = '收起';
            classList.add('lilotpage-linkon');
        }
    }

    render() {
        // const {remarkShow,TeacherList} =this.state
        const { $$img,apiPrefix, $$imgSrc } = window;
        const {teacherList,index}=this.props
        return (
            <div className='CamelCaseTeachershow'>
                {
                    teacherList.length>0?(
                        <ul>
                            {
                                teacherList&&teacherList.map((item,index)=>{
                                    return(
                                        <li key={index}>
                                            <div className="pic">
                                                {/*<RenderImg type={item.mediaType} size='m' id={item.portraitId}/>*/}
                                                {item.portraitId? <img src={$$imgSrc(item.portraitId)}/> : <img src={$$imgSrc('gr.png')}/>}
                                                {
                                                    item.teachingRole===3?<span className="on">助教</span>: item.teachingRole===1? <span>计划管理员</span>: item.teachingRole===2?<span>主讲老师</span>:<span>讲师</span>
                                                }

                                            </div>
                                            <div className="text">
                                                {
                                                    item.teacherTitle?<h3>{item.fullName}
                                                    <i style={{background:`url('${$$img("cvec-space-front-peixun/title_icon25.png")}') no-repeat center`,backgroundSize: 'cover'}}>
                                                        <img src={`${$$img(urlPre + "title_icon26.png")}`}/>{item.teacherTitle}
                                                    </i>
                                                    </h3>:(
                                                        <h3>{item.fullName}</h3>
                                                    )
                                                }

                                                {
                                                    item.enterpriseName&&<span>归属机构：{item.enterpriseName}</span>
                                                }
                                                {
                                                    item.introduction&&item.introduction.length>80?(
                                                        <div className="conet" id={`intro${index}`} style={{"height":"64px"}}>
                                                            <div className='teacher_con' dangerouslySetInnerHTML={{__html: item.introduction}}>
                                                            </div>
                                                            <div className='lilotpage-link' onClick={this.toggleForm}><em>显示全部</em><img src={`${$$img(urlPre + "title_icon27.png")}`}/></div>
                                                        </div>
                                                    ): <div className='teacher_con' dangerouslySetInnerHTML={{__html: item.introduction}}>
                                                    </div>
                                                }

                                            </div>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                    ):<NoData/>
                }
            </div>
        )
    }
}
