import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Row, Col, Button, Modal, Form, Tabs,Spin} from 'antd';
import { withRouter,Link } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import Cououtline from "../../components/cououtline";
import Coumaterials from "../../components/coumaterials";
import {logoutUrl } from '@/config/index.js';
import {courseDetail, courseDetailTeacher, courseDetailTeacherList, courseDetailRelationPlan, courseDetailAllClass,
    courseDetailClass, courseDetailGetPlan, studentJoinCourse
} from "../../api/course/index";
import {insertSocietyStudent} from '../../api/plan/index'
import { setCookieToken } from '../../until/cookie';
import { gotoClassDetUrl,loginDomainUrl } from '../../config/secret'
import Cookies from 'js-cookie';
import RenderImg from '../../components/renderImg/index'
import NoData from '../../components/noData'
import RegisTration from './compents/registration'
const urlPre = "cvec-space-front-peixun/";
const { TabPane } = Tabs;

export default Form.create()(
    withRouter(
        class Courseint extends React.Component {
            state = {
                userId: Cookies.get("info")? JSON.parse(decodeURI(Cookies.get("info"))).userId:'', // 用户id （未登录没有加入学习等权限）
                enterpriseId:JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'',//机构id,
                isModalVisible:false,
                activeKey:'1',
                Tealist:[],
                Schelist:[],
                courseId: '', // 课程id
                courseDetailInfo: '', // 课程信息
                courseDetailTeacherInfo: '', // 主讲老师信息
                courseDetailTeacherListInfo: '', // 教师团队
                courseDetailRelationPlanInfo: '', // 关联计划信息
                cid: null, // 选中班级id
                classList: [], // 可选班级列表
                checkedClassInfo: {}, // 可选班级被选择的班级信息
                classType: '', // 课程状态
                courseDetailGetPlanList: [], // 计划条数
                studentJoinCourseInfo: '', // 学生加入课程信息
                disabled: true,
                tableLoading:true,
                classId: '', // 班級id
                isRegTra: false, // 注册弹层
                isWarm:false,  //判断登录注册弹窗
            };

            componentDidMount () {
                const data = this.props.location.search; //地址栏截取
                const param = data.split("?")[1];
                if (param) {
                    const codeParam = param.includes("%") ? decodeURI(param) : param;
                    const jsonparam = JSON.parse(codeParam);
                    this.setState({
                        courseId: jsonparam.id,
                    },()=>{
                        this.courseDetail()
                        this.courseDetailTeacher()
                        this.courseDetailTeacherList()
                        this.courseDetailRelationPlan()
                    });
                }
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
            //详情信息
            async courseDetail () {
                const {courseId}=this.state
                const courseDetailInfo = await courseDetail({id:courseId})
                this.setState({
                    courseDetailInfo,
                    classList: courseDetailInfo.classList,
                    tableLoading:false
                })

            }

            //主讲老师
            async courseDetailTeacher () {
                const {courseId}=this.state
                const courseDetailTeacherInfo = await courseDetailTeacher({id:courseId})
                this.setState({
                    courseDetailTeacherInfo
                })
            }
            // 教师团队
            async courseDetailTeacherList () {
                const {courseId} = this.state
                const courseDetailTeacherListInfo = await courseDetailTeacherList({id: courseId})
                this.setState({
                    courseDetailTeacherListInfo,
                    tableLoading:false
                })
            }

            // 关联计划
            async courseDetailRelationPlan () {
                const {courseId} = this.state
                const courseDetailRelationPlanInfo = await courseDetailRelationPlan({id: courseId})
                this.setState({
                    courseDetailRelationPlanInfo
                })
            }

            cancel = e => {
                this.setState({ isModalVisible:false });
            }
            async courseDetailAllClass () { // 查看更多班级
                const {courseId} = this.state
                const classList = await courseDetailAllClass({id: courseId})
                this.setState({
                    classList,
                    checkedClassInfo: classList[0]
                },()=>{
                    // 获取课程详情信息-查询班级状态
                    if (classList[0]) {
                        this.setState({
                            disabled: false
                        })
                        this.courseDetailClass()
                    } else {
                        this.setState({
                            classType: 1,
                            disabled: true
                        })
                    }

                })
            }

            Joinstu = () => { // 获取更多班级弹层
                this.setState({ isModalVisible:true});
            }
            async courseDetailGetPlan () { // 获取课程详情信息-根据课程id查询计划数据
                const {courseId, classType,userId } = this.state
                let data = {
                    courseId: courseId,
                    userId: userId
                }
                const courseDetailGetPlanList = await courseDetailGetPlan(data)
                this.setState({
                    courseDetailGetPlanList
                }, () => { // 返回的list不为空弹出立即学习弹层提示，可以访问没关系继续学习功能
                    if (courseDetailGetPlanList.length > 0) {
                        this.showConfirm()
                    } else  {
                        if (classType == 3 || classType == 4) { // 未关联计划的课程可直接跳转到学生端课程详情页
                            // 直接跳转到学生端课程详情页
                            const {courseId,checkedClassInfo, userId} = this.state
                            let cookieData = {
                                courseId: courseId,
                                classId: checkedClassInfo.id,
                                userId: userId,
                                isReload: true
                            }
                            setCookieToken(cookieData, 2)
                            Cookies.set('pathName', `/training/mytraining/interestclass`); // 存菜单地址
                            window.open(gotoClassDetUrl + JSON.stringify(cookieData)).location.reload()
                        } else {
                            this.hint(
                                {'title': '温馨提示',
                                    'content': '是否确定报名',
                                    'isCancel': true,
                                    'cancelText': '取消',
                                    'okText': '确定',
                                    'sure': this.sureGo,
                                    'type': 'warning'
                                }
                            )
                        }
                    }
                })
            }
            sureGo = () => { // 确定报名执行函数
                const {courseId, classType,checkedClassInfo, userId } = this.state
                if ( classType === 1 ) { // classTyp == 1 访问 api/v1/student/course/studentJoinCourse
                    this.studentJoinCourse()
                } else { // classType == 3 4 不访问接口直接跳转到课程详情页
                    let cookieData = {
                        courseId: courseId,
                        classId: checkedClassInfo.id,
                        userId: userId
                    }
                    Cookies.set('pathName', `/training/mytraining/interestclass`); // 存菜单地址
                    window.open(gotoClassDetUrl + JSON.stringify(cookieData)).location.reload()
                }
            }

            showConfirm =() => { // 立即学习弹层
                Modal.confirm({
                    title:(
                        <div>
                            <img src={window.$$img('cvec-space-front-peixun/title_icon44.png')}/>
                            温馨提示
                        </div>
                    ),
                    content: '当前课程已存在于您的培训计划内，若继续学习该课程将无法记录学习成果于计划内，建议您前往培训计划内学习。',
                    okText: '前往培训计划内学习',
                    cancelText: '没关系，继续学习',
                    centered:true,
                    className:'Confirmcontent',
                    onOk: async () => {
                        const {courseDetailGetPlanList,courseId} = this.state
                        if (courseDetailGetPlanList.length == 1) { // courseDetailGetPlanList.length === 1 跳转到学生端培训计划详情（个人项目）
                            let cookieData = {
                                planId: courseDetailGetPlanList[0].id,
                                planStatus: 2,
                                classId: courseDetailGetPlanList[0].classId
                            }
                            Cookies.set('pathName', `/training/mytraining/trainingplan`); // 存菜单地址
                            window.open(loginDomainUrl + JSON.stringify(cookieData)).location.reload()
                        } else { // courseDetailGetPlanList.length > 1 ) 跳转到当前项目计划列表传id用,分隔 "enterpriseId": "","planIds": [],
                            const planIds = courseDetailGetPlanList.map(item => item.id) // 获取每项id
                            this.props.history.push(`/traininglistp/?${encodeURI(JSON.stringify({planIds: planIds}))}`)
                        }
                    },
                    onCancel: async () => { //  classType == 1访问接口 studentJoinCourse 返回报名成功跳转到学生端课程详情页， classType == 3、4直接跳转到学生端课程详情页
                        if (this.state.classType === 1) {
                            this.studentJoinCourse()
                        } else {
                            // 直接跳转到学生端课程详情页
                            const {courseId,checkedClassInfo, userId} = this.state
                            let cookieData = {
                                courseId: courseId,
                                classId: checkedClassInfo.id,
                                userId: userId
                            }
                            setCookieToken(cookieData, 2)
                            Cookies.set('pathName', `/training/mytraining/interestclass`); // 存菜单地址
                            window.open(gotoClassDetUrl + JSON.stringify(cookieData)).location.reload()
                        }
                    },
                });
            }
            async studentJoinCourse () { // 学生加入课程查詢 成功后直接跳转学生端课程详情页
                const {courseId, checkedClassInfo, userId} = this.state
                let data = {
                    courseId: courseId,
                    classId: checkedClassInfo.id,
                    // classId: '8de58308747c4c92aa4a855b2ffe89cc',
                    userId: userId,
                }

                await studentJoinCourse(data).then(res => {
                    if (res) {
                        let cookieDatass = {
                            courseId: courseId,
                            classId: checkedClassInfo.id,
                            userId: userId
                        }
                        Cookies.set('pathName', `/training/mytraining/interestclass`); // 存菜单地址
                        window.open(gotoClassDetUrl + JSON.stringify(cookieDatass)).location.reload()
                    }
                })
            }
            callback = async (key) => {
                await this.setState({activeKey: key})
            }
            courseDetailClass = async(index, item )=> { // 切换可选班级-获取课程详情信息-查询班级状态
                this.setState({cid: index, checkedClassInfo: item, classId: item.id})
                const {courseId, userId} = this.state
                let data = {
                    id: courseId,
                    classId: item.id,
                    userId: userId
                }
                await courseDetailClass(data).then(res=>{
                    if(res === -1){
                        this.hint({'title': '温馨提示',
                            'content': '您当前正在该课程的教学团队内，继续报名将离开团队，请确认是否继续报名',
                            'isCancel': true,
                            'cancelText': '取消',
                            'okText': '确定',
                            'sure': this.studentEnroll,
                            'type': 'warning'
                        })
                    } else if (res === -2) {
                        this.setState({
                            isWarm:true
                        })
                        // this.hint({'title': '温馨提示',
                        //     'content': '您还未登录，请先登录，如未注册，请先注册',
                        //     'isCancel': true,
                        //     'cancelText': '取消',
                        //     'okText': '注册',
                        //     'sure': this.registration,
                        //     'type': 'warning'
                        // })
                    }  else{
                        this.setState({
                            classType: res
                        },()=>{
                            if(res === 1 ||res === 3 || res === 4 ) {
                                this.setState({
                                    disabled: false
                                })
                            }
                        })
                    }

                })
            }
            registration = () => { // 注册弹层
                this.setState({
                    isRegTra: true
                })
            }
            cancelRe = () => { // 取消注册
                this.setState({
                    isRegTra: false
                })
                const form = this.formPorps.props.form;
                form.resetFields()
            }
            toRegistration = () => { // 注册
                const {courseId,checkedClassInfo, userId,enterpriseId} = this.state
                const form = this.formPorps.props.form;
                form.validateFields((err, fieldsValue) => {
                    if (!err) {
                        let formData = JSON.parse(JSON.stringify(fieldsValue))
                        let data = {
                            enrollType: 2, //  1 计划 2课程
                            userEnrollCourseQo: {
                                courseId: courseId,
                                classId: checkedClassInfo.id,
                                userId: userId,
                            },
                            organization: enterpriseId
                        }
                        let datas = Object.assign(formData, data);
                        this.insertSocietyStudent(datas)
                    }else{
                        this.mesWarning('您有未填写信息')
                    }
                });

            }

            insertSocietyStudent = async (data) => { // 确定注册
                await insertSocietyStudent(data).then( res =>{
                    if (res) {
                        this.mesSuccess('注册成功')
                        const form = this.formPorps.props.form;
                        form.resetFields()
                        this.setState({
                            isRegTra: false
                        })
                    }
                })
            }
            studentEnroll =()=>{
                this.setState({
                    classType:1
                },()=>{
                    this.courseDetailGetPlan()
                })
            }
            messageTip = () => { // 未开发功能提示
                this.mesWarning('该功能正在建设中，敬请期待…');
            };

            canWarm = () =>{
                this.setState({
                    isWarm:false
                })
            }
            //登录
            handleLoginWarm=()=>{
                window.location = `${logoutUrl}?url=${window.location.origin}&out=true`
            }

            render() {
                const {isModalVisible,activeKey,courseId, courseDetailInfo, courseDetailTeacherInfo,
                    courseDetailTeacherListInfo, courseDetailRelationPlanInfo, classList, classType,
                    disabled,tableLoading,isRegTra,isWarm
                } =this.state
                const { $$img, $$imgSrc } = window;
                // 课程详情
                let Introductionpic=<div>
                    {
                        tableLoading?<Spin className="Spin-content" tip="加载中"/>:(
                            <div>
                                {
                                    courseDetailInfo.courseDesc?(
                                    <div className="CamelCasecouintr tableStyle" dangerouslySetInnerHTML={{__html: courseDetailInfo.courseDesc}}>
                                    </div>):<NoData/>
                                }
                            </div>
                        )
                    }

                </div>
                // 课程大纲
                let Syllabus= <div>
                    <Cououtline courseId={courseId}/>
                </div>
                // 课程资料
                let Teacher= <div>
                    <Coumaterials courseId={courseId}/>
                </div>
                return (
                    <div className="resource_main">
                        <Heder navName={'courselist'}/>
                        <div className="resource_container">
                            <div className='CamelCaseCourseintr'>
                                <div className="Courseintrbanner" style={{background:`url('${$$img("cvec-space-front-peixun/pic30.png")}') no-repeat center #34384A`}}>
                                    <div className="Camel-content">
                                        <div className="left">
                                            <RenderImg type={courseDetailInfo.mediaType} size='m' id={courseDetailInfo.courseImg}/>
                                        </div>

                                        <div className="right">
                                            <h3>{courseDetailInfo.courseName}</h3>
                                            <ul className="ul">
                                                {
                                                    courseDetailInfo.labelList?.map((item) => {
                                                        return(<li>{item}</li>)
                                                    })
                                                }
                                            </ul>
                                            <p>{courseDetailInfo.courseSummary}</p>
                                            <span><i>学习人数：{courseDetailInfo.studentNum}</i> | <i>课时：{courseDetailInfo.courseNum}</i></span>
                                            <div className="text">
                                                <em>可选班次</em>
                                                <ul className="ul-on">
                                                    {
                                                        classList?.map((item,index)=> {
                                                            if (index < 3) {
                                                                return(
                                                                    <li className={this.state.cid == index ? 'checked' : ''} key={index} onClick={()=>this.courseDetailClass(index, item)}>
                                                                        {item.className}
                                                                    </li>
                                                                )
                                                            }

                                                        })
                                                    }
                                                    {
                                                        classList&&classList.length > 3 ? <li className="li-on" onClick={()=>this.Joinstu()}>更多班级</li> : null
                                                    }
                                                </ul>
                                            </div>
                                            <div className="more-link">
                                                <div className='left_wapper_box'>
                                                    <span style={{color: '#D91717',fontSize: '24px',marginRight: '38px',display: 'inline'}}>
                                                        {
                                                            courseDetailInfo.courseType == 1?'免费':'收费'
                                                        }
                                                    </span>
                                                    {
                                                        classType == 1 ? <Button disabled={disabled} className={this.state.disabled === true ? 'disabledval' : ''} onClick={() => this.courseDetailGetPlan()}>立即报名</Button> :
                                                            classType == 2? <Button disabled className='disabledval'>暂无可报名班级</Button> :
                                                                classType == 3 ? <Button disabled={disabled} className={disabled === true ? 'disabledval' : ''} onClick={() => this.courseDetailGetPlan()}>开始学习</Button> :
                                                                    classType == 4 ? <Button  disabled={disabled} className={disabled === true ? 'disabledval' : ''} onClick={() => this.courseDetailGetPlan()}>继续学习</Button> :
                                                                        classType == 5 ? <Button disabled className='disabledval'>本学期已结束</Button> : <Button disabled className='disabledval'>请选择班级</Button>
                                                    }
                                                </div>
                                                <div className="bottom">
                                                    <i><img src={`${$$img(urlPre + "title_icon43.png")}`}/>感兴趣  {courseDetailInfo.interestNum}</i>
                                                    {/*<i className="i-on" onClick={this.messageTip}><img src={`${$$img(urlPre + "title_icon39.png")}`}/>分享</i>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="Camel-content">
                                    <Row className="row-cont">
                                        <Col span={18}>
                                            {
                                                courseDetailTeacherInfo.teacherId?
                                                    <div className="cont">
                                                        <div className="pic">
                                                            {/*<img src={$$img(courseDetailTeacherInfo.portraitId)}/>*/}
                                                            {courseDetailTeacherInfo.portraitId? <img src={$$imgSrc(courseDetailTeacherInfo.portraitId)}/> : <img src={$$imgSrc('gr.png')}/>}
                                                            {/*<RenderImg type={courseDetailTeacherInfo.mediaType} size='m' id={courseDetailTeacherInfo.portraitId}/>*/}
                                                            <span>
                                                        {
                                                            courseDetailTeacherInfo.teachingRole == 1? '计划管理员':
                                                                courseDetailTeacherInfo.teachingRole == 2? '主讲教师':
                                                                    courseDetailTeacherInfo.teachingRole == 3?'助教': '讲师'}
                                                    </span>
                                                        </div>
                                                        <div className="text">
                                                            <h3>{courseDetailTeacherInfo.fullName}<i>归属机构：{courseDetailTeacherInfo.enterpriseName}</i></h3>
                                                            <ul>
                                                                <li>{courseDetailTeacherInfo.courseNum}个课程</li>
                                                                <li>{courseDetailTeacherInfo.studentNum}次学习</li>
                                                                <li>{courseDetailTeacherInfo.planNum}个培训计划</li>
                                                            </ul>
                                                            {
                                                                courseDetailTeacherInfo.teacherIntroduction?<p>{courseDetailTeacherInfo.teacherIntroduction}</p>:""
                                                            }

                                                        </div>
                                                    </div> : null
                                            }

                                            <div className="table-conte">
                                                <Tabs activeKey={activeKey} onChange={this.callback.bind(this)}>
                                                    <TabPane tab="课程介绍" key="1">
                                                        {/*<div dangerouslySetInnerHTML={{__html: courseDetailInfo.courseDesc}}>*/}
                                                        {/*</div>*/}
                                                    </TabPane>
                                                    <TabPane tab="课程大纲" key="2">
                                                        {/*<Cououtline courseId={this.state.courseId}/>*/}
                                                    </TabPane>
                                                    <TabPane tab="课程资料" key="3">
                                                    </TabPane>
                                                </Tabs>
                                                {
                                                    activeKey === "1" ? Introductionpic : (activeKey === "2" ? Syllabus :activeKey === "3" ? Teacher :"")
                                                }
                                            </div>
                                        </Col>
                                        <Col span={6}>
                                            <div className="right-top">
                                                        <ul className="ul-a">
                                                            <h3><img src={`${$$img(urlPre + "title_icon22.png")}`}/>教师团队</h3>
                                                            {
                                                                courseDetailTeacherListInfo.length>0?courseDetailTeacherListInfo.map((item,index)=>{
                                                                    return(
                                                                        <li key={index}>
                                                                            {
                                                                                <span>
                                                                                    {/*<RenderImg type={item.mediaType} size='m' id={item.portraitId}/>*/}
                                                                                    {item.portraitId? <img src={$$imgSrc(item.portraitId)}/> : <img src={$$imgSrc('gr.png')}/>}
                                                                                                    {
                                                                                                        // item.teachingRole==='助教教师'?<i className="on">{item.zw}</i>:<i>{item.zw}</i>
                                                                                                        item.teachingRole == 1 ? <i>计划管理员</i> :
                                                                                                            item.teachingRole == 2 ? <i>主讲老师</i> :
                                                                                                                item.teachingRole == 3 ? <i className="on">助教</i> : <i>讲师</i>
                                                                                                    }

                                                                                </span>
                                                                            }
                                                                            <div className="text">
                                                                                <h4>{item.fullName}</h4>
                                                                                {
                                                                                    item.enterpriseName?<p>归属机构：{item.enterpriseName}</p>:""
                                                                                }

                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }):<NoData/>
                                                            }
                                                        </ul>
                                                        <ul className="ul-b">
                                                            <h3><img src={`${$$img(urlPre + "title_icon22.png")}`}/>关联计划</h3>
                                                            {
                                                                courseDetailRelationPlanInfo.length>0?courseDetailRelationPlanInfo.map((item,index)=>{
                                                                    return(
                                                                        <li key={index}>
                                                                            <span>
                                                                                <RenderImg type={item.mediaType} size='m' id={item.planImg}/>
                                                                            </span>
                                                                            <div className="text">
                                                                                <h4>{item.planName}</h4>
                                                                                <p>{item.trainingOverview}</p>
                                                                                <div className="more">
                                                                                    {
                                                                                        item.planLeader?<em><img src={`${$$img(urlPre + "title_icon23.png")}`}/>{item.planLeader}</em>:""
                                                                                    }

                                                                                    {
                                                                                        item.studentNum?<b>{item.studentNum}人在学</b>:""
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }):<NoData/>
                                                            }
                                                            {
                                                                courseDetailRelationPlanInfo.length > 1?
                                                                    <div className="more-link">
                                                                        <Link to='/traininglistp'>查看更多 ></Link>
                                                                    </div> : null
                                                            }

                                                        </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                        <Modal
                            title="选择班级"
                            visible={isModalVisible}
                            onCancel={this.cancel}
                            centered={true}
                            wrapClassName="Selectcent"
                            footer={
                                <div className='pos-btn'>
                                    <Button className="but" onClick={this.cancel}>取消</Button>
                                    <Button className="but" onClick={this.cancel} type="primary">确定</Button>
                                </div>
                            }
                        >
                           <div className="table-content">
                               <ul className="ul-on">
                                   {
                                       this.state.classList?.map((item,index)=> {
                                           return(
                                               <li className={this.state.cid == index ? 'checked' : ''} key={index} onClick={this.courseDetailClass.bind(this,index, item)}>
                                                   {item.className}
                                               </li>
                                           )

                                       })
                                   }
                               </ul>

                           </div>
                        </Modal>
                        <Modal
                            title="注册信息"
                            visible={isRegTra}
                            onCancel={this.cancelRe}
                            centered={true}
                            wrapClassName="signupcont"
                            width={800}
                            footer={
                                <div className='pos-btn'>
                                    <Button className="but" onClick={this.cancelRe}>取消</Button>
                                    <Button className="but" type="primary" onClick={this.toRegistration.bind(this)}>确定</Button>
                                </div>
                            }
                        >
                            <RegisTration
                                wrappedComponentRef={form => {
                                    this.formPorps = form
                                }}/>
                        </Modal>
                        <Modal
                            title="温馨提示"
                            visible={isWarm}
                            onCancel={this.canWarm}
                            centered={true}
                            closable={false}
                            wrapClassName="war_Stration"
                            footer={
                                <div className='pos-btn'>
                                    <Button className="but" onClick={this.canWarm}>取消</Button>
                                    <Button type="primary" onClick={this.registration}>注册</Button>
                                    <Button type="primary" onClick={this.handleLoginWarm}>登录</Button>
                                </div>
                            }
                        >
                            <div>
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="9" fill="white"></circle><path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM10.8594 5V11.6307C10.8594 12.1053 10.4746 12.4901 10 12.4901C9.52539 12.4901 9.14062 12.1053 9.14062 11.6307V5C9.14062 4.52539 9.52539 4.14062 10 4.14062C10.4746 4.14062 10.8594 4.52539 10.8594 5ZM10 13.3594C10.6904 13.3594 11.25 13.919 11.25 14.6094C11.25 15.2997 10.6904 15.8594 10 15.8594C9.30965 15.8594 8.75 15.2997 8.75 14.6094C8.75 13.919 9.30965 13.3594 10 13.3594Z" fill="#FFA325"></path></svg>
                        </span>
                                <p>您还未登录，请先登录，如未注册，请先注册</p>
                            </div>
                        </Modal>
                    </div>
                );
            }
        }
    )
);
