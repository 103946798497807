import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Pagination,Form } from 'antd';
import { withRouter } from "react-router-dom";
import Heder from "../../components/headerroom";
import Footer from "../../components/footer";
import {peixunIndexNewsOneCategory,peixunIndexNewsAndTwoCategory,peixunIndexNewsByCategory} from"../../api/newslist/index";//导入接口
import RenderImg from "../../components/renderImg";
import S from 'gongyongicon'
import {Jiant} from '../../assets/svg'
import NoData from "../../components/noData";
const urlPre = "cvec-space-front-peixun/";

export default Form.create()(
    withRouter(
        class Newslist extends React.Component {
            state = {
                organizationId: JSON.parse(localStorage.getItem('enterpriseInfo'))? JSON.parse(localStorage.getItem('enterpriseInfo')).schoolId:'', //机构id,
                pageNum:1,
                pageSize:10,
                total: '',
                leftList:[],
                rightList:[],
                rightnewsList:[],
                oneId: '', // 一级id
                twoId:'',// 二级id
                newsId:'',//新闻id
                specialId:'',//专题id
                NewsDetail:[],//新闻详情内容
                indexs: 0, // 一级默认选择
                indexTwo: -1 , // 二级默认选择
                openType: 'close'
            };
            componentDidMount() {// 进入页面就执行查询一级分类
                this.peixunIndexNewsOneCategory()
            }
            //查询首页新闻一级分类
            async peixunIndexNewsOneCategory(){
                        const {organizationId,pageNum,pageSize}=this.state
                        let data={
                            organizationId:organizationId,
                            pageNum:pageNum,
                            pageSize:pageSize,
                            platform:'TS'
                        }
                        const leftList=await peixunIndexNewsOneCategory(data)

                        this.setState({//赋值用，把调用接口之后返回的值赋值给State 中的leftList
                            leftList,//把返回值liftList赋值给state中定义的liftList数组（=leftList:leftList如果前后一样可以简写）
                            oneId: leftList?leftList[0].categoryId: '',

                        },()=>{
                            this.peixunIndexNewsAndTwoCategory()
                        })

            }

            //查询首页新闻和二级分类'0cf12bd5198346d0b058bfdce58bc17a'
            async peixunIndexNewsAndTwoCategory(){
                const {oneId,pageNum,pageSize}=this.state
                let data={
                    oneCategoryId:oneId,
                    pageNum:pageNum,
                    pageSize:pageSize,
                    platform:'TS'
                }

                const result=await peixunIndexNewsAndTwoCategory(data)
                // result.twoCategoryList?( result.twoCategoryList.length > 0? result.twoCategoryList[0].categoryId:''):
                if(result){
                    this.setState({
                        rightList:result.twoCategoryList,
                        rightnewsList:result.newsList.data,
                        twoId: '',
                        total: result.newsList.total
                    },()=>{
                        //用回调函数，有返回值了之后再去调用下一步操作
                        if(this.state.twoId){
                            this.peixunIndexNewsByCategory()
                        }
                    })
                }
            }

            //根据二级分类查新闻列表 peixunIndexNewsByCategory
            async peixunIndexNewsByCategory(){
                const {oneId,pageNum,pageSize,twoId}=this.state
                let data={
                    oneCategoryId:oneId,
                    pageNum:pageNum,
                    pageSize:pageSize,
                    platform:'TS',
                    twoCategoryId:twoId
                }
                const result=await peixunIndexNewsByCategory(data)
                this.setState({
                    rightnewsList:result.data,
                    total: result.total
                })
            }

           //查询二级分类菜单和新闻peixunIndexNewsAndTwoCategory。参数是一级分类的id
            gotoTwoNews = (id,index)=> {
                this.setState({ //给state赋值
                    rightList:[],
                    rightnewsList:[],
                    oneId: id,//给state中的oneId赋值,
                    indexs: index,
                    pageNum:1,
                    pageSize:10,
                    indexTwo:-1
                },()=>{
                    this.peixunIndexNewsAndTwoCategory()
                })
            }

            //根据二级栏目查询新闻列表
            queryNewsFromTwo = (id,index)=>{
                this.setState({ //给state赋值
                    twoId: id,//给state中的oneId赋值
                    indexTwo: index,
                },()=>{
                    this.peixunIndexNewsByCategory()
                })
            }

            //查询新闻详情（跳转）
            peixunIndexNewsDetail0= item=>{
                this.props.history.push(`/newscont/?${encodeURI(JSON.stringify({newsId: item.newsId,oneCategoryId:item.oneCategoryId,twoCategoryId:item.twoCategoryId}))}`)
            }
            // 展开收起事件
            openList = (type) =>{
                this.setState({
                    openType: type
                })
            }
            onShowSizeChange=(current, pageSize)=>{ // 分页
                this.setState({
                    pageSize:pageSize,
                    pageNum:current
                },()=>{
                    this.peixunIndexNewsAndTwoCategory()
                })
            }
            onChange=(page)=>{
                this.setState({
                    pageNum:page
                },()=>{
                    this.peixunIndexNewsAndTwoCategory()
                })
            }

            render() {
                const {leftList,rightList,rightnewsList,indexTwo,indexs,openType,total,pageSize,pageNum} =this.state
                const { $$img } = window;
                return (
                    <div className="resource_main">
                        <Heder navName={'newsinformation'}/>
                        <div className="resource_container">
                            <div className='newslist'>
                                <div className="news-list">
                                    <div className="marin-cont">
                                        <div className="left">
                                            <ul className="ul">
                                                {
                                                    leftList&&leftList.map((item,index)=>{//&&代表state中的leftList是否有值，&&之后：如果有的话，遍历它得到每个对象item
                                                        return(
                                                            //当点击li的时候执行这个方法。参数就是刚刚遍历leftList后每个item中的id
                                                            <li className={indexs === index ? "on" : ''}  onClick={()=>this.gotoTwoNews(item.categoryId,index)}>
                                                                <span>{item.categoryName}</span>
                                                                <i><Jiant/></i>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="right">
                                            <div className="spread-top">
                                                {
                                                    openType === 'open' ?
                                                        <ul className="spread-ul" >
                                                            {
                                                                rightList&&rightList.length>0&&rightList.map((item,index)=>{//&&代表state中的rightList是否有值，&&之后：如果有的话，遍历它得到每个对象item
                                                                    return(
                                                                            <li className={indexTwo === index ? "on" : ''} onClick={()=>this.queryNewsFromTwo(item.categoryId,index)}>
                                                                                <span>{item.categoryName}</span>
                                                                                <i></i>
                                                                            </li>
                                                                        )
                                                                })
                                                            }
                                                            <li className="open" onClick={()=>this.openList('close')}>收起<S.Shangjiantou/></li>
                                                        </ul> :  <ul className="spread-ul" >
                                                            {
                                                                rightList&&rightList.length>0&&rightList.map((item,index)=>{//&&代表state中的rightList是否有值，&&之后：如果有的话，遍历它得到每个对象item
                                                                    if (index < 8) {
                                                                        return(
                                                                            //当点击li的时候执行这个方法。参数就是刚刚遍历rightList后每个item中的id
                                                                            <li className={indexTwo === index ? "on" : ''} onClick={()=>this.queryNewsFromTwo(item.categoryId,index)}><span>{item.categoryName}</span><i></i></li>
                                                                        )
                                                                    }

                                                                })
                                                            }
                                                            {
                                                                rightList&&rightList.length >= 8 ? <li className="open" onClick={()=>this.openList('open')}>展开<S.Xiajiantou_icon1/></li> : ''
                                                            }
                                                        </ul>

                                                }

                                            </div>
                                            <div className="spread-bottom">
                                                <ul>
                                                    {
                                                        rightnewsList&& rightnewsList.length > 0 ? rightnewsList.map((item,index)=>{
                                                            return(
                                                                <li onClick={()=>this.peixunIndexNewsDetail0(item)}>
                                                                    <h3>{item.newsTitle}</h3>
                                                                    <div className="text">
                                                                        <div className="left-img">
                                                                            <RenderImg type={item.mediaType} size='m' id={item.newsImg}/>
                                                                        </div>
                                                                        <div className="right-text">
                                                                            <div className="dov" dangerouslySetInnerHTML={{__html: item.newsAbstract}}>
                                                                            </div>
                                                                            <div className="link">
                                                                                <h5><i className={item.newsType === 1 ? '' : 'on'}>{item.newsType === 1 ? '原创' : '转载'}</i>来源：{item.authorName}</h5>
                                                                                <span>{item.releaseTime}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )}) :  <NoData></NoData>
                                                    }
                                                </ul>
                                            </div>
                                            {
                                                rightnewsList&& rightnewsList.length > 0 ?
                                                    <div className="Pagination-cont">
                                                        <Pagination
                                                            className="Pagination-fs"
                                                            showSizeChanger
                                                            onShowSizeChange={this.onShowSizeChange}
                                                            onChange={this.onChange}
                                                            total={total}
                                                            pageSize={pageSize}
                                                            current={pageNum}
                                                        />
                                                    </div> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
