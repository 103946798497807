export const apiPrefix = () => {
    return "/api/peixun"
    // return ""
}
export const apiRcenter = () => {
    return "/api/rcenter"
    // return ""
}
export const apiZixun = () => {
    return "/api/zixun"
    // return ""
}

export const apiUcenter = () => {
    return "/api/ucenter"
}
