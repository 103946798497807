    import React from 'react';
import './index.scss';
import '@/reset_style/index.less';
import {Row,Pagination,Form } from 'antd';
import { withRouter,Link } from "react-router-dom";
import Footer from "../../components/footer";
function onShowSizeChange(current, pageSize) {
    console.log(current, pageSize);
}

export default Form.create()(
    withRouter(
        class Focustime extends React.Component {
            state = {
                Focustimelist:[],
            };

            render() {
                const {Focustimelist} =this.state

                return (
                    <div className="resource_main">
                        {/*<Heder />*/}
                        <div className="resource_container">
                            <div className='CamelCaseFocustime'>
                                {/*没有头部*/}
                                <div className="Camel-content demeanor">
                                    <div className="link-return">
                                        <Link><img src={window.$$img('cvec-space-front-peixun/title_icon34.png')}/>返回</Link>
                                    </div>
                                    <div className="Focustimelist">
                                        <h3><img src={window.$$img('cvec-space-front-peixun/title_icon3.png')}/>专注时刻</h3>
                                        <ul>
                                            {
                                                Focustimelist&&Focustimelist.map((item,index)=>{
                                                    return(
                                                        <li><Link>
                                                            <span><img src={item.Image}/></span>
                                                            <p>{item.doc}</p>
                                                        </Link></li>
                                                    )
                                                })
                                            }


                                        </ul>
                                    </div>
                                    <Row>
                                        <Pagination className="Pagination-f"
                                                    showSizeChanger
                                                    onShowSizeChange={onShowSizeChange}
                                                    defaultCurrent={3}
                                                    total={500}
                                        />
                                    </Row>
                                </div>
                            </div>
                            <div className="resource_footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                );
            }
        }
    )
);
